import React from 'react';
import categories from "../../constants/categories.json"
import { Link, NavLink } from "react-router-dom";

const FreelancerItem = (props) => {
    
    const getCategoryNameById = (catId) => {
		if(catId && catId.id != "-1")
			return categories.find(category => category.id === parseInt(catId.id)).name;
		else
			return "nicio categorie";
	}
    
    if (props.categories) 
        props.categories.sort();

    return (
        <div class="freelancer">

            {/* Overview */}
            <div class="freelancer-overview">
                <div class="freelancer-overview-inner">

                    {/* Bookmark Icon */}
                    <span class="bookmark-icon"></span>

                    {/* Avatar */}
                    <div class="freelancer-avatar">
                        {/* <div class="verified-badge"></div> */}
                        <a href="#"><img src="images/user-avatar-placeholder.png" alt="" /></a>
                    </div>

                    {/* Name */}
                    <div class="freelancer-name">
                        <h4 ><a>{props.name}                <img class="flag" src="images/flags/ro.svg" alt="" title="Romania" data-tippy-placement="top" /></a></h4>
                        
                        <span>{props.description ? props.description : "Profesionist"}</span>

                        {(props.categories != null && props.categories && props.categories[0]) && <div class="task-icons margin-top-20">
                            <span><i className="icon-material-outline-local-offer"></i> {getCategoryNameById(props.categories[0])}</span><br/>
                            {props.categories[1] && <span><i className="icon-material-outline-local-offer"></i> {getCategoryNameById(props.categories[1])}</span>}
                            
                        </div>}

                        <div className="task-tags">
                            {props.skills && props.skills.map(skill =>
                                <span>{skill}</span>
                            )}
                        </div>
                        {/* Rating */}
                        {/* <div class="freelancer-rating">
                            <div class="star-rating" data-rating="4.9"></div>
                        </div> */}
                    </div>
                </div>
            </div>

            {/* Details */}
            <div class="freelancer-details">
                <div class="freelancer-details-list">
                    <ul>
                        {props.workCity ? <li>Location <strong><i class="icon-material-outline-location-on"></i> {props.workCity}</strong></li> :
                            <li>Location <strong><i class="icon-material-outline-location-off"></i> Nespecificat</strong></li> 
                        }   
                        <li>Rate <strong>{`${props.hourlyTariff} ${props.hourlyTariffCurrency} / oră`}</strong></li>
                    </ul>
                </div>
                <NavLink to={`/freelancer/${props.id}`} className="button button-sliding-icon ripple-effect" >Vizualizează profil <i className="icon-material-outline-arrow-right-alt"></i></NavLink>
            </div>
        </div>
    );
}

export default FreelancerItem;