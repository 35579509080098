import { addNotification } from '../../../actions';
import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { host } from '../../../constants/constants';
import { rippleEffect, DashboardResize } from '../../../runJQuery';
import { withRouter } from 'react-router';

const ActionLog = () => {

	const [actions, setActions] = useState([]);

	useEffect(() => {
		//rippleEffect();
        fetchActions();
        //DashboardResize();
	}, []);

	const fetchActions = () => {
		axios({
			method: 'GET',
			url: `${host}/actions`,
			withCredentials: true
		}).then(res => {
			setActions(res.data);
		}).catch(err => {
			addNotification('error', 'Could not load actions. Please try again in few minutes');
		});
	};

    const getAction = (action) => {
        switch(action) {
            case 'PROJECT_POSTED' : return "Postare Proiect";
            case 'PROJECT_PROMOTED' : return "Promovare Proiect";
            case 'WORKER_UNLOCKED' : return "Deblocare Freelancer";
            case 'WORKER_PROMOTED' : return "Freelancer Promovat";
        }
    }
	
	return (
	    <div class="dashboard-content-container " data-simplebar>
			<div className="dashboard-content-inner " >

				{/*~~ Dashboard Headline ~~*/}
				<div className="dashboard-headline">
					<h3> Desfăsurător</h3>
				</div>

				{/*~~ Row ~~*/}
				{/*Dashboard Box */}
				<div class="col-xl-8 ">
					<div class="dashboard-box">
						<div class="headline">
							<h3><i class="icon-material-outline-assignment"></i>  Desfăsurător Credite</h3>
						</div>
						<div class="content">
							<ul class="dashboard-box-list margin-botton-95">
                                {actions != undefined && 
                                    actions.map(action => 
                                        <li>
                                            <div class="invoice-list-item">
                                            <strong>{action.userName} - {getAction(action.actionType)}</strong>
                                                <ul>
                                                    {/* <li>Număr comandă: {action.orderId}</li> */}
                                                    <li>Dată: {new Date(action.createdAt).toLocaleDateString("ro-RO", 
                                                        {
                                                            day: 'numeric', // numeric, 2-digit
                                                            year: 'numeric', // numeric, 2-digit
                                                            month: 'long', // numeric, 2-digit, long, short, narrow
                                                        })}
                                                    </li>
                                                    <li>Credite debitate: {action.creditsDebited}</li>
                                                    {action.actionType === 'PROJECT_POSTED'&&
                                                        <li>Nume Proiect: {action.actionDetails.projectName}</li>
                                                    }
                                                    {action.actionType === 'PROJECT_PROMOTED' &&
                                                        <Fragment>
                                                            <li>Nume Proiect: {action.actionDetails.projectName}</li>
                                                            <li>Zile Promovate: {action.actionDetails.daysPromoted}</li>
                                                        </Fragment>
                                                    }
                                                    {action.actionType === 'WORKER_PROMOTED' &&
                                                        <Fragment>
                                                            <li>Nume freelancer: {action.actionDetails.workerName}</li>
                                                            <li>Zile Promovare: {action.actionDetails.daysPromoted}</li>
                                                        </Fragment>
                                                    }
                                                    {action.actionType === 'WORKER_UNLOCKED' &&
                                                        <Fragment>
                                                            <li>Nume freelancer: {action.actionDetails.workerName}</li>
                                                        </Fragment>
                                                    }
                                                </ul>
                                                {/* <ul className="margin-top-10">
                                                    
                                                    <li>Credite: {action.creditAmount}</li>
                                                    <li>Total achitat: {action.total} RON</li>
                                                    <li>Tip plată: {(action.paymentType && action.paymentType==='BANK_TRANSFER') ? "Transfer bancar" : "Card online" }</li>
                                                </ul>

                                                <ul className="margin-top-10">
                                                    <li>Detalii plată: {action.message}</li>
                                                </ul>
                                                <ul className="margin-top-10">
                                                    <li>Stare: {action.status === "PAID" ? <span class="paid">Plătită</span> : <span class="unpaid">Neplătită</span> } </li>
                                                        
                                                </ul> */}
                                            </div>
                                            {/*Buttons */}
                                            {/* <div class="buttons-to-right">
                                                <a href="pages-invoice-template.html" class="button gray">View Invoice</a>
                                            </div> */}
                                        </li>
                                        
                                    )}
							</ul>
						</div>
					</div>
				</div>

			</div>
		</div>
		
	);
}

export default withRouter(ActionLog);