import React, { Fragment, useEffect, useState } from "react"
import { addNotification } from '../../../actions';
import { Link, NavLink } from "react-router-dom";
import axios from 'axios';
import { host } from '../../../constants/constants';

const UnlockedWorkers = () => {

    const [workers, setWorkers] = useState([]);

    const fetchUnlockedFreelancers = () => {
		axios({
			method: 'GET',
			url: `${host}/users/unlocked`,
			withCredentials: true
		}).then(res => {
			setWorkers(res.data);
		}).catch(err => {
			addNotification('error', 'Nu am putut găsi proiecte. Încearcă în câteva minute.');
		});
	};

    useEffect(() => {
        fetchUnlockedFreelancers();
    }, [])

    return (
        
        <Fragment>
            <div className="dashboard-content-inner" >

                {/* Dashboard Headline */}
                <div className="dashboard-headline">
                    <h3>Candidați deblocați</h3>

                    {/* Breadcrumbs */}
                    <nav id="breadcrumbs" className="dark">
                        <ul>
                            <li><Link to="/">Pagină principală</Link></li>
                            <li><Link to="/dashboard">Dashboard</Link></li>
                            <li>Candidați deblocați</li>
                        </ul>
                    </nav>
                </div>

                <div className="clearfix margin-top-40"/>

                {/* Row */}
                <div className="row">

                    {/* Dashboard Box */}
                    <div className="col-xl-12">
                        <div className="dashboard-box margin-top-0">

                            {/* Headline */}
                            <div className="headline">
                                <h3><i className="icon-material-outline-supervisor-account"></i> Candidați deblocați</h3>
                            </div>

                            <div className="content">
                                <ul className="dashboard-box-list">
                                    {workers  &&
                                        workers.map(worker =>
                                            <li key={worker.id}>
                                                {/* Overview */}
                                                <div className="freelancer-overview manage-candidates">
                                                    <div className="freelancer-overview-inner">

                                                        {/* Avatar */}
                                                        <div className="freelancer-avatar">
                                                            <a href="#"><img src="/images/user-avatar-placeholder.png" alt="" /></a>
                                                        </div>

                                                        {/* Name */}
                                                        <div className="freelancer-name">
                                                            <h4><NavLink to={`/freelancer/${worker.id}`}>{worker.firstName + " " + worker.lastName} <img className="flag" src="images/flags/ro.svg" alt="" title="Romania" data-tippy-placement="top" /></NavLink></h4>

                                                            {/* Details */}
                                                            <span className="freelancer-detail-item"><a href="#"><i className="icon-feather-mail"></i> {worker.email}</a></span>
                                                            <span className="freelancer-detail-item"><i className="icon-feather-phone"></i> {worker.phone}</span><br/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                
                
            </div>
        </Fragment>);
}

export default UnlockedWorkers;