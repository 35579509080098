import React from 'react';
import SearchBar from './SearchBar';
import HowItWorks from './HowItWorks';
import FeaturedProjects from './FeaturedProjects';
import FeaturedFreelancers from './FeaturedFreelancers';

export default function () {
	return (
		<>
			<SearchBar />
			<FeaturedProjects />
			<HowItWorks />
			{/* <FeaturedFreelancers /> */}
		</>
	);
}