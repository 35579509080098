import React,{ Fragment } from "react"
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
    return (
        <Fragment>
            {/* Content */}
            <div id="titlebar" className="gradient">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Politică de confidențialitate</h2>
                        
                            {/* Breadcrumbs */}
                            <nav id="breadcrumbs" className="dark">
                                <ul>
                                    <li><Link to="/">Pagină principală</Link></li>
                                    <li>Politică de confidențialitate</li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            {/* Post Content */}
            <div className="container">
                <div className="row">

                    {/* Inner Content */}
                    
                        {/* Blog Post */}
                        <div className="blog-post single-post">

                            {/* Blog Post Content */}
                            <div className="blog-post-content">

                                <p>Societatea prelucrează o serie de date cu caracter personal ale Utilizatorilor, Utilizatorilor Candidați sau ale Utilizatorilor Recrutori (i.e. reprezentanți ai Utilizatorilor Recrutori, persoane juridice, sau Utilizatori Recrutori persoane fizice autorizate) atunci când utilizează lancehub.ro.</p>

                                <p>Această politică de confidențialitate („Politica”) descrie ce tipuri de date cu caracter personal sunt prelucrate, cum sunt acestea utilizate, care sunt opțiunile dvs. în legătură cu aceste prelucrări, precum și modul în care Noi vom respecta drepturile pe care le aveți în calitate de persoană vizată conform legislației privind protecția datelor cu caracter personal, inclusiv Regulamentul (UE) 2016/679 („GDPR”).</p>

                                <p>ÎNAINTE DE A UTILIZA LANCEHUB.RO APLICAȚIA LANCEHUB.RO SAU SERVICIILE NOASTRE, VĂ RECOMANDĂM SĂ CITIȚI CU ATENȚIE ACEASTĂ POLITICĂ PENTRU A ÎNȚELEGE CUM VĂ SUNT PRELUCRATE DATELE CU CARACTER PERSONAL.</p>

                                <h3 className="margin-bottom-10 margin-top-50">1. Cine este responsabil de prelucrarea datelor dvs.?</h3>

                                <p>Lance Hub Services S.R.L., cu sediul în Bughea de Jos nr.398A, com. Gura Vitioarei, Jud.Prahova, România, este operatorul datelor cu caracter personal, conform legislației privind protecția datelor cu caracter personal inclusiv GDPR, în ceea ce privește datele cu caracter personal ale Utilizatorilor colectate și prelucrate prin intermediul lancehub.ro.</p>

                                <p>Pentru activitatea de prelucrare a datelor cu caracter personal Societatea și-a desemnat un responsabil cu protecția datelor (Data Protection Officer sau DPO) care poate fi contactat folosind următoarele date de contact: office@lancehub.ro.</p>

                                <h3 className="margin-bottom-10 margin-top-50">2. Ce date prelucrăm?</h3>
                                
                                <p>Prelucrăm următoarele categorii de date cu caracter personal:</p>

                                <p>Date pe care le furnizați în mod direct</p>

                                <p>Crearea contului de Utilizator</p>

                                <p>Atunci când vă creați un cont de Utilizator, trebuie să ne furnizați o serie de date, cum ar fi nume, prenume, adresa de e-mail/ nr. telefon mobil, data nașterii, detaliile companiei (în cazul contului de Utilizator Recrutor) etc.</p>

                                <p>Datele din profilul de Utilizator înregistrat</p>

                                <p>Puteți să includeți o serie de informații în profilul dvs. de de Utilizator Candidat, cum ar fi studiile, experiența profesională, aptitudini, fotografii, preferințe privind locul de muncă dorit, informații privind abilitățile etc.</p>

                                <p>Alte date pe care le furnizați în mod direct când utilizați Platforma lancehub.ro sau interacționați cu lancehub.ro</p>

                                <p>Putem stoca informațiile pe care le-am primit de la dvs. ca urmare a unor solicitări transmise prin e-mail sau chat, sau atunci când participați la sondajele și chestionarele noastre, date colectate sau generate de noi ca urmare a interacțiunii dvs. cu Platforma lancehub.ro, înregistrăm în jurnal informații despre când și/sau cum vizitați sau utilizați în alt mod Serviciile lancehub.ro, inclusiv modul în care utilizați Platforma lancehub.ro (e.g. dacă v-ați actualizat sau nu profilul, dacă ați aplicat sau nu la un proiect).</p>

                                <p>De asemenea, utilizăm module cookie și tehnologii similare pentru a vă recunoaște pe dvs. și dispozitivele dvs. De asemenea, permitem altor persoane să utilizeze module cookie. Modul în care utilizăm aceste tehnologii sunt descrise în Politica privind Cookie-urile.</p>
                            
                                <p>De asemenea, când utilizați Platforma lancehub.ro (inclusiv modulele cookie sau tehnologii similare de pe site-urile altora), primim informații despre adresa dvs. IP, locația pe baza de IP, serverul proxy, sistemul de operare, browserul web și modulele de completare, identificatorul de dispozitiv. Dacă utilizați serviciile noastre de la un dispozitiv mobil, dispozitivul respectiv ne va trimite date referitoare la poziția dvs. geografică., pe baza setărilor dispozitivului mobil utilizat. Vă vom solicita să optați pentru acceptare înainte de a utiliza funcția de geolocalizare sau alte instrumente pentru a identifica locația dvs.</p>
                            
                                <p>Datele obținute de la terți</p>
                            
                                <p>Primim informații despre vizitele dvs. și interacțiunea cu serviciile furnizate de alte entități când vizitați serviciile altor furnizori care includ reclame, module cookie sau tehnologii asemănătoare.</p>
                            
                                <p>În cazul persoanelor de contact desemnate de Utilizatorii Recrutori (altele decât titularii de cont), datele sunt colectate de la respectivul titular de cont.</p>
                            
                                <h3 className="margin-bottom-10 margin-top-50">3. De ce vă prelucrăm datele cu caracter personal?</h3>
                                
                                <p>În cele ce urmează vă vom informa cu privire la scopurile pentru care colectăm și prelucrăm datele dvs. cu caracter personal, precum și cu privire la temeiul legal pentru prelucrarea datelor.</p>
                            
                                <h4 className="margin-bottom-10 margin-top-50">3.1 Utilizarea Serviciilor de Către Utilizatorii Candidați</h4>
                                
                                <p>Dacă doriți să vă bucurați de toate facilitățile oferite de Platforma lancehub.ro, trebuie să vă creați un cont de Utilizator. Contul de Utilizator vă permite să accesați o serie de Servicii dedicate Utilizatorilor Candidați, cum ar fi să vă creați un profil, să aplicați la un proiect, să vizualizați anunțuri de proiecte de colaborare, să contactați Utilizatorii Recrutori, să primiți anunțuri și alerte în legătură cu anunțurile de cautare de specialisti publicate de Utilizatorii Recrutori etc. Temeiul juridic: art. 6(1)(b) GDPR – încheierea și executarea contractului.</p>
                            
                                <p>De asemenea, Platforma lancehub.ro poate fi accesata de Utilizatori Recrutori din străinătate, inclusiv din afara Spațiului Economic European, către jurisdicții cărora nu li s-a recunoscut un nivel de protecție adecvat. În cazul în care aplicați pentru un post publicat de un asemenea Utilizator Recrutor, datele dvs. pot fi transferate către o astfel de jurisdicție, iar temeiul juridic al transferului este art. 6(1)(b) GDPR – încheierea și executarea contractului.</p>
                            
                                <p>Putem, de asemenea, să folosim o serie de date pe care ni le furnizați pentru a optimiza modul în care vă facem sugestii de locuri de muncă sau sugestii pentru a vă completa profilul. De asemenea, folosim datele pe care le primim de la Utilizatorii Candidați pentru a crea facilități de filtrare a Utilizatorilor Candidați pe diverse criterii de interes pentru Utilizatorii Recrutori. Temeiul juridic: art. 6(1)(f) GDPR – interes legitim. Avem un interes legitim pentru a ne îmbunătăți Serviciile și de furniza sugestii / sfaturi Utilizatorilor Candidați pentru a maximiza succesul Serviciilor noastre. Totodată, dorim să asigurăm un serviciu de căutare a indicatorilor cât mai eficient pentru Utilizatorii Recrutori.</p>
                            
                                <h4 className="margin-bottom-10 margin-top-50">3.2. Utilizarea Serviciilor de către Utilizatorii Recrutori</h4>
                                
                                <p>Prelucrăm datele personale cu scopul de a gestiona relația contractuală sau precontractuală cu Utilizatorii Recrutori. În cazul Utilizatorilor Recrutori (persoane fizice autorizate) temeiul juridic este: art. 6(1)(b) GDPR – încheierea și executarea contractului sau efectuarea formalităților precontractuale. În cazul datelor cu caracter personal ale reprezentanților Utilizatorilor Recrutori, temeiul juridic este art. 6(1)(f) GDPR – interes legitim. Avem un interes legitim să ne desfășurăm în mod adecvat raporturile contractuale cu clienții noștri persoane juridice. Drepturile și interesele persoanelor fizice (i.e. reprezentanții Utilizatorilor Recrutori) nu sunt prejudiciate întrucât din perspectiva clientului persoană juridică, prelucrarea este necesara în contextul desfășurării relațiilor de muncă / colaborare pe care aceștia o au cu persoanele fizice ale căror date sunt furnizate / asociate contului de Utilizator Recrutor.</p>
                            
                                <p>De asemenea, pe baza interesului legitim, utilizăm anumite date personale asociate unui cont de Utilizator Recrutor pentru a preveni eventualele fraude / utilizări abuzive ale facilităților oferite de Platforma lancehub.ro Utilizatorilor Recrutori.</p>
                            
                                <h4 className="margin-bottom-10 margin-top-50">3.3. Gestiunea relațiilor cu Utilizatorii și suport</h4>

                                <p>Prelucrăm o serie de date cu caracter personal cu scopul de a gestiona relațiile cu Utilizatorii Platformei lancehub.ro, spre exemplu atunci când ne contactează cu diverse întrebări sau solicitări privind funcționalitățile sau disfuncționalitățile Platformei lancehub.ro. Temeiul juridic: art. 6(1)(f) GDPR – interes legitim constând în interesul nostru de a asigura servicii adecvate tuturor Utilizatorilor.</p>
                            
                                <h4 className="margin-bottom-10 margin-top-50">3.4 Furnizarea de comunicări privind Serviciile</h4>
                                
                                <p>Utilizatorii pot primi diverse comunicări de la Noi, care țin de furnizarea Serviciilor (e.g. dacă profilul a fost vizualizat de vreun Utilizator Recrutor, dacă proiectele salvate sunt pe cale să expire, alerte privind necesitatea de actualizare a profilului, alerte privind interviurile programate etc.). Prelucrăm aceste date pe baza interesului nostru legitim art. 6(1)(f) GDPR. Considerăm că interesul nostru legitim nu încalcă drepturile și interesele persoanelor vizate întrucât comunicările sunt legate strâns de furnizarea Serviciilor solicitate. Totodată, oferim posibilitatea Utilizatorilor să dezactiveze oricând aceste comunicări din setările aferente contului de Utilizator.</p>
                            
                                <p>Furnizarea de comunicări comerciale</p>
                            
                                <p>Dorim să vă transmitem materiale promoționale, să vă informăm în legătură cu noile servicii oferite de Societate, să vă lansăm invitații de a participa la sondaje sau de a răspunde la chestionare și pentru a vă comunica alte informații similare care considerăm că pot fi de interes pentru dvs., inclusiv, dacă sunteți de acord, prin transmiterea de materiale promoționale / comunicări de marketing privind produsele / serviciile partenerilor Societății.</p>
                            
                                <p>Vă vom transmite aceste comunicări dacă avem acordul dvs. Temeiul juridic: art. 6(1)(a) GDPR – consimțământ.</p>
                            
                                <p>Utilizatorii se pot oricând dezabona de la aceste comunicări. Spre exemplu, dacă vă retrageți consimțământul pentru comunicările de conținut (Newslettere) nu vă vom mai putea transmite articolele noastre publicate pe platformele de conținut.</p>
                            
                                <p>De asemenea, dacă avem consimțământul pentru comunicări comerciale, în anumite situații, putem utiliza informațiile colectate de la dvs. în combinație cu datele rezultate din interacțiunea dvs. cu Serviciile Platformei lancehub.ro, pe care să le folosim în contextul comunicărilor noastre de marketing. Temeiul juridic pentru o astfel de prelucrare este: art. 6(1)(f) GDPR – interes legitim. Dorim să eficientizăm activitatea noastră de marketing, propunând clienților noștri oferte de servicii relevante și personalizate.</p>

                                <h4 className="margin-bottom-10 margin-top-50">3.5 Utilizarea serviciilor de geolocalizare</h4>
                                
                                <p>Atunci când folosiți funcția de geolocalizare a dispozitivului dvs, vă vom cere acordul. Temeiul juridic al prelucrării: art. 6(1)(f) GDPR – consimțământ.</p>

                                <h4 className="margin-bottom-10 margin-top-50">3.6. Analize și statistici privind funcționarea Platformei lancehub.ro, cookie-uri și tehnologii similare</h4>
                                
                                <p>Putem folosi datele cu caracter personal pe care le furnizează Utilizatorii sau pe care le colectăm în contextul utilizării Serviciilor cu scopul efectuării unor analize și statistici privind Serviciile noastre, inclusiv a modului în care funcționează Platforma lancehub.ro. Temeiul juridic pentru o astfel de prelucrare este: art. 6(1)(f) GDPR – interes legitim. Analizele și statisticile pe care le facem ne ajută să înțelegem mai bine cum am putea să îmbunătățim Serviciile noastre sau funcționalitățile Platformei lancehub.ro.</p>

                                <p>În efectuarea analizelor și statisticilor utilizăm, de asemenea, cookie-uri și alte tehnologii similare conform Politicii privind Cookie-urile. În funcție de tipologia cookie-urilor utilizate și a tehnologiilor similare temeiul juridic este: art. 6(1)(f) GDPR – interes legitim pentru cookie-urile necesare și funcționale sau art. 6(1)(a) GDPR – consimțământ pentru cookie-urile de analiză, tracking și publicitate comportamentală.</p>

                                <h4 className="margin-bottom-10 margin-top-50">3.7. Conectare prin plugin Facebook</h4>
                                
                                <p>Utilizatorii Candidați se pot loga sau își pot crea cont prin intermediul pluginului Facebook, care permite conectarea prin contul personal de Facebook. Atunci când Utilizatorii Candidați utilizează aceasta facilitate, Platforma lancehub.ro preia parțial datele publice ale Utilizatorului Candidat din Facebook (e.g. nume, prenume, adresă de e-mail, număr de telefon). Utilizarea acestor date este necesară pentru crearea contului de Utilizator, prin urmare temeiul juridic al prelucrării este art. 6 (1) (b) din GDPR, respectiv încheierea și executarea contractului.</p>

                                <h4 className="margin-bottom-10 margin-top-50">3.8. Îndeplinirea unor obligații legale</h4>
                                
                                <p>Uneori, prelucrarea datelor este necesară pentru a ne îndeplini obligațiile legale (Temei juridic: art. 6(1)(c) GDPR – executarea unei obligații legale) ce ne revin, cum ar fi:</p>

                                <p>• plata impozitelor și a contribuțiilor relevante, raportarea către autoritățile fiscale relevante și ținerea unor evidențe contabile</p>

                                <p>• arhivarea datelor conform legislației aplicabile.</p>

                                <h4 className="margin-bottom-10 margin-top-50">3.9. Apărarea drepturilor și intereselor în justiție</h4>

                                <p>De asemenea, putem să prelucrăm date cu caracter personal pentru constatarea, exercitarea sau apărarea unui drept în justiție în cadrul unei proceduri în fața unei instanțe, a unei proceduri administrative sau a altor proceduri oficiale în care este implicată Societatea (Temei juridic: art. 6(1)(f) GDPR – apărarea drepturilor și intereselor operatorului).</p>

                                <h3 className="margin-bottom-10 margin-top-50">4. Cui dezvăluim datele?</h3>

                                <p>Putem dezvălui datele dvs. cu caracter personal către (i) entitățile și/sau persoanele împuternicite de noi (din EEA sau din state terțe) implicate în furnizarea Serviciilor inclusiv în furnizarea comunicărilor comerciale (cum ar fi furnizorilor de centre de date, furnizori de servicii de plată pentru diverse facilități de plată în Platforma lancehub.ro, furnizorii de platforme de e-mailing cum ar fi Google Mail); (ii) Utilizatorilor Recrutori inclusiv, dacă este cazul, celor situați în afara EEA; (iii) dacă avem obligația de a divulga datele personale în scopul conformării cu orice obligație legală sau decizie a unei autorități judiciare, autorități publice sau organ guvernamental; sau (iv) dacă ni se cere sau ni se permite în alt mod să facem acest lucru conform legislație aplicabile. De asemenea, datele dvs. cu caracter personal pot fi dezvăluite terților furnizori de cookie-uri și tehnologii similare conform celor descrise în Politica privind Cookie-urile.</p>

                                <h3 className="margin-bottom-10 margin-top-50">5. Cât timp păstrăm datele?</h3>

                                <p>Păstrăm datele dvs. cu caracter personal cât este necesar pentru îndeplinirea scopurilor pentru care au fost colectate, cu respectarea procedurilor interne privind retenția datelor, inclusiv a regulilor de arhivare aplicabile.</p>

                                <p>În general, Datele Utilizatorilor Candidați sunt păstrate atât timp cât acesta are un cont de Utilizator în Platforma lancehub.ro. Datele cu caracter personal vor fi șterse dacă Utilizatorul Candidat solicită dezactivarea și ștergerea contului, sau după o perioada de minim 5 ani de la data la care Utilizatorul Candidat devine inactiv (i.e. de la data ultimei interacțiuni în Platforma lancehub.ro).</p>

                                <p>Datele Utilizatorilor Recrutori (persoane fizice autorizate și reprezentanți ai persoanelor juridice) vor fi păstrate pe întreaga durată a raporturilor contractuale cu Utilizatorul Recrutor, plus pe întreaga perioadă prevăzută de lege pentru activitatea de arhivare și păstrare a documentelor financiar-contabile.</p>

                                <p>Datele aferente cookie-urilor și tehnologiilor similare sunt păstrate conform termenelor specifice setate pentru respectivele tehnologii, termenul de stocare putând fi între durata sesiunii de navigare (pentru cookie-uri de sesiune) și o durată de până la 2 ani (pentru cookie-uri de analiză).</p>

                                <h3 className="margin-bottom-10 margin-top-50">6. Ce drepturi aveți în calitate de persoană vizată?</h3>
                            
                                <p>Conform legii, vă sunt recunoscute următoarele drepturi în calitate de persoană vizată:</p>

                                <p>a. Dreptul de acces</p>

                                <p>Puteți obține de la noi confirmarea că prelucrăm datele dvs. personale, precum și informații privind specificul prelucrării.</p>

                                <p>b. Dreptul de a corecta datele</p>

                                <p>Puteți să ne solicitați să modificăm datele dvs. personale incorecte ori, după caz, să completăm datelor care sunt incomplete.</p>

                                <p>c. Dreptul la ștergere</p>

                                <p>Puteți solicita ștergerea datelor personale atunci când: (i) acestea nu mai sunt necesare pentru scopurile pentru care le-am colectat și le prelucrăm; (ii) v-ați retras consimțământul pentru prelucrarea datelor și noi nu le mai putem prelucra pe alte temeiuri legale; (iii) datele sunt prelucrate contrar legii; respectiv (iv) datele trebuie șterse conform legislației relevante.</p>

                                <p>d. Retragerea consimțământului și dreptul de opoziție</p>

                                <p>Puteți să vă retrageți oricând consimțământul cu privire la prelucrarea datelor pe bază de consimțământ. De asemenea, vă puteți opune oricând prelucrărilor în scop de marketing, inclusiv profilărilor efectuate în acest scop, precum și prelucrărilor bazate pe interesul legitim al Societății, din motive care țin de situația dvs. specifică.</p>

                                <p>e. Restricționare</p>

                                <p>În anumite condiții puteți solicita restricționarea prelucrării datelor dvs. personale.</p>

                                <p>f. Dreptul la portabilitatea datelor</p>
                            
                                <p>În măsura în care prelucrăm datele prin mijloace automate, puteți să ne solicitați, în condițiile legii, să furnizăm datele dvs. într-o formă structurată, utilizată frecvent și care poate fi citită în mod automat. Dacă ne solicitați acest lucru, putem să transmitem datele dvs. unei alte entități, dacă este posibil din punct de vedere tehnic.</p>
                            
                                <p>g. Dreptul de a depune o plângere la autoritatea de supraveghere</p>
                            
                                <p></p>
                            
                                <p></p>

                                <p>Aveți dreptul de a depune o plângere la autoritatea de supraveghere a prelucrării datelor în cazul în care considerați că v-au fost încălcate drepturile:</p>
                            
                                <p>Autoritatea Națională pentru Supravegherea Datelor cu Caracter Personal din România</p>
                            
                                <p>B-dul G-ral. Gheorghe Magheru 28-30 Sector 1, cod poștal 010336 București, România</p>
                            
                                <p>anspdcp@dataprotection.ro</p>
                            
                                <p>PENTRU EXERCITAREA DREPTURILOR MENȚIONATE MAI SUS, NE PUTEȚI CONTACTA UTILIZÂND DATELE DE CONTACT MENȚIONATE LA SECȚIUNEA 1.</p>
                            
                                <p>De asemenea, Societatea poate fi contactată utilizând datele și mecanismele de contact disponibile în Platforma lancehub.ro. Utilizatorii pot să își exercite anumite drepturi (drept de editare, rectificare, opoziție la comunicări comerciale sau ștergere) direct în Platforma lancehub.ro, prin intermediul setărilor din secțiunea CONTUL MEU.</p>
                            
                            </div>
                        </div>
                </div>  
            </div>
       

            {/* Spacer */}
            <div className="padding-top-40"></div>
            {/* Spacer */}
        </Fragment>
    );
}

export default PrivacyPolicy;