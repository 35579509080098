import React, { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import axios from 'axios'
import { host } from '../../constants/constants';
import { addNotification, addProjectApplication } from '../../actions';
import { inlineBG, modalTrigger, slidingButtonEffect } from "../../runJQuery";
import { useSelector } from "react-redux";
import categories from "../../constants/categories.json"

const ProjectDetails = () => {
    const [project, setProject] = useState({
        title: "",
        city : {
            name : ""
        },
        details: "",
        skills: [],
        categories: []
    });

    const userAuthenticated = useSelector(state => state.user.authenticated);

    const { projectId } = useParams();
    const applyButtonRef = useRef();

    const detailsSplit = project.details.split(/\n/);

    const fetchProject = () => {
		axios({
			method: 'GET',
			url: `${host}/project/${projectId}`,
			withCredentials: true
		}).then(res => {
			setProject(res.data);
		}).catch(err => {
			addNotification('error', 'Nu am putut găsi proiecte. Încearcă în câteva minute.');
		});
	};

    useEffect(() => {
        fetchProject();
        inlineBG();
    }, []);

    useEffect(() => {
        modalTrigger(applyButtonRef);
    }, [applyButtonRef]);

    const getCurrencySymbol = () => {
        switch(project.currency) {
            case "USD" : return "$";
            case "EUR" : return "EUR";
            default : return "RON";
        }
    }

    const getProjectBudget = () => {
        return `${project.budget} ${getCurrencySymbol()}`;
    }

    const getPaymentType = () => {
        if (project.paymentType === "PROJECT")
            return "Preț fix pe proiect";
        if (project.paymentType === "HOUR")
            return "Plată pe oră";
        if (project.paymentType === "MONTH")
            return "Plată pe lună";
        if (project.paymentType === "FIXED")
            return "Preț fix pe proiect";
    }
    
    const getJobType = () => {
        if (project.workLocation === "ON_SITE" || project.workLocation === "HQ") 
            return "La sediu";
        if (project.workLocation === "REMOTE")
            return "Remote";
        if (project.workLocation === "UNSPECIFIED")
            return "Neprecizat";
    }

    const handleApplyButton = () => {
        addProjectApplication(project);
    };

	const getCategoryNameById = (catId) => {
		if(catId && catId.id != "-1")
			return categories.find(category => category.id === parseInt(catId.id)).name;
		else
			return "nicio categorie";
	}

    const getProjectDuration = () => {
        if (project.durationType === 'MONTHS') 
            return `${project.duration} luni`
        if (project.durationType === 'DAYS') 
            return `${project.duration} zile`
    }

    if (project.categories)
        project.categories.sort();

    return (
        <React.Fragment>
        {/* Titlebar */}
            <div className="single-page-header" data-background-image="/images/single-job.jpg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="single-page-header-inner">
                                <div className="left-side">
                                    < div class="header-image"><a href="single-company-profile.html"><img src="/images/company-logo-placeholder-alt.png" alt=""/></a></div>
                                    <div className="header-details">
                                    

                                        <h3>{project.title}</h3>
                                        <h5>Despre client</h5>
                                        <ul>
                                         
                                            <li><i className="icon-material-outline-business"></i>{project.clientName}</li>
                                            {/* <li><div className="star-rating" data-rating="4.9"></div></li> */}
                                            <li><img className="flag" src="/images/flags/ro.svg" alt=""/> România</li>
                                            {/* <li><div className="verified-badge-with-title">Verified</div></li> */}
                                        </ul>
                                    </div>
                                </div>
                                <div className="right-side">
                                    <div className="salary-box">
                                        <div className="salary-type">{getPaymentType()}</div>
                                        <div className="salary-amount">{getProjectBudget()}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Page Content
            ================================================== */}
            <div className="container">
                <div className="row">
                    
                    {/* Content */}
                    <div className="col-xl-8 col-lg-8 content-right-offset">

                        <div className="single-page-section">
                            <h3 className="margin-bottom-25">Descriere proiect</h3>
                            {   detailsSplit.map(paragraph => 
                                        <p>{paragraph}</p>
                                    )
                            } 
                        </div>

                        {/*  Skills */}
                        <div class="single-page-section">
                            <h3>Skills</h3>
                            <div class="task-tags">
                                {project.skills.map(skill => <span>{skill}</span>)}
                            </div>
                        </div>   
                    </div>
                    

                    {/* Sidebar */}
                    <div className="col-xl-4 col-lg-4">
                        {/* <div class="countdown yellow-fire margin-bottom-35">Urgent</div> */}
                        <div className="sidebar-container">

                        {/* <a href="#sign-in-dialog" className="popup-with-zoom-anim log-in-button" ref={ref}>Apply Now <i className="icon-material-outline-arrow-right-alt"></i></a> */}
                    
                            <a href={userAuthenticated ? "#small-dialog" : "#sign-in-dialog"} className="apply-now-button popup-with-zoom-anim" ref={applyButtonRef} onClick={handleApplyButton} > Aplică acum <i className="icon-material-outline-arrow-right-alt"></i></a>
                                
                            {/* Sidebar Widget */}
                            <div className="sidebar-widget">
                                <div className="job-overview">
                                    <div className="job-overview-headline">Detalii proiect</div>
                                    <div className="job-overview-inner">
                                        <ul>
                                            <li>
                                                <i className="icon-material-outline-location-on"></i>
                                                <span>Locație</span>
                                                <h5>{project.city.name}, Romania</h5>
                                            </li>
                                            <li>
                                                <i className="icon-material-outline-business-center"></i>
                                                <span>Tipul de lucru</span>
                                                <h5>{getJobType()}</h5>
                                            </li>
                                            <li>
                                                <i className="icon-material-outline-local-atm"></i>
                                                <span>{getPaymentType()}</span>
                                                <h5>{getProjectBudget()}</h5>
                                            </li>
                                            <li>
                                                <i className="icon-material-outline-access-time"></i>
                                                <span>Data Postării</span>
                                                <h5>{
                                                    new Date(project.createdAt).toLocaleDateString("ro-RO", 
                                                    {
                                                        day: 'numeric', // numeric, 2-digit
                                                        year: 'numeric', // numeric, 2-digit
                                                        month: 'long', // numeric, 2-digit, long, short, narrow
                                                    })}</h5>
                                            </li>
                                            {(project.categories!= null && project.categories && project.categories[0]) &&
                                                <li>
                                                    <i className="icon-material-outline-local-offer"></i>
                                                    <span>Categorie principală</span>
                                                    <h5>{getCategoryNameById(project.categories[0])}</h5>
                                                </li>
                                            }
                                            {(project.categories!= null && project.categories && project.categories[1]) &&
                                                <li>
                                                    <i className="icon-material-outline-local-offer"></i>
                                                    <span>Subcategorie</span>
                                                    <h5>{getCategoryNameById(project.categories[1])}</h5>
                                                </li>
                                            }
                                            
                                                <li>
                                                    <i className="icon-line-awesome-certificate"></i>
                                                    <span>Nivel de experiență cerut</span>
                                                    <h5>{project.experienceLevel}</h5>
                                                </li>

                                                <li>
                                                    <i className="icon-material-outline-watch"></i>
                                                    <span>Durată proiect</span>
                                                    <h5>{getProjectDuration()}</h5>
                                                </li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    

                </div>
            </div>
        </React.Fragment>
    );


}

export default ProjectDetails;