import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { mobileMenu } from '../../../runJQuery';
import { signOut } from '../../../actions';

function Sidebar(props) {
	const checkActiveLink = (match) => {
		if (props.location.pathname === match) return 'active';
	}

	useEffect(mobileMenu, []);

	return (
		<div className="dashboard-sidebar">
			<div className="dashboard-sidebar-inner" data-simplebar>
				<div className="dashboard-nav-container">

					{/*~~ Responsive Navigation Trigger ~~*/}
					<a href="#" className="dashboard-responsive-nav-trigger">
						<span className="hamburger hamburger--collapse" >
							<span className="hamburger-box">
								<span className="hamburger-inner"></span>
							</span>
						</span>
						<span className="trigger-title">Dashboard Navigation</span>
					</a>

					{/*~~ Navigation ~~*/}
					<div className="dashboard-nav">
						<div className="dashboard-nav-inner">

							<ul data-submenu-title="Dashboard">
								<li className={checkActiveLink('/dashboard')}>
									<Link to="/dashboard"><i className="icon-material-outline-folder"></i> Proiectele tale</Link>
								</li>
								<li className={checkActiveLink('/dashboard/createProject')}>
									<Link to="/dashboard/createProject"><i className="icon-material-outline-gavel"></i> Postează un proiect</Link>
								</li>
								<li className={checkActiveLink('/dashboard/promotions')}>
									<Link to="/dashboard/promotions"><i className="icon-material-outline-star-border"></i> Promovare</Link>
								</li>
								<li className={checkActiveLink('/dashboard/unlocked-candidates')}>
									<Link to="/dashboard/unlocked-candidates"><i className="icon-feather-unlock"></i> Profesionisti deblocați</Link>
								</li>
							</ul>

							<ul data-submenu-title="Administrare">
								<li className={checkActiveLink('/profile')}>
									<Link to="/dashboard/profile"><i className="icon-material-outline-settings"></i> Setări</Link>
								</li>
								<li><a onClick={signOut} ><i className="icon-material-outline-power-settings-new"></i> Logout</a></li>
							</ul>

						</div>
					</div>
					{/*~~ Navigation / End ~~*/}

				</div>
			</div>
		</div>
	);
}

export default withRouter(Sidebar);