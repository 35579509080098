import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { signOut } from '../../../actions';
import useClickToOpen from '../../../hooks/useClickToOpen';

function UserMenu(props) {
	let ref = React.createRef();
	let [active, toggleState] = useClickToOpen(ref);

	// Helpers
	const classFromState = () => {
		if (active) return 'header-notifications user-menu active';
		return 'header-notifications user-menu';
	}

	const getUserType = () => {
		switch(props.userType) {
			case "CLIENT" : return "Client"
			case "WORKER" : return "Freelancer"
			case "ADMIN" : return "Admin"
		}

	}

	return (
		<div id="user-widget" className="header-widget">
			<div className={classFromState()} ref={ref}>
				<div className="header-notifications-trigger" onClick={toggleState}>
					<button><div className="user-avatar status-online"><img src="/images/user-avatar-placeholder.png" alt="" /></div></button>
				</div>
				<div className="header-notifications-dropdown">
					<div className="user-status">
						<div className="user-details">
							<div className="user-avatar status-online"><img src="/images/user-avatar-placeholder.png" alt="" /></div>
							<div className="user-name">
								{props.name} <span>{getUserType()}</span>
							</div>
						</div>

						{/* <div className="status-switch" id="snackbar-user-status">
							<label className="user-online current-status">Online</label>
							<label className="user-invisible">Invisible</label>
							<span className="status-indicator" aria-hidden="true"></span>
						</div> */}
					</div>

					<ul className="user-menu-small-nav">
						<li><Link to="/dashboard" onClick={toggleState}><i className="icon-material-outline-dashboard"></i> Dashboard</Link></li>
						<li><Link to="/dashboard/profile"> <i className="icon-material-outline-settings"></i> Setări profil</Link></li>
						<li><a href="#" onClick={signOut}><i className="icon-material-outline-power-settings-new"></i> Logout</a></li>
					</ul>

				</div>
			</div>
		</div >
	);
}

const mapStateToProps = (state) => ({
	name: state.user.profile.fullName,
	userType: state.user.profile.userType
});

export default connect(mapStateToProps)(UserMenu);