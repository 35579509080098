import React, {useEffect} from "react"
import { rippleEffect } from '../../../runJQuery';
import { Link, NavLink } from "react-router-dom";

const ProjectItem = (props) => {

    useEffect(() => {
        rippleEffect();
    }, []);

    const getStatusClassForProject = () => {
        if(props.status === 'DRAFT') {
            return `dashboard-status-button yellow`
        }

        if(props.status === 'ACTIVE') {
            return `dashboard-status-button green`
        }

        if(props.status === 'IN_AUCTION') {
            return `dashboard-status-button green`
        }

        if(props.status === 'FINISHED') {
            return `dashboard-status-button`
        }
    };

    const getStatusForProject = () => {
        if (props.status === "DRAFT") {
            return "Draft";
        }

        if (props.projectType === 'RECRUIT') {
            if (props.status === "IN_AUCTION") {
                return "Activ";
            }
            if (props.status === "ACTIVE") {
                return "Activ";
            }
        }
        
        if (props.projectType === 'BID') {
            if (props.status === "IN_AUCTION") {
                return "Licitație activă";
            }
            if (props.status === "ACTIVE") {
                return "Proiect atribuit";
            }
        }
        return props.status;
    };


    const getProjectType = () => {
        if (props.projectType === 'RECRUIT')
            return "Proiect Recrutare";
        if (props.projectType === 'BID')
            return "Licitație";
    }

    const deleteHandler = () => {
        props.onDelete(props.id, props.clientId);
    };

    const activateHandler = () => {
        props.onActivate(props.id);
    }

    const currentProjectDate = new Date(props.createdAt).toLocaleDateString("ro-RO", 
    {
        day: 'numeric', // numeric, 2-digit
        year: 'numeric', // numeric, 2-digit
        month: 'long', // numeric, 2-digit, long, short, narrow
    });

    let expiringDate = new Date();
    
    expiringDate.setDate(expiringDate.getDate() + 10);

    expiringDate = expiringDate.toLocaleDateString("ro-RO", 
    {
        day: 'numeric', // numeric, 2-digit
        year: 'numeric', // numeric, 2-digit
        month: 'long', // numeric, 2-digit, long, short, narrow
    });

    return (
            <React.Fragment>
                
                    {/*~~ Job Listing ~~*/}
                    <div className="job-listing">

                        {/*~~ Job Listing Details ~~*/}
                        <div className="job-listing-details">

                        
                            <div className="job-listing-description">
                                <Link to={`/project/${props.id}`}>
                                <h3 className="job-listing-title"><a href="#">{props.title} - {getProjectType()}</a> <span className={getStatusClassForProject()}>{getStatusForProject()}</span></h3>
                                </Link>
                                {/*~~ Job Listing Footer ~~*/}
                                <div className="job-listing-footer">
                                    <ul>
                                        <li><i className="icon-material-outline-date-range"></i> {`Postat pe ${currentProjectDate}`}</li>
                                        <li><i className="icon-material-outline-date-range"></i> {`Expiră pe ${expiringDate}`}</li>
                                        {props.promoted === true &&
                                            <li><i className="icon-material-outline-star"></i>   <strong>Promoție activă</strong></li>
                                        }
                                        {props.urgent === true && <li><mark className="color" ><span className="icon-material-outline-alarm"></span> Urgent</mark></li>}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*~~ Buttons ~~*/}
                    <div className="buttons-to-right always-visible">
                        <NavLink className="button ripple-effect" to={`/dashboard/${props.id}/candidates`}><i className="icon-material-outline-supervisor-account"></i> Administrează candidați <span className="button-info">{props.candidatesCounter}</span></NavLink>
                        {props.promoted === true && <NavLink className="button dark ripple-effect" to={`/dashboard/promotions`}><i className="icon-material-outline-star"></i> Vezi promoții active</NavLink>}
                        {props.status != "IN_AUCTION" && props.status != "ACTIVE" && props.projectType === 'BID' && <a href="#" className="button dark ripple-effect" onClick={activateHandler}><i className="icon-material-outline-gavel"></i> Activează Licitație</a>}
                        {props.status != "IN_AUCTION" && props.status != "ACTIVE" && props.projectType === 'RECRUIT' && <a href="#" className="button dark ripple-effect" onClick={activateHandler}><i className="icon-material-outline-gavel"></i> Activează Proiect</a>}
                        {/* <a href="#" className="button gray ripple-effect ico" title="Edit" data-tippy-placement="top"><i className="icon-feather-edit"></i></a> */}
                        <a href="#" className="button gray ripple-effect ico" title="Remove" data-tippy-placement="top" onClick={deleteHandler}><i className="icon-feather-trash-2"></i></a>
                    </div>
                    
            </React.Fragment>
        );
};

export default ProjectItem;