import { addNotification } from '../../../actions';
import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { host } from '../../../constants/constants';
import { rippleEffect } from '../../../runJQuery';

const ProjectPromotion = () => {

    const [projects, setProjects] = useState([]);
	const [projectsUpdated, setProjectsUpdated] = useState(true);
	const [availableCredits, setAvailableCredits] = useState(0);
    const [selectedPromotionDays, setSelectedPromotionDays] = useState(7);

	useEffect(() => {
		rippleEffect();
	}, []);

    const fetchClientProjects = () => {
		axios({
			method: 'GET',
			url: `${host}/project?pageNumber=0&pageSize=100`,
			withCredentials: true
		}).then(res => {
			setProjects(res.data.content);
		}).catch(err => {
			console.log(err.response);
			addNotification('error', 'Nu s-au putut incarca proiectele! Încearcă în câteva minute.');
		});
	};

    const fetchAvailableCredits = () => {   
        const options = {
            method: 'GET',
            url: `${host}/profile`,
            withCredentials: true
        };
        axios(options)
        .then(res => {
            setAvailableCredits(res.data.credits);
        })
        .catch(err => addNotification('error', "Nu s-au putut incarca datele! Încearcă în câteva minute."));
    }

    const promoteProject = (project) => {
        const data = {
            promotionId : 2,
            multiplier : selectedPromotionDays / 7
        }
        
        const options = {
            method: 'POST',
            url: `${host}/promotions/project/${project.id}`,
            data,
            withCredentials: true
        };
        axios(options)
        .then(res => {
            setAvailableCredits(res.data.credits);
            setProjectsUpdated(true);
        })
        .catch(err => addNotification('error', "Credite insuficiente."));
    }

    useEffect(() => {
		if(projectsUpdated == true) {
			fetchClientProjects();
			fetchAvailableCredits();
			setProjectsUpdated(false);
		}
	}, [projectsUpdated]);
    
    const decreaseSelectedDays = () => {
        if(selectedPromotionDays > 7)
        setSelectedPromotionDays(prev => prev - 7);
    }

    const increaseSelectedDays = () => {
        setSelectedPromotionDays(prev => prev + 7);
    }

    const controlDaysInput = (e) => {
        const amount = e.target.value;
        if (amount >= 7 && amount % 7 === 0)
            setSelectedPromotionDays(amount);
    };

    const currentProjectDate = (date) =>{
        return  new Date(date).toLocaleDateString("ro-RO", 
        {
            day: 'numeric', // numeric, 2-digit
            year: 'numeric', // numeric, 2-digit
            month: 'long', // numeric, 2-digit, long, short, narrow
        });
    }

    let expiringDate = new Date();
    
    expiringDate.setDate(expiringDate.getDate() + 10);

    expiringDate = expiringDate.toLocaleDateString("ro-RO", 
    {
        day: 'numeric', // numeric, 2-digit
        year: 'numeric', // numeric, 2-digit
        month: 'long', // numeric, 2-digit, long, short, narrow
    });

    return (
		<div class="dashboard-content-container" data-simplebar>
			<div className="dashboard-content-inner" >

				{/*~~ Dashboard Headline ~~*/}
				<div className="dashboard-headline">
					<h3>Promovează proiecte</h3>

					<span>Credite valabile:  {availableCredits}</span>
				</div>

				{/*~~ Row ~~*/}
				<div className="row">

					{/*~~ Dashboard Box ~~*/}
					<div className="col-xl-12">
						<div className="dashboard-box margin-top-0">

							{/*~~ Headline ~~*/}
							<div className="headline">
								<h3><i className="icon-material-outline-business-center"></i> Proiecte</h3>
							</div>

							<div className="content">
								<ul className="dashboard-box-list">
									{projects.map((project) => (
										<li>
											 {/*~~ Job Listing ~~*/}
                                             <div className="job-listing">

                                            {/*~~ Job Listing Details ~~*/}
                                            <div className="job-listing-details">


                                                <div className="job-listing-description">
                                                    <Link to={`/project/${project.id}`}>
                                                        <h3 className="job-listing-title"><a href="#">{project.title}</a></h3>
                                                    </Link>
                                                    {/*~~ Job Listing Footer ~~*/}
                                                    <div className="job-listing-footer">
                                                        <ul>
                                                            <li><i className="icon-material-outline-date-range"></i> {`Postat pe ${currentProjectDate(project.createdAt)}`}</li>
                                                            <li><i className="icon-material-outline-date-range"></i> {`Expiră pe ${expiringDate}`}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                            {project.activePromotions === null &&
                                                 <Fragment>
                                                    <h4>Selecteaza numarul de zile</h4>
                                                    <div className="bidding-inner margin-left-20" >
                                                        
                                                        <div className="bidding-fields" style={{maxWidth: "150px" }}>
                                                            <div className="bidding-field">
                                                                
                                                                <div className="qtyButtons">
                                                                    <div className="qtyDec" onClick={decreaseSelectedDays}></div>
                                                                    <input type="text" name="qtyInput" value={selectedPromotionDays} onChange={controlDaysInput}/>
                                                                    <div className="qtyInc" onClick={increaseSelectedDays}></div>
                                                                </div>
                                                            </div>
                                                        </div>  
                                                    </div>
                                                    <div className="buttons-to-right always-visible">
                                                        <button className="button  ripple-effect" title="Promovează" onClick={(e) => promoteProject(project)}><i className="icon-material-outline-star"></i>Promovează proiect</button>
                                                    </div>
                                                </Fragment>
                                            }
                                            {project.activePromotions != null && project.activePromotions.activePromotions != null &&
                                                project.activePromotions.activePromotions.map(promotion => 
                                                    <div class="notification warning">
                                                        <p><i className="icon-material-outline-star"></i>   <strong>Promoție activă</strong></p>
                                                        <p><i className="icon-material-outline-date-range"></i> {`Data început promovare: ${currentProjectDate(promotion.startDate)}`}</p>
                                                        <p><i className="icon-material-outline-date-range"></i> {`Data sfârșit promovare: ${currentProjectDate(promotion.endDate)}`}</p>
                                                    </div>
                                                )
                                            }
										</li>
									))}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ProjectPromotion;