import React from 'react';
import { Route } from 'react-router-dom';
import Sidebar from './Sidebar';
import Projects from './Projects';
import CreateProject from './CreateProject';
import ClientProfileUpdate from './ClientProfileUpdate';
import ManageCandidates from './ManageCandidates';
import ProjectPromotion from './ProjectPromotion';
import UnlockedWorkers from './UnlockedWorkers';

function Dashboard(props) {
	return (
		<div className="dashboard-container">
			<Sidebar />

			<div className="dashboard-content-container">
				<Route path="/dashboard/" exact component={Projects} />
				<Route path="/dashboard/createProject" exact component={CreateProject} />
				<Route path="/dashboard/profile" exact component={ClientProfileUpdate} />
				<Route path="/dashboard/:projectId/candidates" exact component={ManageCandidates} />
				<Route path="/dashboard/promotions" exact component={ProjectPromotion} />
				<Route path="/dashboard/unlocked-candidates" exact component={UnlockedWorkers} />
			</div>
		</div>
	);
};

export default Dashboard;