import React, { Fragment, useState } from 'react';
import Dropdown from '../util/Dropdown';
import { useHistory } from 'react-router-dom';
import categories from "../../constants/categories.json"

const SearchBar = () => {

	const [searchBarContent, setSearchBarContent] = useState("");
	const [category, setCategory] = useState('-1');

	const history = useHistory();

	const handleChange = (e) => {
		setSearchBarContent(e.target.value);
	};

	const controlCategory = (category) => {
		setCategory(category);
	}

	const handleSearch = () => {
		if (category != '-1')
			history.push(`/searchProject?projectTerm=${searchBarContent}&categoryParam=${category}`);
		history.push(`/searchProject?projectTerm=${searchBarContent}`);
	}

	// categories
	const mainCategories = categories
		.filter(cat => cat.parent === "-1");

		return (
			<Fragment>
				<div className="container margin-top-15" style={{ textAlign: "center" }}>
					<a href="https://ec.europa.eu/agriculture/cap-funding/funding-opportunities_ro">
						<img style={{ border: "none", maxWidth: "90px" }} src="/images/logo-ue-nou.svg" alt="" />
					</a>

					<a href="https://www.madr.ro/" className="margin-left-20">
						<img style={{ border: "none", maxWidth: "90px" }} src="/images/logo-gov.svg" alt="" />
					</a>

					<a href="https://www.pndr.ro" className="margin-left-20">
						<img style={{ border: "none", maxWidth: "90px" }} src="/images/logo_pndr.svg" alt="" />
					</a>

					<a href="https://afir.info" className="margin-left-20">
						<img style={{ border: "none", maxWidth: "90px" }} src="/images/logo_afir.svg" alt="" />
					</a>
				</div>

				<div style={{ textAlign: "center" }} className="margin-bottom-10">
					<a href="https://ec.europa.eu/agriculture/cap-funding/funding-opportunities_ro">
						Pentru mai multe informații privitoare la FEADR vă rugăm să accesați acest link.
					</a>
				</div>


			<div className="intro-banner dark-overlay big-padding">
				{/*<!-- Transparent Header Spacer -->*/}
				<div className="transparent-header-spacer"></div>

				<div className="container">
					{/*<!-- Intro Headline -->*/}
					<div className="row">
						<div className="col-md-12">
							<div className="banner-headline-alt">
								<h3>Esti freelancer sau cauti un proiect pe cont propriu?</h3>
								<span>Găseste cele mai bune proiecte din industria ta</span>
							</div>
						</div>
					</div>

					{/*<!-- Search Bar -->*/}
					<div className="row">
						<div className="col-md-12">
							<div className="intro-banner-search-form margin-top-95">

								{/*<!-- Search Field -->*/}
								<div className="intro-search-field">
									<label htmlFor="intro-keywords" className="field-title ripple-effect">Cauți un proiect ?</label>
									<input
										id="intro-keywords"
										type="text"
										placeholder="Titlu proiect sau cuvinte cheie"
										onChange={handleChange}
										value={searchBarContent}
									/>
								</div>

								{/*<!-- Search Field -->*/}
								<div id="select-category" className="intro-search-field">
									<Dropdown
                                        onChange={controlCategory}
                                        options={[{ text: "Toate categoriile", value: "-1" },
                                        ...mainCategories.map(categ => {
                                            return { text: categ.name, value: categ.id }
                                        })]}
                                    />
								</div>

								{/*<!-- Button -->*/}
								<div className="intro-search-button">
									<button className="button ripple-effect" onClick={handleSearch}>Caută proiect</button>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/*<!-- Background image container -->*/}
				<div className="video-container"></div>
			</div>
			</Fragment>
		);
	
}

export default SearchBar;