import { addNotification } from '../../../actions';
import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { host } from '../../../constants/constants';
import { rippleEffect } from '../../../runJQuery';

const Promotion = () => {
    const [workerUpdated, setWorkerUpdated] = useState(true);
    const [worker, setWorker] = useState({
        credits: 0
    });
    const [selectedPromotionDays, setSelectedPromotionDays] = useState(7);

    useEffect(() => {
        rippleEffect();
    }, []);

    const fetchAvailableCredits = () => {
        const options = {
            method: 'GET',
            url: `${host}/profile`,
            withCredentials: true
        };
        axios(options)
            .then(res => {
                setWorker(res.data);
            })
            .catch(err => addNotification('error', "Nu s-au putut incarca datele! Încearcă în câteva minute."));
    }

    const promoteWorker = (worker) => {
        const data = {
            promotionId: 1,
            multiplier: selectedPromotionDays / 7
        }

        const options = {
            method: 'POST',
            url: `${host}/promotions/worker/1`,
            data,
            withCredentials: true
        };
        axios(options)
            .then(res => {
                setWorkerUpdated(true);
            })
            .catch(err => addNotification('error', "Credite insuficiente."));
    }

    useEffect(() => {
        if (workerUpdated == true) {
            fetchAvailableCredits();
        }
    }, [workerUpdated]);

    const decreaseSelectedDays = () => {
        if (selectedPromotionDays > 7)
            setSelectedPromotionDays(prev => prev - 7);
    }

    const increaseSelectedDays = () => {
        setSelectedPromotionDays(prev => prev + 7);
    }

    const controlDaysInput = (e) => {
        const amount = e.target.value;
        if (amount >= 7 && amount % 7 === 0)
            setSelectedPromotionDays(amount);
    };

    const currentProjectDate = (date) => {
        return new Date(date).toLocaleDateString("ro-RO",
            {
                day: 'numeric', // numeric, 2-digit
                year: 'numeric', // numeric, 2-digit
                month: 'long', // numeric, 2-digit, long, short, narrow
            });
    }

    let expiringDate = new Date();

    expiringDate.setDate(expiringDate.getDate() + 10);

    expiringDate = expiringDate.toLocaleDateString("ro-RO",
        {
            day: 'numeric', // numeric, 2-digit
            year: 'numeric', // numeric, 2-digit
            month: 'long', // numeric, 2-digit, long, short, narrow
        });

    return (
        <div class="dashboard-content-container" data-simplebar>
            <div className="dashboard-content-inner" >

                {/*~~ Dashboard Headline ~~*/}
                <div className="dashboard-headline">
                    <h3>Promovare</h3>

                    <span>Credite valabile:  {worker.credits}</span>
                </div>

                {/*~~ Row ~~*/}
                <div className="row">

                    {/*~~ Dashboard Box ~~*/}
                    <div className="col-xl-12">
                        <div className="dashboard-box margin-top-0">

                            <div className="content">
								<ul className="dashboard-box-list">
                                <li>
                            <h4>Selecteaza numarul de zile</h4>
                            <div className="bidding-inner margin-left-20" >

                                <div className="bidding-fields" style={{ maxWidth: "150px" }}>
                                    <div className="bidding-field">

                                        <div className="qtyButtons">
                                            <div className="qtyDec" onClick={decreaseSelectedDays}></div>
                                            <input type="text" name="qtyInput" value={selectedPromotionDays} onChange={controlDaysInput} />
                                            <div className="qtyInc" onClick={increaseSelectedDays}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="buttons-to-right always-visible">
                                <button className="button  ripple-effect" title="Promovează" onClick={(e) => promoteWorker(worker)}><i className="icon-material-outline-star"></i>Promovează</button>
                            </div>

                            {worker.activePromotions != null && worker.activePromotions.activePromotions != null &&
                                worker.activePromotions.activePromotions.map(promotion =>
                                    <div class="notification warning">
                                        <p><i className="icon-material-outline-star"></i>   <strong>Promoție activă</strong></p>
                                        <p><i className="icon-material-outline-date-range"></i> {`Data început promovare: ${currentProjectDate(promotion.startDate)}`}</p>
                                        <p><i className="icon-material-outline-date-range"></i> {`Data sfârșit promovare: ${currentProjectDate(promotion.endDate)}`}</p>
                                    </div>
                                )
                            }
                            </li>
                            </ul>
                            </div>
         
                        </div>
                    </div>
                </div>
            </div>
        </div>
	);
}

export default Promotion;