import React, { Fragment } from "react"
import { Link } from "react-router-dom";
import categories from "../../constants/categories.json"

const ProjectItem = (props) => {


    const getProjectLocation = () => {
        if (props.workLocation === "REMOTE" ) {
            return <li><i className="icon-material-outline-location-off"></i> Remote </li>;
        }

        if (props.workLocation === "UNSPECIFIED" ) {
            return <li><i className="icon-material-outline-location-off"></i> Neprecizat </li>;
        }

        if (props.workLocation === "HQ" || props.workLocation === "ON_SITE") {
            return <li><i className="icon-material-outline-location-on"></i> {props.workCity} </li>;
        }
    };

    const getProjectDescription = () => {
        return props.description.substring(0, 200).concat("...");
    }

    const getCurrencySymbol = () => {
        switch(props.currency) {
            case "USD" : return "$";
            case "EUR" : return "EUR";
            default : return "RON";
        }
    }

    const getProjectBudget = () => {
        return `${props.budget} ${getCurrencySymbol()}`;
    }

    const getPaymentType = () => {
        if (props.projectPaymentType === "PROJECT")
            return "Preț pe proiect";
        if (props.projectPaymentType === "HOUR")
            return "Plata pe oră";
        if (props.projectPaymentType === "MONTH")
            return "Plata pe lună";
        if (props.projectPaymentType === "FIXED")
            return "Preț fix";
    }

    const getCategoryNameById = (catId) => {
		if(catId && catId.id != "-1")
			return categories.find(category => category.id === parseInt(catId.id)).name;
		else
			return "nicio categorie";
	}


    // function timeDifference(date1,date2) {
    //     var difference = date1.getTime() - date2.getTime();
    
    //     var daysDifference = Math.floor(difference/1000/60/60/24);
    //     difference -= daysDifference*1000*60*60*24
    
    //     var hoursDifference = Math.floor(difference/1000/60/60);
    //     difference -= hoursDifference*1000*60*60
    
    //     var minutesDifference = Math.floor(difference/1000/60);
    //     difference -= minutesDifference*1000*60
    
    //     console.log('difference = ' + 
    //       daysDifference + ' day/s ' + 
    //       hoursDifference + ' hour/s ' + 
    //       minutesDifference + ' minute/s ');
    // }


    // timeDifference(new Date() , props.creadtedDate);

    if (props.categories)
        props.categories.sort()

    return (
        <Fragment>
            
            {/* Task */}
            
            <a class="task-listing">
                 

                {/* Job Listing Details */}
                {props.promoted == true && <span class="bookmark-icon bookmarked"></span>}
                
                <div class="task-listing-details">
                
                
                <Link to={`/project/${props.id}`}>
                    {/*} Details */}
                    
                    <div class="task-listing-description">
                   
                    
                        <h3 class="task-listing-title">{props.title}</h3>
                        
                        <ul class="task-icons">
                            { getProjectLocation() }
                            <li><i class="icon-material-outline-access-time"></i> {
                                                    new Date(props.datePosted).toLocaleDateString("ro-RO", 
                                                    {
                                                        day: 'numeric', // numeric, 2-digit
                                                        year: 'numeric', // numeric, 2-digit
                                                        month: 'long', // numeric, 2-digit, long, short, narrow
                                                    })}</li>
                        </ul>

                        {(props.categories!= null && props.categories && props.categories[0]) && <ul class="task-icons">
                            <li><i className="icon-material-outline-local-offer"></i> {getCategoryNameById(props.categories[0])}</li>
                            {props.categories[1] && <li><i className="icon-material-outline-local-offer"></i> {getCategoryNameById(props.categories[1])}</li>}
                            
                        </ul>}

                        <p class="task-listing-text">{getProjectDescription()}</p>
                        <div className="task-tags">
                            {props.skills && props.skills.map(skill =>
                                <span>{skill}</span>
                            )}
                        </div>
                    </div>
                    </Link>
                    
                </div>

                <div class="task-listing-bid">
                    <div class="task-listing-bid-inner">
                    
                        <div class="task-offers">
                            <strong>{getProjectBudget()}</strong>
                            <span>{getPaymentType()}</span>
                        </div>
                        <Link to={`/project/${props.id}`}><span className="button button-sliding-icon ripple-effect"> Aplică <i className="icon-material-outline-arrow-right-alt"></i></span></Link>
                    </div>
                </div>
            </a>
            
        </Fragment>
    );
};

export default ProjectItem;