import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { tippyEffect } from '../../runJQuery';

const Packages = () => {

    useEffect(() => {
        tippyEffect();
    },[]);

    return (
        <React.Fragment>
            <div id="titlebar" class="gradient">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h2>Pachete promoționale</h2>
                            <nav id="breadcrumbs" class="dark">
                                <ul>
                                    <li><Link to="/">Pagină principală</Link></li>
                                    <li>Pachete promoționale</li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row justify-content-center" >
                    <div className="col-xl-12" style={{maxWidth: "500px", position: "centered"}}>
                            {/* Plan */}
                            <div className="pricing-plan recommended"  >
                                {/* <div className="recommended-badge">Recommended</div> */}
                                <h3>Pachet standard</h3>
                                <p className="margin-top-10">Alege numărul de credite în funcție de nevoile tale</p>
                                <div className="pricing-plan-label"><strong>10 RON</strong>/ credit</div>
                                <div className="pricing-plan-features">
                                    <strong>Pentru profesioniști</strong>
                                    <ul>
                                        <li><b>10 credite</b> - Promovare profil profesionist
                                            <i class="help-icon" data-tippy-placement="right" 
                                                    title="Profilul tău apare în lista de căutari promovat timp de 7 zile"></i>
                                        </li>
                                    </ul>
                                    <br/>
                                    <strong>Pentru clienți</strong>
                                    <ul>
                                        <li><b>10 credite</b> - Postare proiect 
                                                  <i class="help-icon" data-tippy-placement="right" 
                                                    title="Proiectul tău va fi publicat pentru 30 de zile."></i>
                                        </li>
                                        <li><b>10 credite</b> - Promovare proiect
                                                    <i class="help-icon" data-tippy-placement="right" 
                                                    title="Proiectul tău va fi promovat timp de 7 zile cu actualizare automată."></i></li>
                                        <li><b>100 credite</b> - Organizare licitații <i class="help-icon" data-tippy-placement="right" 
                                                    title="Proiectul tău definit cu cerințe, buget, termen de realizare și modalitate de livrare deschis la licitație pentru aplicanți. Aplicanții și ratele la care acestia licitează vor fi disponibile doar clientului."></i>
                                        </li>
                                        <li><b>10 credite</b> - Deblocare date profesionist <i class="help-icon" data-tippy-placement="right" 
                                                    title="Deblochează datele de contact ale unui profesionist la alegere."></i>
                                        </li>
                                    </ul>
                                </div>
                                <Link to="/buy/standard"><a href="pages-checkout-page.html" className="button full-width margin-top-20 ripple-effect"> Cumpără acum </a></Link>
                            </div>
                    </div>
                </div>
            </div>
            <div class="margin-top-20"></div>
            <div class="margin-bottom-100"></div>
        </React.Fragment>
    );
}

export default Packages;