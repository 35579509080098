import React, { Fragment } from "react"

const SearchBarMini = (props) => {

    const handleOnChange = (e) => {
        props.handleChange(e);
    }

    return (
        <Fragment>
            
            <div className="col-md-16  margin-bottom-50 margin-top-20">
                <div className="intro-banner-search-form">

                    {/*<!-- Search Field -->*/}
                    <div className="intro-search-field">
                        <input
                            id="intro-keywords"
                            type="text"
                            placeholder={props.placeholder} //"Titlu proiect sau cuvinte cheie"
                            onChange={handleOnChange}
                            value={props.searchBarContent}
                        // onKeyPress={handleKeyPress}
                        />
                    </div>

                    {/*<!-- Button -->*/}
                    <div className="intro-search-button">
                        <button className="button ripple-effect" onClick={() => props.handleSearch()}>{props.searchButton}</button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default SearchBarMini;