import { addNotification } from '../../../actions';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import AppliedProjectItem from './AppliedProjectItem';
import { host } from '../../../constants/constants';
import { rippleEffect } from '../../../runJQuery';


const AppliedProjects = () => {

	const [projects, setProjects] = useState([]);
	const [projectsUpdated, setProjectsUpdated] = useState(true);
	const [availableCredits, setAvailableCredits] = useState(0);

	useEffect(() => {
		rippleEffect();
	}, []);

	const fetchClientProjects = () => {
		axios({
			method: 'GET',
			url: `${host}/worker/projects`,
			withCredentials: true
		}).then(res => {
			setProjects(res.data);
		}).catch(err => {
			console.log(err.response);
			addNotification('error', 'Nu s-au putut încărca proiectele. Încearcă în căteva minute.');
		});
	};

	const fetchAvailableCredits = () => {   
        const options = {
            method: 'GET',
            url: `${host}/profile`,
            withCredentials: true
        };
        axios(options)
        .then(res => {
            setAvailableCredits(res.data.credits);
        })
        .catch(err => addNotification('error', "Nu s-au putut incarca datele! Încearcă în câteva minute."));
    }

	useEffect(() => {
		if(projectsUpdated == true) {
			fetchClientProjects();
			fetchAvailableCredits();
			setProjectsUpdated(false);
		}
	}, [projects, projectsUpdated]);
	
	return (
		<div class="dashboard-content-container" data-simplebar>
			<div className="dashboard-content-inner" >

				{/*~~ Dashboard Headline ~~*/}
				<div className="dashboard-headline">
					<h3>Administrează proiecte</h3>

					<span>Credite valabile:  {availableCredits}</span>
				</div>

				{/*~~ Row ~~*/}
				<div className="row">

					{/*~~ Dashboard Box ~~*/}
					<div className="col-xl-12">
						<div className="dashboard-box margin-top-0">

							{/*~~ Headline ~~*/}
							<div className="headline">
								<h3><i className="icon-material-outline-business-center"></i> Proiecte la care ai aplicat</h3>
							</div>

							<div className="content">
								<ul className="dashboard-box-list">
									{projects.map((project) => (
										<li>
											<AppliedProjectItem 
											key={project.id}
											id={project.id}
                                            credits={availableCredits}
											title={project.title}
											createdAt={project.createdAt}
											status={project.status}
											urgent={project.urgent}
											clientId={project.clientId}
                                            applicant={project.applicants[0]}
											chosenApplicant={project.chosenApplicant}
											contactPerson={project.contactPerson}
											/>
										</li>
									))}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AppliedProjects;