import { ADD_APPLICATION_PROJECT, REMOVE_APPLICATION_PROJECT } from '../actions/types';

export default function (state = {}, action) {
	switch (action.type) {
		case ADD_APPLICATION_PROJECT:
			return action.payload;
		case REMOVE_APPLICATION_PROJECT:
			return '';
		default:
			return state;
	}
}

