import React from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

function Footer(props) {
	// hide footer in dashboard
	if (props.location.pathname.includes('/dashboard') 
	|| props.location.pathname.includes('/admin-dashboard') 
	|| props.location.pathname.includes('/return-payment')
	|| props.location.pathname.includes('/terms-and-conditions')) return null;

	return (
		<div id="footer">
			{/*<~~ Footer Top Section ~~>*/}
			<div className="footer-top-section">
				<div className="container">
					<div className="row">
						<div className="col-xl-12">

							{/*<~~ Footer Rows Container ~~>*/}
							<div className="footer-rows-container">

								{/*<~~ Left Side ~~>*/}
								<div className="footer-rows-left">
									<div className="footer-row">
										<div className="footer-row-inner footer-logo">
											<img src="/images/logo-white.svg" alt="" />
										</div>
									</div>
								</div>

								{/*<~~ Right Side ~~>*/}
								<div className="footer-rows-right">

									{/*<~~ Social Icons ~~>*/}
									<div className="footer-row">
										<div className="footer-row-inner">
											<ul className="footer-social-links">
												<li>
													<a href="https://www.facebook.com/LanceHubServices" title="Facebook" data-tippy-placement="bottom" data-tippy-theme="light">
														<i className="icon-brand-facebook-f"></i>
													</a>
												</li>
											</ul>
											<div className="clearfix"></div>
										</div>
									</div>

									{/*<~~ Language Switcher ~~>*/}
									<div className="footer-row">
										<div className="footer-row-inner">
											<select className="selectpicker language-switcher" data-selected-text-format="count" data-size="5">
												<option>English</option>
												<option>Français</option>
												<option>Español</option>
												<option>Deutsch</option>
											</select>
										</div>
									</div>
								</div>

							</div>
							{/*<~~ Footer Rows Container / End ~~>*/}
						</div>
					</div>
				</div>
			</div>
			{/*<~~ Footer Top Section / End ~~>*/}

			{/*<~~ Footer Middle Section ~~>*/}
			<div className="footer-middle-section">
				<div className="container">
					<div className="row" >

						{/*<~~ Links ~~>*/}
						<div className="col-xl-4 col-lg-2 col-md-3">
							<div className="footer-links">
								<h3>Pentru freelanceri</h3>
								<ul>
									<li><Link to="/searchProject"><span>Caută proiect</span></Link></li>
									<li><Link to="/packages"><span>Pachete promoționale</span></Link></li>
								</ul>
							</div>
						</div>

						{/*<~~ Links ~~>*/}
						<div className="col-xl-4 col-lg-2 col-md-3">
							<div className="footer-links">
								<h3>Pentru clienți</h3>
								<ul>
									<li><Link to="/searchFreelancer"><span>Caută freelanceri</span></Link></li>
									<li><Link to="/packages"><span>Pachete promoționale</span></Link></li>
								</ul>
							</div>
						</div>

						{/*<~~ Links ~~>*/}
						<div className="col-xl-4 col-lg-2 col-md-3">
							<div className="footer-links">
								<h3>Link-uri utile</h3>
								<ul>
									<li><Link to="/terms-and-conditions"><span>Termeni și condiții</span></Link></li>
									<li><Link to="/privacy-policy"><span>Politică de confidențialitate</span></Link></li>
									<li><Link to="/cookie-policy"><span>Politică cookie</span></Link></li>
								</ul>
							</div>
						</div>


						{/*<~~ Newsletter ~~>*/}
						{/* <div className="col-xl-4 col-lg-4 col-md-12">
							<h3><i className="icon-feather-mail"></i> Sign Up For a Newsletter</h3>
							<p>Weekly breaking news, analysis and cutting edge advices on job searching.</p>
							<form action="#" method="get" className="newsletter">
								<input type="text" name="fname" placeholder="Enter your email address" />
								<button type="submit"><i className="icon-feather-arrow-right"></i></button>
							</form>
						</div> */}
					</div>
				</div>
			</div>
			{/*<~~ Footer Middle Section / End ~~>*/}

			{/*<~~ Footer Copyrights ~~>*/}
			<div className="footer-bottom-section">
				<div className="container">
					<div className="row">
						<div className="col-xl-12">
							© 2021 <strong>Lance Hub</strong>. Toate drepturile rezervate.
				</div>
					</div>
				</div>
			</div>
			{/*<~~ Footer Copyrights / End ~~>*/}
		</div>
	);
}

export default withRouter(Footer);