import React from 'react';
import { BrowserRouter, Route,Switch } from 'react-router-dom';
import PrivateRoute from './util/PrivateRoute';
import Header from './Header';
import AuthModal from './auth/AuthModal';
import Notifier from './util/Notifier';
import Home from './Home';
import Footer from './Footer';
import Dashboard from './Dashboard';
import { getUserProfile } from '../actions';
import Packages from './packages';
import Checkout from './packages/Checkout';
import Blog from './blog';
import SearchProject from './SearchProject';
import ProjectDetails from './project/ProjectDetails';
import NotFound  from './not-found/NotFound';
import ApplyModal from './apply/ApplyModal';
import ReactGa from 'react-ga';
import SearchFreelancer from './SearchFreelancer';
import AdminDashboard from './Dashboard/admin/AdminDashboard';
import BlogPost from './blog/BlogPage';
import PaymentReturn from './packages/PaymentReturn';
import TermsAndConditions from './TermsAndCondition/index';
import PrivacyPolicy from './TermsAndCondition/PrivacyPolicy';
import CookiePolicy from './TermsAndCondition/CookiePolicy';
import CookieConsent, { Cookies } from "react-cookie-consent";
import FreelanceDetails from './Freelancer/FreelancerDetails';

class App extends React.Component {
	
	state = {
		checkingAuth: true
	};

	componentDidMount() {
		getUserProfile(() => {
			this.setState({ checkingAuth: false });
		});

		ReactGa.initialize("UA-159824334-1", []);
	}

	render() {
		if (this.state.checkingAuth) return null;

		return (
			<BrowserRouter>
				<Header />
				<AuthModal />
				<ApplyModal />
				<Switch>
					<Route path='/' exact component={Home} />
					<PrivateRoute path='/dashboard' component={Dashboard} />
					
					<PrivateRoute path='/admin-dashboard' component={AdminDashboard} />
					<PrivateRoute path='/buy/:packageType' component={Checkout} />
					<Route path="/packages" exact component={Packages} />
					<Route path="/searchProject" component={SearchProject} />
					<Route path="/searchFreelancer" component={SearchFreelancer} />
					<Route path="/blog" exact component={Blog} />
					<Route path="/blog/:postId" component={BlogPost} />
					<Route path="/project/:projectId" component={ProjectDetails} />
					<Route path="/return-payment" exact component={PaymentReturn} />
					<Route path="/terms-and-conditions" exact component={TermsAndConditions} />
					<Route path="/privacy-policy" exact component={PrivacyPolicy} />
					<Route path="/cookie-policy" exact component={CookiePolicy} />
					<Route path="/freelancer/:workerId" component={FreelanceDetails} />
					{/* <Route path='*' component={NotFound} /> */}
				</Switch>

				<Notifier />
				<Footer />

				{/* <CookieConsent
					location="bottom"
					buttonText="Accept Cookies"
					cookieName="CookieConsent"
					style={{ background: "#2B373B" }}
					buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
					expires={150}
					>
					This website uses cookies to enhance the user experience.{" "}
					<span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span>
					</CookieConsent> */}
			</BrowserRouter>
		);
	}
}

export default App;