import React, { Fragment, useEffect , useState} from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom"
import { rippleEffect, qtyEffect, paymentTabEffect } from '../../runJQuery';
import axios from 'axios';
import { host } from '../../constants/constants';
import { addNotification } from '../../actions';

const Checkout = () => {
    const [tabActive, setTabActive] = useState(0);
    const [selectedCredits, setSelectedCredits] = useState(1);
    const [paymentData, setPaymentData] = useState({});

    const { packageType } = useParams();

    const preparePayment = () => {
        const data = {
            creditId: 1,
            creditAmount: selectedCredits 
        }
        axios({
			method: 'POST',
			url: `${host}/credits/start-payment`,
			withCredentials: true,
            data
		}).then(res => {
			const paymentDetails = res.data;
            if (paymentDetails!= undefined) {
                postForm('https://sandboxsecure.mobilpay.ro/', 
                {
                    env_key : paymentDetails.paymentKey,
                    data : paymentDetails.paymentData
                })
            }
		}).catch(err => {
			addNotification('error', 'Nu s-a putut pregati plata');
		});
    };

    function postForm(path, params, method) {
        method = method || 'post';
    
        var form = document.createElement('form');
        form.setAttribute('method', method);
        form.setAttribute('action', path);
    
        for (var key in params) {
            if (params.hasOwnProperty(key)) {
                var hiddenField = document.createElement('input');
                hiddenField.setAttribute('type', 'hidden');
                hiddenField.setAttribute('name', key);
                hiddenField.setAttribute('value', params[key]);
    
                form.appendChild(hiddenField);
            }
        }
    
        document.body.appendChild(form);
        form.submit();
    }

    useEffect(() => {
        paymentTabEffect();
        rippleEffect();
        qtyEffect();
    }, []);

    const handleNetopiaTabClass = (tab) => {
        if(tab === tabActive) {
            return "payment-tab payment-tab-active";
        }
        return "payment-tab";
    }

    const handleTabSwitch = (tab) => {
        setTabActive(prev => tab);
    }

    const decreaseSelectedCredits = () => {
        if(selectedCredits > 1)
            setSelectedCredits(prev => prev-1);
    }

    const increaseSelectedCredits = () => {
        if( selectedCredits <= 5000)
            setSelectedCredits(prev =>  prev+1);
    }

    const getVat = () => {
        const price = selectedCredits *10;
        return price * 0.19 +" RON" ;
    }

    const getPriceWithoutVat = () => {
        const price = selectedCredits *10;
        return price - (price * 0.19) + " RON" ;
    }

    const getFinalPrice = () =>{ 
        return selectedCredits * 10 + " RON";
    };

    const controlCreditInput = (e) => {
        const amount = e.target.value;
        if (amount >= 1 && amount <= 5000)
            setSelectedCredits(amount);
    };

    const netopiaLogo = 
        <div style={{ fontFamily: "Roboto", fontSize: "22px"}}>
            <div className="container" style={{display: "flex", justifyContent: "left"}}>
                <div style={{backgroundColor: "black", padding: "20px", textAlign: "center", width: "25%", margin: "10px"}}>
                    <img style={{ display: "block", width: "100%"}} src="/images/white.svg" />
                </div>
            </div>
        </div>

    return (
        <Fragment>

            {/* Titlebar*/}
            <div id="titlebar" className="gradient">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">

                            <h2>Checkout</h2>

                            {/* Breadcrumbs */}
                            <nav id="breadcrumbs" className="dark">
                                <ul>
                                    <li><Link to="/" >Pagină principală</Link></li>
                                    <li><Link to="/packages"> Pachete promoționale </Link></li>
                                    <li>Checkout</li>
                                </ul>
                            </nav>

                        </div>
                    </div>
                </div>
            </div>


            {/* Content */}
            <div className="container">
                <div className="row">
                    <div className="col-xl-8 col-lg-8 content-right-offset">

                        {/* Hedaline */}
                        <h3>Număr credite</h3>

                        
                        <div className="bidding-inner margin-top-30" style={{maxWidth: "200px" }}>
                            
							<div className="bidding-fields" >
								<div className="bidding-field">
                                 
									<div className="qtyButtons">
										<div className="qtyDec" onClick={decreaseSelectedCredits}></div>
										<input type="text" name="qtyInput" value={selectedCredits} onChange={controlCreditInput}/>
										<div className="qtyInc" onClick={increaseSelectedCredits}></div>
									</div>
								</div>
                                <div class="margin-top-10 margin-left-10">
                                    <h5> Max 5000 credite</h5>
                                    </div> 
							</div>  
                        </div>
                        

                        {/* Hedline */}
                        <h3 className="margin-top-50">Metode de plată</h3>

                        {/* Payment Methods Accordion */}
                        <div className="payment margin-top-30">

                            <div className={handleNetopiaTabClass(0)} >
                                <div className="payment-tab-trigger" >
                                    {tabActive === 0 ? <input checked type="radio" name="cardType" id="creditCart" value="creditCard" onClick={() => handleTabSwitch(0)}/> :
                                    <input type="radio" name="cardType" id="creditCart" value="creditCard" onClick={() => handleTabSwitch(0)}/>}
                                    <label for="creditCart"><b>Credit / Debit Card</b></label>
                                    {netopiaLogo}
                                </div>

                                <div className="payment-tab-content">
                                    <p>Vei fi redirectat catre Netopia Payments pentru a efectua plata cu cardul.</p>
                                </div>
                            </div>


                            <div className={handleNetopiaTabClass(1)} >
                                <div class="payment-tab-trigger" >
                                    <input active id="paypal" name="cardType" type="radio" value="paypal" onClick={() => handleTabSwitch(1)}/>
                                    <label for="paypal"><b>Ordin de plată</b></label>
                                </div>

                                <div class="payment-tab-content">
                                    <p>Achită suma prevazută în sumarul de plată prin transfer bancar. Pentru factură trimite un mail către <b>office@lancehub.ro</b> cu datele de facturare. </p>
                                    <p><b>Nu uita sa adaugi in detalii plată emailul tău!</b></p>
                                    <br/>
                                    <p><b>Beneficiar:</b> Lance Hub Services SRL</p>
                                    <p><b>Banca:</b> ING Bank N.V. Amsterdam</p>
                                    <p><b>IBAN:</b> RO26 INGB 000 9999 0609 5147</p>
                                    <p><b>SWIFT:</b> INGBROBU</p>
                                    <p><b>Detalii plată:</b> emailul tău</p>
                                </div>
                            </div>

                        </div>
                        {/* Payment Methods Accordion / End */}
                        <button className="button big ripple-effect margin-top-40 margin-bottom-65" onClick={preparePayment}> Plătește </button>
                    </div>


                    {/* Summary */}
                    <div className="col-xl-4 col-lg-4 margin-top-0 margin-bottom-60">
                        
                        {/* Summary */}
                        <div className="boxed-widget summary margin-top-0">
                            <div className="boxed-widget-headline">
                                <h3>Sumar de plată</h3>
                            </div>
                            <div className="boxed-widget-inner">
                                <ul>
                                    <li>{`${selectedCredits} x Pachet credite standard`}<span>{getFinalPrice()}</span></li>
                                    {/* <li>TVA (19%) <span>{getVat()}</span></li> */}
                                    <li className="total-costs">Preț final<span>{getFinalPrice()}</span></li>
                                </ul>
                            </div>
                        </div>

                        
                        {/* Summary / End */}

                        {/* Checkbox */}
                        {/* <div className="checkbox margin-top-30">
                            <input type="checkbox" id="two-step"/>
                            <label for="two-step"><span className="checkbox-icon"></span>  I agree to the <a href="#">Terms and Conditions</a> and the <a href="#">Automatic Renewal Terms</a></label>
                        </div> */}
                    </div>
                    <div className="margin-bottom-100"/>
                </div>
            </div>
        </Fragment>
    );
}

export default Checkout;