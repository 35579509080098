import React from 'react';
import { useEffect } from 'react';
import { slickCarousel } from '../../runJQuery';

function FeaturedFreelancers() {
	useEffect(() => {
		slickCarousel();
	}, []);

	function renderFreelancer() {
		return (
			<div className="freelancer" >
				{/*<!-- Overview -->*/}
				< div className="freelancer-overview" >
					<div className="freelancer-overview-inner">

						{/*<!-- Bookmark Icon -->*/}
						<span className="bookmark-icon"></span>

						{/*<!-- Avatar -->*/}
						<div className="freelancer-avatar">
							<div className="verified-badge"></div>
							<a href="single-freelancer-profile.html"><img src="images/user-avatar-placeholder.png" alt="" /></a>
						</div>

						{/*<!-- Name -->*/}
						<div className="freelancer-name">
							<h4><a href="single-freelancer-profile.html">Tom Smith <img className="flag" src="images/flags/gb.svg" alt="" title="United Kingdom" data-tippy-placement="top" /></a></h4>
							<span>UI/UX Designer</span>
						</div>

						{/*<!-- Rating -->*/}
						{/* <div className="freelancer-rating">
							<div className="star-rating" data-rating="5.0"></div>
						</div> */}
					</div>
				</div>

				{/*<!-- Details -->*/}
				<div className="freelancer-details">
					<div className="freelancer-details-list">
						<ul>
							<li>Location <strong><i className="icon-material-outline-location-on"></i> London</strong></li>
							<li>Rate <strong>$60 / hr</strong></li>
							<li>Job Success <strong>95%</strong></li>
						</ul>
					</div>
					<a href="single-freelancer-profile.html" className="button button-sliding-icon ripple-effect">View Profile <i className="icon-material-outline-arrow-right-alt"></i></a>
				</div>
			</div>
		);
	}
	return (
		<div className="section gray padding-top-65 padding-bottom-70 full-width-carousel-fix">
			<div className="container">
				<div className="row">

					<div className="col-xl-12">
						{/*<!-- Section Headline -->*/}
						<div className="section-headline margin-top-0 margin-bottom-25">
							<h3>Highest Rated Freelancers</h3>
							<a href="freelancers-grid-layout.html" className="headline-link">Browse All Freelancers</a>
						</div>
					</div>

					<div className="col-xl-12">
						<div className="default-slick-carousel freelancers-container freelancers-grid-layout">
							{renderFreelancer()}
							{renderFreelancer()}
							{renderFreelancer()}
							{renderFreelancer()}
							{renderFreelancer()}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default FeaturedFreelancers;