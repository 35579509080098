import React from 'react';
import SignIn from './SignIn';
import SignUp from './SignUp';

class AuthModal extends React.Component {
	render() {
		return (
			<div id="sign-in-dialog" className="zoom-anim-dialog mfp-hide dialog-with-tabs">

				{/*<!-- Tabs -->*/}
				<div className="sign-in-form">

					<ul className="popup-tabs-nav">
						<li><a href="#login">Log In</a></li>
						<li><a href="#register">Register</a></li>
					</ul>

					<div className="popup-tabs-container">
						<SignIn />
						<SignUp />
					</div>
				</div>
			</div>
		);
	}
}

export default AuthModal;