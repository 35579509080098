import React, { Fragment, useEffect, useState } from "react"
import { useParams } from "react-router";
import axios from 'axios';
import { addNotification } from '../../../actions';
import { host } from '../../../constants/constants';
import { Link, NavLink } from "react-router-dom";

const ManageCandidates = () => {
    const [project, setProject] = useState({});
    const { projectId } = useParams();
    const [projectsUpdated, setProjectsUpdated] = useState(true);

    const fetchClientProject = () => {
        axios({
            method: 'GET',
            url: `${host}/project/${projectId}`,
            withCredentials: true
        }).then(res => {
            setProject(res.data);
        }).catch(err => {
            console.log(err.response);
            addNotification('error', 'Could not load projects. Please try again in few minutes');
        });
    };

    const acceptOffer = (applicant) => {
        setProjectsUpdated(true);

        axios({
            method: 'PATCH',
            url: `${host}/project/${projectId}/applicant/${applicant}`,
            withCredentials: true
        }).then(res => {
            addNotification('succes', 'Ofertă acceptată cu succes.');
            setProjectsUpdated(true);    
        }).catch(err => {
            addNotification('error', 'Nu s-a putut accepta oferta. Încercați în câteva minute.');
        });
    };

    useEffect(() => {
        if(projectsUpdated === true) {
            fetchClientProject();
            setProjectsUpdated(false);
        }
    }, [projectsUpdated]);

    const getDurationDetails = (applicant) => {
        if (applicant.paymentTypeRequested === 'PROJECT' && applicant.durationRequestedType === 'MONTHS') {
            return `${applicant.durationRequested} ${applicant.durationRequested > 1 ? "luni" : "lună"}`;
        }
        if (applicant.paymentTypeRequested === 'PROJECT' && applicant.durationRequestedType === 'DAYS') {
            return `${applicant.durationRequested} ${applicant.durationRequested > 1 ? "zile" : "zi"}`;
        }
        return "neprecizat";
    }

    const getPaymentType = (applicant) => {
        if (applicant.paymentTypeRequested === "PROJECT")
            return "Preț fix pe proiect";
        if (applicant.paymentTypeRequested === "HOUR")
            return "Plată pe oră";
        if (applicant.paymentTypeRequested === "MONTH")
            return "Plată pe lună";
        if (applicant.paymentTypeRequested === "FIXED")
            return "Preț fix pe proiect";
    }

    const getExperienceLevel = (applicant) => {
        switch (applicant.experienceLevel) {
            case 'JUNIOR' : return 'Junior';
            case 'MIDDLE' : return 'Mediu';
            case 'SENIOR' : return 'Senior';
        }
    }

    const handleAcceptOffer = (userId) => {
        acceptOffer(userId)
    }

    return (
        <Fragment>
            <div className="dashboard-content-inner" >

                {/* Dashboard Headline */}
                <div className="dashboard-headline">
                    <h3>Administrează candidați</h3>
                    <span className="margin-top-7">Aplicanți pentru <NavLink to={`/project/${projectId}`} >{project.title}</NavLink></span>

                    {/* Breadcrumbs */}
                    <nav id="breadcrumbs" className="dark">
                        <ul>
                            <li><Link to="/">Pagină principală</Link></li>
                            <li><Link to="/dashboard">Dashboard</Link></li>
                            <li>Administrează candidați</li>
                        </ul>
                    </nav>
                </div>

                {project.chosenApplicant != null && project.projectType === 'BID' &&
                 <div className="row">

                    {/* Dashboard Box */}
                    <div className="col-xl-12">
                        <div className="dashboard-box margin-top-0">

                            {/* Headline */}
                            <div className="headline">
                                <h3><i className="icon-material-outline-supervisor-account"></i> Candidat acceptat</h3>
                            </div>

                            <div className="content">
                                <ul className="dashboard-box-list">
                                    {project.chosenApplicant &&
                                            
                                            <li>
                                                {/* Overview */}
                                                <div className="freelancer-overview manage-candidates">
                                                    <div className="freelancer-overview-inner">

                                                        {/* Avatar */}
                                                        <div className="freelancer-avatar">
                                                            <a href="#"><img src="/images/user-avatar-placeholder.png" alt="" /></a>
                                                        </div>

                                                        {/* Name */}
                                                        <div className="freelancer-name">
                                                            <h4><a href="#">{project.chosenApplicant.userName} <img className="flag" src="images/flags/ro.svg" alt="" title="Romania" data-tippy-placement="top" /></a></h4>

                                                            {/* Details */}
                                                            <span className="freelancer-detail-item"><a href="#"><i className="icon-feather-mail"></i> {project.chosenApplicant.email}</a></span>
                                                            <span className="freelancer-detail-item"><i className="icon-feather-phone"></i> {project.chosenApplicant.phone}</span><br/>
                                                            <span className="freelancer-detail-item"><i className="icon-feather-clock"></i> {
                                                            new Date(project.chosenApplicant.dateOfAppliance).toLocaleDateString("ro-RO", 
                                                            {
                                                                day: 'numeric', // numeric, 2-digit
                                                                year: 'numeric', // numeric, 2-digit
                                                                month: 'long', // numeric, 2-digit, long, short, narrow
                                                            })}</span>
                                                            <span className="freelancer-detail-item"><i className="icon-feather-book"></i> Nivel de experiență: {getExperienceLevel(project.chosenApplicant)}</span>


                                                            {/* Rating */}
                                                            <br />
                                                            {/* <span className="company-not-rated">Minimum of 3 votes required</span> */}

                                                            {/* Bid Details */}
                                                            <ul className="dashboard-task-info bid-info">
                                                                <li><strong>{`${project.chosenApplicant.paymentRequested} ${project.chosenApplicant.paymentRequestedCurrency}`}</strong><span>{getPaymentType(project.chosenApplicant)}</span></li>
                                                                {project.chosenApplicant.paymentTypeRequested === 'PROJECT' &&
                                                                    <li><strong>{getDurationDetails(project.chosenApplicant)}</strong><span>Timp de livrare</span></li>
                                                                }
                                                            </ul>

                                                            {/* Buttons */}
                                                            <div className="buttons-to-right always-visible margin-top-25 margin-bottom-0">
                                                                <NavLink className="popup-with-zoom-anim button dark ripple-effect" to={`/freelancer/${project.chosenApplicant.userId}`}><i className="icon-material-outline-face"></i> Vezi profil</NavLink>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                }

                <div className="clearfix margin-top-40"/>

                {/* Row */}
                <div className="row">

                    {/* Dashboard Box */}
                    <div className="col-xl-12">
                        <div className="dashboard-box margin-top-0">

                            {/* Headline */}
                            <div className="headline">
                                <h3><i className="icon-material-outline-supervisor-account"></i> {project.applicants && project.applicants.length} candidați</h3>
                            </div>

                            <div className="content">
                                <ul className="dashboard-box-list">
                                    {project.applicants && project.applicants.length &&
                                        project.applicants.map(applicant =>

                                            <li>
                                                {/* Overview */}
                                                <div className="freelancer-overview manage-candidates">
                                                    <div className="freelancer-overview-inner">

                                                        {/* Avatar */}
                                                        <div className="freelancer-avatar">
                                                            <a href="#"><img src="/images/user-avatar-placeholder.png" alt="" /></a>
                                                        </div>

                                                        {/* Name */}
                                                        <div className="freelancer-name">
                                                            <h4><a href="#">{applicant.userName} <img className="flag" src="images/flags/ro.svg" alt="" title="Romania" data-tippy-placement="top" /></a></h4>

                                                            {/* Details */}
                                                            <span className="freelancer-detail-item"><a href="#"><i className="icon-feather-mail"></i> {applicant.email}</a></span>
                                                            <span className="freelancer-detail-item"><i className="icon-feather-phone"></i> {applicant.phone}</span><br/>
                                                            <span className="freelancer-detail-item"><i className="icon-feather-clock"></i> Data aplicării:  {
                                                            new Date(applicant.dateOfAppliance).toLocaleDateString("ro-RO", 
                                                            {
                                                                day: 'numeric', // numeric, 2-digit
                                                                year: 'numeric', // numeric, 2-digit
                                                                month: 'long', // numeric, 2-digit, long, short, narrow
                                                            })}</span>
                                                            <span className="freelancer-detail-item"><i className="icon-feather-book"></i> Nivel de experiență: {getExperienceLevel(applicant)}</span>
                                                            
                                                            {/* Rating */}
                                                            <br />
                                                            {/* <span className="company-not-rated">Minimum of 3 votes required</span> */}

                                                            {/* Bid Details */}
                                                            <ul className="dashboard-task-info bid-info">
                                                                <li><strong>{`${applicant.paymentRequested} ${applicant.paymentRequestedCurrency}`}</strong><span>{getPaymentType(applicant)}</span></li>
                                                                {applicant.paymentTypeRequested === 'PROJECT' &&
                                                                    <li><strong>{getDurationDetails(applicant)}</strong><span>Timp de livrare</span></li>
                                                                }
                                                            </ul>

                                                            {/* Buttons */}
                                                            <div className="buttons-to-right always-visible margin-top-25 margin-bottom-0">
                                                                {project.chosenApplicant === null && project.projectType === 'BID' &&<span key={applicant.userId} className="popup-with-zoom-anim button ripple-effect" onClick={() => handleAcceptOffer(applicant.userId)}><i className="icon-material-outline-check"></i> Acceptă Oferta</span>}
                                                                <Link className="popup-with-zoom-anim button dark ripple-effect" to={`/freelancer/${applicant.userId}`}><i className="icon-material-outline-face"></i> Vezi profil</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                
                
            </div>
        </Fragment>);
};

export default ManageCandidates;