import React from 'react';
import { Route } from 'react-router-dom';
import ActionLog from './ActionLog';
import AdminSidebar from './AdminSidebar';
import Transactions from './Transactions';

function AdminDashboard(props) {
	return (
		<div className="dashboard-container">
			<AdminSidebar />

			<div className="dashboard-content-container">
				<Route path="/admin-dashboard/" exact component={Transactions} />
				<Route path="/admin-dashboard/actions" exact component={ActionLog} />
			</div>
		</div>
	);
};

export default AdminDashboard;