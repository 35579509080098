import React, { Fragment, useEffect, useState } from "react";
import { addNotification } from '../../actions';
import { host } from '../../constants/constants';
import axios from 'axios';
import { useLocation} from "react-router";
import { Link } from 'react-router-dom';

const PaymentReturn = () => {

    const [transactionData, setTransactionData] = useState({
        status: "INITIATED"
    });

    const search = useLocation().search;
    const orderId = new URLSearchParams(search).get('orderId');

    const getTransactionStatus = () => {
        if (orderId == undefined) return addNotification('error', 'Lipseste tranzactia');

        axios({
			method: 'GET',
			url: `${host}/credits/transaction/${orderId}`,
			withCredentials: true
		}).then(res => {
            setTransactionData(res.data);
		}).catch(err => {
			console.log(err.response);
			addNotification('error', 'Nu s-au putut incarca datele tranzactiei');
		});
    }

    useEffect(() => {
        getTransactionStatus();
    },[]);

    const handleRefresh = () => {
        getTransactionStatus();
    }

    return (
        <React.Fragment>
            <div id="titlebar" class="gradient"> </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-12">

                        <div class="order-confirmation-page"> {
                            transactionData.status === "CONFIRMED_PENDING" || transactionData.status === "CONFIRMED" ?
                            <Fragment>
                                 <div class="breathing-icon"><i class="icon-feather-check"></i></div>
                                            <h2 class="margin-top-30">Îți mulțumim pentru comandă!</h2>
                                            <p>Contul tău va fi creditat conform pachetului ales.</p>
                                            <Link to="/" ><a  class="button ripple-effect-dark button-sliding-icon margin-top-30">Pagină principală <i class="icon-material-outline-arrow-right-alt"></i></a></Link>
                            </Fragment>
                                :
                            <Fragment>
                                <div class="breathing-icon"><i class="icon-feather-clock"></i></div>
                                            <h2 class="margin-top-30">Comanda se află în procesare!</h2>
                                            <a class="button ripple-effect-dark button-sliding-icon margin-top-30" onClick={handleRefresh}>Refresh <i class="icon-material-outline-arrow-right-alt"></i></a>
                            </Fragment>
                        }  
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default PaymentReturn;