import React, { useState } from 'react';
import useClickToOpen from '../../hooks/useClickToOpen';

function DropdownWithSearch(props) {
	let ref = React.createRef();
	let searchBox = React.createRef();
	let [active, toggleActive] = useClickToOpen(ref);
	let [searching, setSearching] = useState(false);
	let [suggestions, setSuggestions] = useState([]);

	// Select first option as default
	if (!props.selected) props.onChange(props.options[0]);

	/* Offer suggestions */
	function handleSearch(e) {
		const query = e.target.value.trim().toLowerCase();
		if (query) {
			if (!searching) setSearching(true);
			setSuggestions(props.options.filter(option => option.toLowerCase().startsWith(query)));
		} else {
			setSearching(false);
		}
	}

	function handleSelect(option) {
		searchBox.current.value = '';
		setTimeout(() => setSearching(false), 200);
		props.onChange(option);
	}

	function classFromState() {
		return (active) ? 'open' : '';
	}

	function renderOptions(options) {
		if (props.hideOptions && options !== suggestions) return null;

		return options.map(option => (
			<li key={option} className={option === props.selected ? 'selected active' : ''} onClick={() => handleSelect(option)}>
				<a>
					<span className="text">
						{option}
					</span>
					<span className="glyphicon glyphicon-ok check-mark"></span>
				</a>
			</li>
		));
	}

	return (
		<div className={`btn-group bootstrap-select with-border ${classFromState()}`} onClick={toggleActive} ref={ref}>
			<button type="button" className="btn dropdown-toggle btn-default">
				<span className="filter-option pull-left">{props.selected}</span>&nbsp;
				<span className="bs-caret"><span className="caret"></span></span>
			</button>
			<div className="dropdown-menu open" style={{ maxHeight: '479px', overflow: 'hidden', minHeight: '38px' }}>
				<div className="bs-searchbox" data-noclose>
					<input
						type="text"
						className="form-control"
						autoComplete="off"
						onChange={handleSearch}
						ref={searchBox}
						data-noclose
					/>
				</div>
				<ul className="dropdown-menu inner" style={{ maxHeight: '210px', overflowY: 'auto' }}>
					{searching ? renderOptions(suggestions) : renderOptions(props.options)}
				</ul>
			</div>
		</div>
	);

}

export default DropdownWithSearch;