import userReducer from './userReducer';
import notificationsReducer from './notificationsReducer';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import projectApplicationReducer from './projectApplicationReducer';

const reducers = combineReducers({
	user: userReducer,
	notifications: notificationsReducer,
	projectApplication: projectApplicationReducer
});

export default createStore(reducers, {}, applyMiddleware(reduxThunk));