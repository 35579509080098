import { LOADING_USER, SET_USER_PROFILE, SIGN_OUT } from '../actions/types';

const INITIAL_STATE = {
	authenticated: false,
	loading: false,
	profile: {},
};

function userReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_USER_PROFILE: 
			return { 
				...state,
				authenticated: true,
				profile: action.payload
			};
		case LOADING_USER:
			return {
				...state,
				loading: action.payload
			};
		case SIGN_OUT: 
			return INITIAL_STATE;
		default: 
			return state;
	}
}

export default userReducer;