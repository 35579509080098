import { thisExpression } from '@babel/types';
import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { signUp } from '../../actions';

class SignUp extends React.Component {
	state = {
		clientName: '',
		firstName: '',
		lastName: '',
		email: '',
		password: '',
		repeatPassword: '',
		freelancer: true,
		error: null,
		termsAccepted: false
	};

	/* Form control */
	setFreelancer = (value) => {
		this.setState({ freelancer: value });
	}

	controlClientName = (e) => {
		this.setState({ clientName: e.target.value });
	}

	controlFirstName = (e) => {
		this.setState({ firstName: e.target.value });
	}

	controlLastName = (e) => {
		this.setState({ lastName: e.target.value });
	}

	controlEmail = (e) => {
		this.setState({ email: e.target.value.trim() });
	}

	controlPassword = (e) => {
		this.setState({ password: e.target.value });
	}

	controlRepeatPassword = (e) => {
		this.setState({ repeatPassword: e.target.value });
	}

	handleTermsCheck = (e) => {
		this.setState({termsAccepted: !this.termsAccepted});
	}

	submitForm = (e) => {
		e.preventDefault();
		if (this.freelancer == true && !this.state.firstName) return this.setState({ error: 'Introdu prenume.' });
		if (this.freelancer == true && !this.state.lastName) return this.setState({ error: 'Introdu nume.' });
		if (this.freelancer == false && !this.state.clientName) return this.setState({ error: 'Introdu numele clientului.' });
		if (!this.state.email) return this.setState({ error: 'Introdu o adresă de email.' });
		if (!this.state.password) return this.setState({ error: 'Introdu parola.' });
		if (!this.state.repeatPassword) return this.setState({ error: 'Introudu confirmare parolei.' });
		if (this.state.password !== this.state.repeatPassword) return this.setState({ error: 'Cele două parole nu se potrivesc.' });
		if (this.state.termsAccepted === false) return this.setState({error: "Nu ai acceptat termenii și condițiile."})

		signUp(this.state, (err) => {
			if (err) return this.setState({ error: err });

			// on successfull signup
			this.setState({ error: null });
			window.$.magnificPopup.close(); // close auth modal
		});
	}

	/* Render */
	render() {
		return (
			<div className="popup-tab-content" id="register">
				{/*-- Notification error --*/}
				{this.state.error && (
					<div className="notification error auth-notification">
						<p> {this.state.error} </p>
					</div>
				)}

				{/*<!-- Welcome Text -->*/}
				<div className="welcome-text">
					<h3>Creare cont!</h3>
				</div>

				{/*<!-- Account Type -->*/}
				<div className="account-type">
					<div>
						<input type="radio" id="freelancer-radio" className="account-type-radio" checked={this.state.freelancer} onChange={() => this.setFreelancer(true)} />
						<label htmlFor="freelancer-radio" className="ripple-effect-dark"><i className="icon-material-outline-account-circle"></i> Freelancer</label>
					</div>

					<div>
						<input type="radio" id="employer-radio" className="account-type-radio" checked={!this.state.freelancer} onChange={() => this.setFreelancer(false)} />
						<label htmlFor="employer-radio" className="ripple-effect-dark"><i className="icon-material-outline-business-center"></i> Client</label>
					</div>
				</div>

				{/*<!-- Form -->*/}
				<form method="post" id="register-account-form" onSubmit={this.submitForm}>
					{
						this.state.freelancer === true ?
						<Fragment>
							<div className="input-with-icon-left">
								<i className="icon-feather-user"></i>
								<input type="text" className="input-text with-border" placeholder="Prenume" value={this.state.firstName} onChange={this.controlFirstName} />
							</div> 
							<div className="input-with-icon-left">
								<i className="icon-feather-user"></i>
								<input type="text" className="input-text with-border" placeholder="Nume" value={this.state.lastName} onChange={this.controlLastName} />
							</div> 
						</Fragment>
						
						:
						<div className="input-with-icon-left">
							<i className="icon-feather-user"></i>
							<input type="text" className="input-text with-border" placeholder="Nume Client" value={this.state.clientName} onChange={this.controlClientName} />
						</div> 

					}
					

					<div className="input-with-icon-left">
						<i className="icon-material-baseline-mail-outline"></i>
						<input type="email" className="input-text with-border" placeholder="Email Address" value={this.state.email} onChange={this.controlEmail} />
					</div>

					<div className="input-with-icon-left" title="Should be at least 8 characters long" data-tippy-placement="bottom">
						<i className="icon-material-outline-lock"></i>
						<input type="password" className="input-text with-border" placeholder="Password" value={this.state.password} onChange={this.controlPassword} />
					</div>

					<div className="input-with-icon-left">
						<i className="icon-material-outline-lock"></i>
						<input type="password" className="input-text with-border" placeholder="Repeat Password" value={this.state.repeatPassword} onChange={this.controlRepeatPassword} />
					</div>

					<div class="checkbox">
						<input type="checkbox" id="chekcbox1" onClick={this.handleTermsCheck}/>
						<label for="chekcbox1"><span class="checkbox-icon"></span> Sunt de acord cu <NavLink to="/terms-and-conditions">termenii și condițiile.</NavLink></label>
					</div>
				</form>

				{/*<!-- Button -->*/}
				<button className="margin-top-10 button full-width button-sliding-icon ripple-effect" type="submit" form="register-account-form">Înregistrare <i className="icon-material-outline-arrow-right-alt"></i></button>
			</div>

		);
	}
}

export default SignUp;