import React from 'react';

function HowItWorks() {
	return (
		<div className="section padding-top-65 padding-bottom-65">
			<div className="container">
				<div className="row">

					<div className="col-xl-12">
						{/*<!-- Section Headline -->*/}
						<div className="section-headline centered margin-top-0 margin-bottom-5">
							<h3>Cum funcționează ?</h3>
						</div>
					</div>

					<div className="col-xl-4 col-md-4">
						{/*<!-- Icon Box -->*/}
						<div className="icon-box with-line">
							{/*<!-- Icon -->*/}
							<div className="icon-box-circle">
								<div className="icon-box-circle-inner">
									<i className="icon-line-awesome-lock"></i>
									<div className="icon-box-check"><i className="icon-material-outline-check"></i></div>
								</div>
							</div>
							<h3>Creează un cont</h3>
							<p>Creeaza un cont de client pentru a găsi profesioniști pentru proiectele tale.</p>
						</div>
					</div>

					<div className="col-xl-4 col-md-4">
						{/*<!-- Icon Box -->*/}
						<div className="icon-box with-line">
							{/*<!-- Icon -->*/}
							<div className="icon-box-circle">
								<div className="icon-box-circle-inner">
									<i className="icon-line-awesome-legal"></i>
									<div className="icon-box-check"><i className="icon-material-outline-check"></i></div>
								</div>
							</div>
							<h3>Postează un proiect</h3>
							<p>Postează un proiect în rețeaua noastră și vei beneficia de cei mai buni experți în domeniu.</p>
						</div>
					</div>

					<div className="col-xl-4 col-md-4">
						{/*<!-- Icon Box -->*/}
						<div className="icon-box">
							{/*<!-- Icon -->*/}
							<div className="icon-box-circle">
								<div className="icon-box-circle-inner">
									<i className=" icon-line-awesome-trophy"></i>
									<div className="icon-box-check"><i className="icon-material-outline-check"></i></div>
								</div>
							</div>
							<h3>Alege un expert</h3>
							<p>Alege unul dintre experții din rețeaua noastră.</p>
						</div>
					</div>

				</div>
			</div>
		</div>
	);
}

export default HowItWorks;