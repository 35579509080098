import React, {useEffect} from "react"
import { rippleEffect } from '../../../runJQuery';
import { Link, NavLink } from "react-router-dom";

const AppliedProjectItem = (props) => {

    useEffect(() => {
        rippleEffect();
    }, []);

    const getStatusClassForProject = () => {

        if(props.status === 'ACTIVE' 
            && props.chosenApplicant != null 
            && props.chosenApplicant.userId === props.applicant.userId) {
            return `dashboard-status-button green`
        }

        if(props.status === 'ACTIVE' 
            && props.chosenApplicant != null 
            && props.chosenApplicant.userId != props.applicant.userId) {
            return `dashboard-status-button red`
        }

        if(props.status === 'IN_AUCTION') {
            return `dashboard-status-button yellow`
        }

        if(props.status === 'FINISHED') {
            return `dashboard-status-button`
        }
    };

    const getStatusForProject = () => {
        if (props.status === "IN_AUCTION") {
            return "Selecție candidați";
        }
        if (props.status === "ACTIVE" 
            && props.chosenApplicant != null 
            && props.chosenApplicant.userId != props.applicant.userId) {
            return "Nereușită";
        }
        if (props.status === "ACTIVE" 
            && props.chosenApplicant != null 
            && props.chosenApplicant.userId === props.applicant.userId) {
            return "Câștigător";
        }
        return props.status;
    };

    const currentProjectDate = new Date(props.createdAt).toLocaleDateString("ro-GB", 
    {
        day: 'numeric', // numeric, 2-digit
        year: 'numeric', // numeric, 2-digit
        month: 'long', // numeric, 2-digit, long, short, narrow
    });

    const applicationDate = new Date(props.applicant.dateOfAppliance).toLocaleDateString("ro-RO", 
    {
        day: 'numeric', // numeric, 2-digit
        year: 'numeric', // numeric, 2-digit
        month: 'long', // numeric, 2-digit, long, short, narrow
    });

    const getDurationDetails = (applicant) => {
        if (applicant.paymentTypeRequested === 'PROJECT' && applicant.durationRequestedType === 'MONTHS') {
            return `${applicant.durationRequested} ${applicant.durationRequested > 1 ? "luni" : "lună"}`;
        }
        if (applicant.paymentTypeRequested === 'PROJECT' && applicant.durationRequestedType === 'DAYS') {
            return `${applicant.durationRequested} ${applicant.durationRequested > 1 ? "zile" : "zi"}`;
        }
        return "neprecizat";
    }

    
    const getPaymentType = (applicant) => {
        if (applicant.paymentTypeRequested === "PROJECT")
            return "Preț fix pe proiect";
        if (applicant.paymentTypeRequested === "HOUR")
            return "Plată pe oră";
        if (applicant.paymentTypeRequested === "MONTH")
            return "Plată pe lună";
        if (applicant.paymentTypeRequested === "FIXED")
            return "Preț fix pe proiect";
    }

    return (
            <React.Fragment>
                
                    {/*~~ Job Listing ~~*/}
            <div className="job-listing">

                {/*~~ Job Listing Details ~~*/}
                <div className="job-listing-details">


                    <div className="job-listing-description">
                        <Link to={`/project/${props.id}`}>
                            <h3 className="job-listing-title"><a href="#">{props.title}</a> </h3></Link>
                        <span>Stadiu aplicație:  <span className={getStatusClassForProject()}>{getStatusForProject()}</span></span>
                        {/*~~ Job Listing Footer ~~*/}
                        <div className="job-listing-footer">
                            <ul>
                                <li><i className="icon-material-outline-date-range"></i> {`Aplicație înregistrată pe ${applicationDate}`}</li><br />
                                <li><i className="icon-material-outline-date-range"></i> {`Postat pe ${currentProjectDate}`}</li>
                            </ul>
                            {props.chosenApplicant != null && props.chosenApplicant.userId === props.applicant.userId && props.contactPerson != null &&
                                
                                <ul>
                                    <li><i className="icon-material-outline-business"></i> {`Nume client: ${props.contactPerson.firstName}`}</li><br />
                                    <li><i className="icon-feather-mail"></i> {`Email contact: ${props.contactPerson.email}`}</li><br />
                                    <li><i className="icon-feather-phone"></i> {`Telefon ${props.contactPerson.phone}`}</li>
                                </ul>
                            }
                        </div>
                        <ul className="dashboard-task-info bid-info">
                            <li><strong>{`${props.applicant.paymentRequested} ${props.applicant.paymentRequestedCurrency}`}</strong><span>{getPaymentType(props.applicant)}</span></li>
                            {props.applicant.paymentTypeRequested === 'PROJECT' &&
                                <li><strong>{getDurationDetails(props.applicant)}</strong><span>Timp de livrare</span></li>
                            }
                        </ul>
                    </div>
                </div>
            </div>
            </React.Fragment>
        );
};

export default AppliedProjectItem;