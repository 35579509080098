import React from 'react';
import { connect } from 'react-redux';
import { Snackbar } from '@material/react-snackbar';

function Notifier(props) {
	return (
		<>
			{props.notifications.map((el) =>
				<Snackbar
					key={el.id}
					message={el.message}
					actionText='x'
					className={el.type}
					timeoutMs={4000}
					leading
				/>
			)}
		</>
	);
}

const mapStateToProps = (state) => ({
	notifications: state.notifications
});

export default connect(mapStateToProps)(Notifier);