import React, { Fragment } from 'react'; 
import { useState } from 'react'; 
import { useSelector } from 'react-redux';
import { removeProjectApplication } from '../../actions';
import Dropdown from '../util/Dropdown';
import axios from 'axios';
import { host } from '../../constants/constants';
import { addNotification } from '../../actions';

const ApplyModal = () => {

    const [state, setState] = useState({
        tariff : '',
        duration : 0,
        durationType : 'UNSPECIFIED',
        xpLevel : '',
        durationType : ''
    });

    const userDetails = useSelector(state => state.user.profile);
    const project = useSelector(state => state.projectApplication);

    const handleApplication = () => {
        removeProjectApplication();
        window.$.magnificPopup.close(); // close apply modal
    }

    const controlTariff = (event) => {
        const tariff = event.target.value;
		setState(prev => ({ ...prev, tariff}));
	}

    const controlDuration = (event) => {
        const duration = event.target.value;
		setState(prev => ({ ...prev, duration}));
	}

	const controlXpLevel = (xpLevel) => {
		setState(prev => ({ ...prev, xpLevel}));
	}

    const controlDurationType = (durationType) => {
		setState(prev => ({ ...prev, durationType}));
	}

    const getTariffPlaceholder = () => {
        if (project.paymentType === "PROJECT" || project.paymentType === "FIXED")
            return `Tarif în ${project.currency}`;
        if (project.paymentType === "HOUR")
            return `Tarif pe oră în ${project.currency}`;
        if (project.paymentType === "MONTH")
            return `Tarif pe lună în ${project.currency}`;
    }

    const applyToProject = (e) => {
        e.preventDefault();
		// validate form data
		//if (!validateData()) return;

		const data = {
            userId : userDetails.id,
            userName : userDetails.fullName,
            firstName : userDetails.firstName,
            lastName : userDetails.lastName,
			experienceLevel: state.xpLevel.toUpperCase(),
            email: userDetails.email,
            phone: userDetails.phone,
			duration: project.duration,
			durationType: project.paymentType === "PROJECT" || project.paymentType === "FIXED" ?  project.duration : "UNSPECIFIED" ,
            paymentRequested: state.tariff,
			paymentRequestedCurrency: project.currency,
			paymentTypeRequested: project.paymentType.toUpperCase(),
            durationRequested: state.duration , 
            durationRequestedType: project.paymentType === "PROJECT" || project.paymentType === "FIXED" ?  state.durationType.toUpperCase() : "UNSPECIFIED"
		};

		// add duration
		const months = parseInt(state.months);
		const days = parseInt(state.days);

		if (months > 0) {
			data.duration = months;
			data.durationType = 'MONTHS';
		} else if (days > 0) {
			data.duration = days;
			data.durationType = 'DAYS';
		}
        console.log(data);

		axios({
			method: 'post',
			url: `${host}/project/${project.id}/applicant`,
			withCredentials: true,
			data
		}).then(res => {
			addNotification('success', `Ai aplicat cu succes la ${project.title}!`);
			//this.props.history.push('/dashboard');
		}).catch(err => {
            const message = err.response.data.message;
            addNotification('error', message);
		});
        handleApplication();
	}


	return (
        <div id="small-dialog" className="zoom-anim-dialog mfp-hide dialog-with-tabs" >

            {/* Tabs */}
            <div className="sign-in-form">

                <ul className="popup-tabs-nav">
                    <li><a href="#tab">Apply Now</a></li>
                </ul>

                <div className="popup-tabs-container">

                    {/* Tab */}
                    <div className="popup-tab-content" id="tab">

                        {/* Welcome text */}
                        <div className="welcome-text">
                            <h3>Apply to {project.title}</h3>
                        </div>

                        {/* Form */}
                        <form method="post" id="apply-now-form">

                            <div className="section-headline margin-top-10 margin-bottom-10">
                                <h5>Tariff</h5>
                            </div>
                            <div className="input-with-icon-left">
                                <i className="icon-material-outline-monetization-on"></i>
                                <input type="text" className="input-text with-border" name="name" id="name" placeholder={getTariffPlaceholder()} onChange={controlTariff} required />
                            </div>

                            <div className="section-headline margin-top-10 margin-bottom-10">
                                <h5>Experience</h5>
                            </div>
                            <Dropdown
                                className="with-border"
                                onChange={controlXpLevel}
                                options={[
                                    { text: 'Junior', value: 'JUNIOR' },
                                    { text: 'Middle', value: 'MIDDLE' },
                                    { text: 'Senior', value: 'SENIOR' }
                                ]}/>  

                            {/* Duration */}

                            { (project.paymentType === "PROJECT" || project.paymentType === "FIXED") &&  
                                <Fragment>    
                                    <div class="section-headline margin-top-10 margin-bottom-10">
                                        <h5>Duration</h5>
                                    </div>
                                    <div className="row">
                                        <div class="col-xl-6">
                                            <input type="text" className="with-border" name="name" id="name" placeholder={`Duration in ${state.durationType.toLowerCase()}`} onChange={controlDuration} value={state.duration} required />
                                        </div>
                                        <div class="col-xl-4">
                                            <Dropdown
                                                className="with-border"
                                                onChange={controlDurationType}
                                                options={[
                                                    { text: 'Days', value: 'DAYS' },
                                                    { text: 'Months', value: 'Months' }
                                            ]}/>
                                        </div>
                                    </div>
                                </Fragment>
                            }
                        </form>

                       
                        {/* Button */}
                        <button className="button margin-top-35 full-width button-sliding-icon ripple-effect" type="submit" form="apply-now-form" onClick={applyToProject}>Apply Now <i className="icon-material-outline-arrow-right-alt"></i></button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ApplyModal;