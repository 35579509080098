import React from 'react';
import { useEffect, useState } from 'react';
import { rippleEffect } from '../../runJQuery';
import useClickToOpen from '../../hooks/useClickToOpen';

function Dropdown(props) {
	let ref = React.createRef();
	let [open, toggleState] = useClickToOpen(ref);
	let [selectedOption, setOption] = useState(null);

	const selectOption = (opt) => {
		setOption(opt.text);
		props.onChange(opt.value);
	}

	useEffect(() => {
		// select first option
		selectOption(props.options[0]);

		// run necessary jquery
		rippleEffect();
	}, []);

	/*-- Helpers --*/
	const classFromState = () => {
		if (!open) return 'btn-group bootstrap-select show-tick default';
		return 'btn-group bootstrap-select show-tick default open';
	}

	/*-- Render --*/
	const renderOptions = () => {
		return props.options.map(option => (
			<li key={option.value} onClick={() => selectOption(option)}>
				<a><span className="text">{option.text}</span></a>
			</li>
		));
	}

	return (
		<div className={`${classFromState()} ${props.className}`} onClick={toggleState} ref={ref}>
			<button type="button" className="btn dropdown-toggle btn-default bs-placeholder" title="All Categories" >
				<span className="filter-option pull-left">
					{selectedOption}
				</span>&nbsp;
					<span className="bs-caret"><span className="caret"></span></span>
			</button>
			<div className="dropdown-menu open" style={{ maxHeight: '195px', overflow: 'hidden' }}>
				<ul className="dropdown-menu inner" style={{ maxHeight: '175px', overflowY: 'auto' }}>
					{renderOptions()}
				</ul>
			</div>
		</div>
	);
}

export default Dropdown;