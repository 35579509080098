import { useState, useEffect } from 'react';

function useClickToOpen(ref) {
	if (!ref) throw Error('A ref must be provided');
	let [active, setActive] = useState(false);

	useEffect(() => {
		function handleClickOutside(e) {
			if (!ref.current.contains(e.target)) setActive(false);
		}

		if (active) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside)
		}
		
		// cleanup when unmounting
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [active, ref]);

	function toggleState(e) {
		if (active && e.target.getAttribute('data-noclose')) return;
		setActive(!active);
	}
	
	return [active, toggleState];
}

export default useClickToOpen;