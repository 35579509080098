import React,{ Fragment } from "react"
import { Link } from "react-router-dom";

const CookiePolicy = () => {
    return (
        <Fragment>
            {/* Content */}
            <div id="titlebar" className="gradient">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Politică privind cookie-urile și alte tehnologii similare</h2>
                        
                            {/* Breadcrumbs */}
                            <nav id="breadcrumbs" className="dark">
                                <ul>
                                    <li><Link to="/">Pagină principală</Link></li>
                                    <li>Politică utilizare cookie</li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            {/* Post Content */}
            <div className="container">
                <div className="row">

                    {/* Inner Content */}
                    
                        {/* Blog Post */}
                        <div className="blog-post single-post">

                            {/* Blog Post Content */}
                            <div className="blog-post-content">
                                <p>Lance Hub Services S.R.L. poate seta sau citi module cookie și identificatori de dispozitive atunci când utilizați Platforma lancehub.ro sau vizitați un site web, o aplicație sau un serviciu care utilizează Serviciile noastre.</p>
                               
                                <h3 className="margin-bottom-10 margin-top-50">1. Câteva informații utile cu privire la modulele cookie și identificatorii de dispozitive</h3>

                                <h4 className="margin-bottom-10 margin-top-50">1.1. Ce este un "cookie"?</h4>

                                <p>Un "Internet Cookie" (termen cunoscut și ca "browser cookie" sau "HTTP cookie" sau pur și simplu "cookie" ) este un fișier de mici dimensiuni, format din litere și numere, care va fi stocat pe computerul, terminalul mobil sau alte echipamente ale unui utilizator de pe care se accesează Internetul.</p>
                            
                                <p>Cookie-ul este instalat prin solicitarea emisă de către un web-server unui browser (ex: Edge, Firefox, Chrome, Safari) și este complet "pasiv" (nu conține programe software, viruși sau spyware și nu poate accesa informațiile de pe hard-drive-ul utilizatorului). Cookie-ul stochează anumite informații (spre exemplu, limba preferată sau setări ale paginii web), pe care browserul dumneavoastră ni le poate transfera înapoi atunci când accesați din nou pagina web (în funcție de durata de viață a Cookie-ului).</p>
                            
                                <p>Cookie-urile în sine nu solicită informații cu caracter personal pentru a putea fi utilizate și, în cele mai multe cazuri, nu identifică personal utilizatorii de internet.</p>
                            
                                <h4 className="margin-bottom-10 margin-top-50">1.2. Care sunt tipurile de cookie?</h4>

                                <p>Există mai multe feluri de cookie-uri. Clasificarea lor se face în funcție de durata de viața, rolul și sursa acestora, astfel:</p>
                            
                                <p>a. Tipuri de cookie-uri în funcție de durata de viață</p>

                                <p>Cookie-uri de sesiune -  sunt stocate temporar în dosarul de cookie-uri al browserului web pentru ca acesta să le memoreze până când utilizatorul iese de pe website-ul respectiv sau închide fereastra browserului (ex: în momentul logării/delogării pe un cont de webmail sau pe rețele de socializare).</p>

                                <p>Cookie-uri persistente - sunt stocate pe hard-drive-ul unui computer sau echipament (și în general depind de durata de viață prestabilită pentru cookie). Cookie-urile persistente le includ și pe cele plasate de un alt website decât cel pe care îl vizitează utilizatorul la momentul respectiv - cunoscute sub numele de 'third party cookies' (cookie-uri plasate de terți) - care pot fi folosite în mod anonim pentru a memora interesele unui utilizator, astfel încât să fie livrată publicitate cât mai relevantă pentru utilizator.</p>

                                <p>b. Tipuri de cookie-uri în funcție de rolul acestora</p>

                                <p>Cookie-uri strict necesare - tipuri de cookie-uri sunt necesare ca paginile de internet să funcționeze în mod corespunzător. Modulele cookie strict necesare vă permit să navigați prin site și să beneficiați de caracteristicile sale. Fără aceste module cookie, nu vom putea oferi anumite caracteristici, cum ar fi redirecționarea automată la cel mai puțin ocupat server sau reținerea listei dvs. de dorințe.</p>

                                <p>Cookie-uri funcționale - cookie funcționale înregistrează informații legate de alegerile pe care utilizatorii le fac și ne permit, de asemenea, operatorilor de site-uri să personalizeze site-ul conform cerințelor utilizatorului. De exemplu, modulele cookie pot fi utilizate pentru a salva preferințele legate de categorie/segment.</p>

                                <p>Cookie-uri de performanță și analiză - tipuri de cookie-uri oferă posibilitatea operatorilor site-urilor de internet să monitorizeze vizitele și sursele de trafic, modul în care utilizatorii interacționează cu pagina de internet sau anumite secțiuni din pagina de internet.</p>

                                <p>Informațiile furnizate de modulele cookie de analiză ajută operatorii să înțeleagă cum folosesc vizitatorii site-urile și apoi să folosească această informație pentru a îmbunătăți modul în care este prezentat conținutul oferit utilizatorilor.</p>

                                <p>Cookie-uri de targetare și publicitate - cookie-uri pot oferi posibilitatea de a monitoriza activitățile online ale utilizatorilor și de a stabili profiluri de utilizatori, care pot fi apoi folosite în scopuri de marketing. Spre exemplu, pe baza cookie-urilor pot fi identificate produsele și serviciile agreate de către un utilizator, aceste informații servind ulterior la transmiterea de mesaje publicitare adecvate către respectivul utilizator.</p>

                                <p>c. Cookie-uri first party vs cookie-uri third party</p>

                                <p>Fiecare cookie are un „responsabil” (i.e. site-ul web/domeniul Internet) care plasează respectivul cookie.</p>

                                <p>Cookie-urile first party (prima parte) sunt plasate de către domeniul Internet /site-ul web accesat de către utilizator (a cărui adresă apare în bara de adrese a browserului). Spre exemplu, dacă utilizatorul vizitează www.lancehub.ro, iar domeniul cookie-ului plasat pe calculatorul său este www.lancehub.ro, atunci este vorba despre un cookie first party.</p>

                                <p>Un cookie third party (terță parte) este plasat de către un alt domeniu Internet/site web decât cel accesat de către utilizator; acest lucru înseamnă că site-ul web accesat conține și informații provenind de la un site web terț - spre exemplu, un banner publicitar care apare pe site-ul accesat. Astfel, dacă utilizatorul vizitează www.lancehub.ro, dar cookie-ul plasat pe calculatorul său are ca domeniu doublecklick.net atunci este vorba despre un cookie third party.</p>

                                <p>d. Conținut încorporat (butoane, aplicații și widgeturi de socializare)</p>

                                <p>Paginile web permit implementarea unor butoane sau widgeturi de socializare de la un terț, care permit interacțiunea utilizatorilor cu site-urile ale căror butoane sau widgeturi de socializare sunt implementate. Interacțiunea utilizatorului cu butoanele sau widgeturile de socializare de la un terț permite terțului să colecteze unele informații despre acel utilizator, inclusiv adresa IP, informații din antetul paginii și informații despre browser.</p>

                                <p>e. Cum funcționează cookie-urile de targetare și publicitate?</p>

                                <p>În general, datele despre activitatea de navigare pe Internet sunt colectate și analizate anonim. Dacă în urma acestei analize reiese un interes specific, un cookie (mic fișier text utilizat de majoritatea site-urilor pentru a stoca anumite părți de informație folositoare pentru a face experiența de navigare mai buna) – este plasat în calculatorul Utilizatorului și acest cookie determină ce reclamă va primi utilizatorul, care se numește publicitate bazată pe interes.</p>
                            
                                <p>Publicitatea bazată pe interes este un mod de livra reclame pe website-urile pe care le vizitați și de a le face mai relevante pentru interesele dumneavoastră. Interesele comune sunt grupate în funcție de activitatea de navigare desfășurată anterior iar utilizatorilor li se servește doar publicitatea care se potrivește cu interesele lor. În acest mod, publicitatea este relevantă și cât se poate de utilă.</p>
                            
                                <h3 className="margin-bottom-10 margin-top-50">2. Ce tipuri de cookie-uri și identificatori de dispozitive folosim?</h3>

                                <p>Mai jos este lista detaliată a cookie-urilor și identificatorilor de dispozitive utilizate.</p>

                                <p>Aveți posibilitatea de a vă retrage consimțământul dat pentru utilizarea Cookie-urilor (cu excepția cookie-urilor necesare pentru a putea utiliza Platforma lancehub.ro) la orice moment, prin schimbarea opțiunilor din setările de cookie-uri corespunzătoare disponibile.</p>

                                <p>Desigur, puteți seta browserul dvs. să blocheze și cookie-urile necesare, dar Site-ul ar putea să nu funcționeze în mod corespunzător. De asemenea, dezactivarea și a altor tipuri de cookie-uri (decât cele necesare) ar putea să afecteze funcționarea sau experiența dvs. în ceea ce privește utilizarea Site-ului.</p>
                            
                                <h4 className="margin-bottom-10 margin-top-50">2.1 Cookie-uri necesare</h4>
                            
                                <p>Modulele cookie strict necesare vă permit să navigați pe Platforma lancehub.ro și să beneficiați de caracteristicile sale. Fără aceste module cookie, nu vom putea oferi anumite caracteristici, cum ar fi redirecționarea automată la cel mai puțin ocupat server sau reținerea listei dvs. de dorințe.</p>
                            
                                <h4 className="margin-bottom-10 margin-top-50">2.2 Cookie-uri funcționale</h4>

                                <p>Modulele cookie funcționale înregistrează informații legate de alegerile pe care le-ați făcut și ne permit, de asemenea, să adaptăm Site-ul web pentru a se potrivi nevoilor și solicitărilor dvs.</p>

                                <h4 className="margin-bottom-10 margin-top-50">2.3. Cookie-uri de performanță și analiză</h4>

                                <p>Aceste tipuri de cookie ne ajută să înțelegem cum folosesc utilizatorii Platforma lancehub.ro. Folosim aceste date doar cu scopul îmbunătățirii Serviciilor și a performanței Platformei lancehub.ro.</p>
                            
                                <p>Servicii de analiză prin Google Analytics</p>
                            
                                <p>lancehub.ro folosește Google Analytics, un serviciu de analiză web a Google Inc., cu sediul în 1600 Amphitheatre Parkway, Mountain View, CA 94043, United States (“Google”).</p>

                                <p>Pe baza consimțământului dumneavoastră, Google va analiza în numele nostru modul în care dumneavoastră folosiți website-ul nostru. În acest scop, folosim, printre altele, Cookie-urile detaliate în tabelul de mai sus. Informațiile colectate de Google în legătură cu folosirea lancehub.ro de către dumneavoastră (spre exemplu, URL-ul de trimitere, paginile noastre web pe care le vizitați, tipul browserului dumneavoastră, setările de limbă, sistemul dumneavoastră de operare, rezoluția ecranului) vor fi trimise unui server Google din Statele Unite, unde vor fi stocate și analizate; rezultatele corespunzătoare ne vor fi ulterior făcute disponibile într-o formă anonimizată. În acest proces, datele referitoare la datele de trafic nu vor fi atribuite adresei IP complete pe care o folosiți. Am activat pe website-ul nostru funcția de anonimizare a IP-urilor oferită de Google, astfel încât ultimele 8 cifre (tip IPv4) sau ultimii 80 de biți (tip IPv6) ai adresei IP pe care o folosiți sunt șterse.</p>

                                <p>Mai mult, Google este certificat în conformitate cu standardele EU-US Privacy Shield, care asigură un nivel adecvat de protecție a datelor în ceea ce privește prelucrarea datelor de către Google în SUA.</p>

                                <p>Pentru mai multe informații despre Google Analytics vă rugăm să consultați Google Analytics Terms of Service, Google Analytics security and privacy principles, și Google Privacy Policy.</p>

                                <p>Puteți să vă retrageți consimțământul privind analiza web în orice moment, fie prin descărcarea și instalarea unui Browser Plugin oferit de Google, fie prin gestionarea consimțământului dumneavoastră prin setările de mai sus.</p>
                            
                                <h4 className="margin-bottom-10 margin-top-50">2.4. Cookie-uri de targetare și publicitate utilizate de lancehub.ro</h4>
                            
                                <p>Cookie-urile și tehnologiile de targetare (cum ar fi web beacons - fișiere imagine de mici dimensiuni cunoscute și sub numele de clear gifs, pixel tags sau single-pixel gifs, care permit, spre exemplu, contorizarea utilizatorilor care au vizitat paginile sau care au deschis mesajele trimise prin e-mail) cu scop publicitar ne permit să colectăm date de la persoanele care utilizează lancehub.ro.</p>
                            
                                <p>Aceste date sunt apoi agregate pentru a crea segmente de utilizatori clasificați pe diverse categorii, fără însă a avea informații concrete privind identitatea utilizatorilor incluși în astfel de segmente. Aceste date ne permit apoi să furnizam utilizatorilor care corespund acestor clasificări publicitate bazată pe interes sau publicitate contextuală.</p>
                            
                                <h4 className="margin-bottom-10 margin-top-50">2.4.2. Plug-inuri de socializare</h4>

                                <p>lancehub.ro poate utiliza sau implementa plugin-uri rețelelor de socializare ale unor terți. În general, interacțiunea dvs. cu un plugin oferă posibilitatea terțului să colecteze unele informații despre dvs., inclusiv adresa IP, informații din antetul paginii și informații despre browser.</p>
                            
                                <p>lancehub.ro poate implementa următoarele butoane de socializare:</p>
                            
                                <p> • Facebook (<a href="https://www.facebook.com/privacy">https://www.facebook.com/privacy</a>) <br/>
                                    • LinkedIn (<a href="https://www.linkedin.com/legal/privacy-policy">https://www.linkedin.com/legal/privacy-policy</a>)<br/>
                                    • Google (<a href="https://policies.google.com/privacy">(https://policies.google.com/privacy)</a>
                                </p>

                                
                            </div>
                        </div>
                </div>  
            </div>
       

            {/* Spacer */}
            <div className="padding-top-40"></div>
            {/* Spacer */}
        </Fragment>
    );
}

export default CookiePolicy;