import React, { Fragment, useEffect, useState }  from "react";
import { usePagination, DOTS } from "../../hooks/usePagination";
import { rippleEffect } from "../../runJQuery";

const Pagination = (props) => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
      } = props;

      useEffect(() => rippleEffect(), []);

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
      }
    
    const onNext = () => {
        if(currentPage === lastPage) return;
        onPageChange(currentPage + 1);
    };
    
    const onPrevious = () => {
        if(currentPage === 1) return;
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];

    return (
        <Fragment>
            <div className="clearfix"></div>
            <div className="row">
                <div className="col-md-12">
                    {/* Pagination */}
                    <div className="pagination-container margin-top-60 margin-bottom-60">
                        <nav className="pagination">
                            <ul>
                                <li className="pagination-arrow"><button className="ripple-effect" onClick={onPrevious}><i className="icon-material-outline-keyboard-arrow-left"></i></button></li>   
                                {   paginationRange.map(pageNumber => {
                                    if (pageNumber === DOTS) {
                                        return <li><button className="none">...</button></li>
                                    }

                                    if (pageNumber === currentPage) {
                                        return (<li><button className="current-page ripple-effect" onClick={() => onPageChange(pageNumber)}>{pageNumber} </button></li>);
                                    }

                                    return (<li><button className="ripple-effect" disabled={currentPage === lastPage} onClick={() => onPageChange(pageNumber)}>{pageNumber} </button></li>);
                                })}
                                <li className="pagination-arrow"><button className="ripple-effect" type="button" onClick={onNext}><i className="icon-material-outline-keyboard-arrow-right"></i></button></li>   
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Pagination;