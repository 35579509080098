import { Link } from 'react-router-dom';
import axios from "axios";
import React, { useEffect, useState } from "react";
import { host } from '../../constants/constants';
import { addNotification } from '../../actions';
import categories from "../../constants/categories.json";

function FeaturedProjects() {

	const [projects, setProjects] = useState([]);

	const simpleSearch = () => {
        axios({
			method: 'GET',
			url: `${host}/search?pageNumber=0&itemsOnPage=5&type=project`
		}).then(res => {
            setProjects(res.data.searchResults.map(searchResult => searchResult.project));
		}).catch(err => {
			addNotification('error', 'Nu s-au putut încărca proiectele. Încercați în cateva minute.');
		});
    };

	useEffect(() => {
		simpleSearch();
	},[])

	function renderProject(prj) {
		const getPaymentType = () => {
			if (prj.paymentType === "PROJECT")
				return "Pe proiect";
			if (prj.paymentType === "HOUR")
				return "Pe oră";
			if (prj.paymentType === "MONTH")
				return "Pe lună";
			if (prj.paymentType === "FIXED")
				return "Preț fix";
		}

		const getProjectLocation = () => {
			if (prj.workLocation === "REMOTE" ) {
				return <li><i className="icon-material-outline-location-off"></i> Remote </li>;
			}
	
			if (prj.workLocation === "UNSPECIFIED" ) {
				return <li><i className="icon-material-outline-location-off"></i> Neprecizat </li>;
			}
	
			if (prj.workLocation === "HQ" || prj.workLocation === "ON_SITE") {
				return <li><i className="icon-material-outline-location-on"></i> {prj.city.name} </li>;
			}
		};

		const getCurrencySymbol = () => {
			switch(prj.currency) {
				case "USD" : return "$";
				case "EUR" : return "EUR";
				default : return "RON";
			}
		}

		const getCategoryNameById = (catId) => {
			if(catId && catId.id != "-1")
				return categories.find(category => category.id === parseInt(catId.id)).name;
			else
				return "nicio categorie";
		}
	
		const getProjectBudget = () => {
			return `${prj.budget} ${getCurrencySymbol()}`;
		}

		return (
			<a key={prj.projectUuid} class="task-listing">

						{/* Job Listing Details */}
						<div class="task-listing-details">
							{prj.promoted == true && <span class="bookmark-icon bookmarked"></span>}
							{/* Details */}
							<div class="task-listing-description">
								<h3 class="task-listing-title">{prj.title}</h3>
								<ul class="task-icons">
									<li>{ getProjectLocation() }</li>
									<li><i className="icon-material-outline-access-time"></i> { new Date(prj.createdAt).toLocaleDateString("ro-RO", 
                                                    {
                                                        day: 'numeric', // numeric, 2-digit
                                                        year: 'numeric', // numeric, 2-digit
                                                        month: 'long', // numeric, 2-digit, long, short, narrow
                                                    })}</li>
								</ul>
								{(prj.categories!= null && prj.categories && prj.categories[0]) && <ul class="task-icons">
									<li><i className="icon-material-outline-local-offer"></i> {getCategoryNameById(prj.categories[0])}</li>
									{prj.categories[1] && <li><i className="icon-material-outline-local-offer"></i> {getCategoryNameById(prj.categories[1])}</li>}
                        			</ul>}
								
								<div class="task-tags margin-top-15">
									{prj.skills && prj.skills.map(skill => 
										<span>{skill}</span>
									)}

								</div>
							</div>
						</div>

						<div class="task-listing-bid">
							<div class="task-listing-bid-inner">
								<div class="task-offers">
                            		<strong>{getProjectBudget()}</strong>
                            		<span>{getPaymentType()}</span>
								</div>
								<Link to={`/project/${prj.id}`}><span class="button button-sliding-icon ripple-effect"> Aplică <i class="icon-material-outline-arrow-right-alt"></i></span></Link>
							</div>
						</div>
					</a>
		);
	}

	return (
		<div className="section gray padding-top-65 padding-bottom-75">
			<div className="container">
				<div className="row">
					<div className="col-xl-12">

						{/*<!-- Section Headline -->*/}
						<div className="section-headline margin-top-0 margin-bottom-35">
							<h3>Proiecte recent adăugate</h3>
							<Link to="/searchProject"><a className="headline-link">Accesează toate proiectele</a></Link>
						</div>

						{/*<!-- Jobs Container -->*/}
						<div class="tasks-list-container compact-list margin-top-35">


						{/* <div className="listings-container compact-list-layout margin-top-35"> */}
							{ projects.length > 0 && projects.map(proj => renderProject(proj))}
							{ projects.length === 0 &&
                                <div class="notification notice margin-top-30" style={{textAlign: "center"}}>
                                    <p><strong>Nu s-au găsit rezultate</strong></p>
                                </div>
                            }
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default FeaturedProjects;