
import React,{ Fragment } from "react"
import { Link } from "react-router-dom";

const TermsAndConditions = () => {
    return (
        <Fragment>
            {/* Content */}
            <div id="titlebar" className="gradient">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Termeni și condiții</h2>
                        
                            {/* Breadcrumbs */}
                            <nav id="breadcrumbs" className="dark">
                                <ul>
                                    <li><Link to="/">Pagină principală</Link></li>
                                    <li>Termeni și condiții</li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            {/* Post Content */}
            <div className="container">
                <div className="row">

                    {/* Inner Content */}
                    
                        {/* Blog Post */}
                        <div className="blog-post single-post">

                            {/* Blog Post Content */}
                            <div className="blog-post-content">
                                <h3 className="margin-bottom-10">1. Aspecte Generale</h3>

                                <p>Prezentul document („Termeni și Condiții”) guvernează raporturile dintre Lance Hub Services S.R.L., o societate cu răspundere limitata, cu sediul în Bughea de jos 398A, P, Com.Gura Vitioarei, Jud.Prahova, România („Societatea” sau „Noi”) și persoanele care utilizează („Utilizatorul”) pagina de internet www.lancehub.ro („Site-ul” sau „Platforma lancehub.ro”) și/sau aplicația lancehub.ro echivalentă ce poate fi rulată pe un echipament informatic mobil, de tipul smartphone și/sau tabletă, („Aplicația lancehub.ro”) și/sau serviciile oferite de Societate prin intermediul Site-ului sau Aplicației („Serviciile”).</p>
                               
                                <p>Accesarea și utilizarea Site-ului, a Aplicației lancehub.ro, inclusiv a oricărei pagini sau secțiuni componente ale acestora, sau a oricărui Serviciu furnizat prin intermediul Site-ului sau Aplicației lancehub.ro se poate face numai în conformitate cu prezentul document, care include Politica de Confidențialitate a Datelor și Politica privind Cookie-urile.</p>
                                
                                <p>PENTRU UTILIZAREA SERVICIILOR, VOM SOLICITA UTILIZATORULUI SĂ ÎȘI EXPRIME ACORDUL CU PRIVIRE LA ACEȘTI TERMENI ȘI CONDIȚII PRINTR-O ACȚIUNE SPECIFICĂ, CARE ARE SEMNIFICAȚIA UNUI CONSIMȚĂMANT NEECHIVOC (E.G. BIFAREA CĂSUȚEI SPECIFICE „AM CITIT ȘI SUNT DE ACORD CU TERMENII ȘI CONDIȚIILE LANCEHUB.RO”).</p>
                                
                                <p>BIFÂND ACEASTĂ CĂSUȚĂ, UTILIZATORUL:</p>
                                
                                <p><p>A.  ÎȘI DĂ ACORDUL, ÎN MOD EXPRES, SĂ RESPECTE TOATE CLAUZELE DIN ACEST SET DE TERMENI ȘI CONDIȚII, PRECUM ȘI ORICE ALȚI TERMENI ȘI CONDIȚII SPECIFICE APLICABILE SERVICIILOR</p></p>

                                <p>IA LA CUNOȘTINȚĂ CONDIȚIILE DE PRELUCRARE A DATELOR PERSONALE CONFORM POLITICII DE CONFIDENTIALITATE, CARE ESTE PARTE INTEGRANTĂ DIN ACEST SET DE TERMENI ȘI CONDIȚII.</p>

                                <p>Pentru a asigura respectarea condițiilor de acces și utilizare a Serviciilor, Utilizatorii trebuie să verifice la momentul fiecărei accesări a Site-ului și/sau a Aplicației lancehub.ro termenii și condițiile de utilizare.</p>

                                <p>Chiar dacă omiteți să analizați termenii și condițiile, dar utilizați Platforma lancehub.ro sau Aplicația lancehub.ro ca Utilizator (Recrutor sau Candidat), vom considera ca ați acceptat acest set de Termeni și Condiții (inclusiv orice actualizare a acestora).</p>
                            
                                <p>Dacă nu sunteți de acord cu prevederile din acest set de Termeni și Condiții (inclusiv orice actualizare a acestuia), vă rugăm să nu utilizați, sau după caz, să încetați utilizarea Site-ului, Aplicației lancehub.ro sau a Serviciilor oferite de Noi.</p>
                            
                                <h3 className="margin-bottom-10 margin-top-50">2. Serviciile lancehub.ro</h3>

                                <p>Serviciile oferite lancehub.ro și Aplicația lancehub.ro se adresează (i) Utilizatorilor, persoane fizice, care vizitează Platforma lancehub.ro, (ii) Utilizatorilor care caută un loc de muncă, un proiect de colaborare („Utilizatori Candidați”) și (iii) Utilizatorilor, persoane juridice sau persoane fizice autorizate, care vor să publice anunțuri de angajare de personal independent, sau de de gasire de specialist pentru proiectele și activitatile lor, sau vor să caute Utilizatori Candidați pentru anumite locuri de muncă („Utilizatori Recrutori”).</p>
                            
                                <h4 className="margin-bottom-10 margin-top-50">2.1. Crearea contului de Utilizator, parole și responsabilități</h4>

                                <p>Utilizatorii Candidați pot folosi anumite facilități oferite de Platforma lancehub.ro și fără a avea un cont de Utilizator.</p>

                                <p>Pentru a beneficia de toate Serviciile oferite de Platforma lancehub.ro, Utilizatorii (fie Utilizatori Candidați, fie Utilizatori Recrutori) trebuie să își creeze un cont pe Platforma lancehub.ro, completând formularele disponibile în interfața secțiunii „Inregistrare”.</p>
                            
                                <p>În cazul Utilizatorilor Candidați, crearea contului de Utilizator se poate face și prin intermediul contului de utilizator pe platforma de socializare Facebook, caz în care Plaforma lancehub.ro va prelua anumite date publice ale Utilizatorului aferente contului său de Facebook.</p>
                            
                                <p>După finalizarea procesului de creare a contului de Utilizator, Utilizatorul va primi pe adresa de e-mail furnizată și pe numărul de telefon furnizat în procesul de înrolare (creare cont) codurile necesare pentru verificarea și validarea datelor de contact și a contului nou creat.</p>
                            
                                <p>Pentru a folosi Serviciile oferite de Platforma lancehub.ro, este necesar să:</p>
                            
                                <p>A. furnizați date adevărate, exacte și complete despre dumneavoastră, prin completarea câmpurilor din formularul de înregistrare pentru crearea contului de Utilizator</p>
                            
                                <p>B. actualizați, atunci când situația o cere, datele de înregistrare pentru a fi adevărate, exacte și complete în orice moment. Înțelegeți să faceți aceasta actualizare în cel mult 30 de zile de la data la care s-au produs schimbările.</p>
                            
                                <p>În cazul Utilizatorilor Recrutori, lancehub.ro va verifica corectitudinea codului fiscal înregistrat pe site-ul Ministerului de Finanțe, www.mfinante.ro. Pentru persoane juridice, Utilizatorii Recrutori au obligația de a ne transmite informații reale referitoare la denumire, adresă e-mail, telefon, nume și prenume persoană de contact și cod fiscal. Persoanele juridice - Utilizatorii Recrutori, având cont activ pe lancehub.ro trebuie să fie activi din punct de vedere fiscal (astfel, societățile Utilizatorilor Recrutori nu pot fi radiate, suspendate, lichidate). Acest criteriu nu include și societățile în insolvență.</p>
                            
                                <p>Companiile care recrutează pentru străinătate au obligația să trimită o copie după Certificatul de Înregistrare eliberat de Registrul Comerțului cu domeniul de activitate plasarea și recrutarea de personal.</p>
                            
                                <p>Utilizatorii Recrutori care au sediul în străinătate au obligația ca, la momentul înregistrării pe site, să completeze în câmpul dedicat din formularul de creare a contului de Utilizator numărul de TVA valid și să ne trimită o copie a documentului care atestă înregistrarea fiscală în statul de rezidență.</p>
                            
                                <p>Prin sondaj, consultanții lancehub.ro pot solicita documente privind domeniul de activitate al Utilizatorului Recrutor, dacă persoana de contact este împuternicită să reprezinte Compania sau poate verifica corectitudinea numărului de telefon indicat în formularul de creare a contului de Utilizator.</p>

                                <p>Dacă veți furniza informații care nu sunt conforme cu realitatea, inexacte sau incomplete, lancehub.ro are dreptul să suspende sau să blocheze contul dumneavoastră și să refuze toate încercările curente sau viitoare de folosire a Platformei lancehub.ro. În cazul informațiilor eronate sau a încercărilor de fraudare ale Utilizatorilor Recrutori (inclusiv pentru obținerea anunțurilor gratuite sau ofertelor speciale acordate de lancehub.ro în cadrul campaniilor de marketing), lancehub.ro are dreptul, oricând pe durata relației contractuale, să retragă gratuitățile oferite sau să înceteze relația contractuală, fără nicio responsabilitate suplimentară față de Utilizatorii Recrutori aflați în culpă.</p>

                                <p>Lancehub.ro NU are obligația de a verifica în avans materialele publicat de către Utilizatori. lancehub.ro nu răspunde în niciun mod cu privire la informațiile și datele postate, difuzate sau transmise de către Utilizatorii săi. Dacă i se solicită de către un Utilizator sau dacă descoperă semne de o posibilă fraudare, lancehub.ro poate investiga și verifica afirmațiile și poate hotărî dacă informațiile respective trebuie îndepărtate.</p>

                                <h4 className="margin-bottom-10 margin-top-50">2.2. Utilizatori Candidați</h4>
                                
                                <p>Utilizatorii Candidați își pot crea cont de Utilizator doar dacă au la data creării contului cel puțin vârsta de 16 ani. Accesarea Platformei lancehub.ro și utilizarea Serviciilor nu este permisă persoanelor sub 16 ani.</p>

                                <p>lancehub.ro este o platformă de recrutare online destinat muncii independente care vă acordă posibilitatea de a vă crea un profil în baza noastră de date.</p>

                                <p>Profilurile încărcate în Platforma lancehub.ro, precum și datele dvs. de contact pot apoi fi făcute disponibile Utilizatorilor Recrutori în scop de angajare. În acest sens, Utilizatorii Candidați au trei opțiuni:</p>

                                <p>a. Vă puteți înregistra profilul în baza noastră de date. La momentul înregistrării contului de utilizator, în mod automat, datele din profilul dvs. sunt setate ca fiind publice si va putea fi vizualizat de Utilizatorii Recrutori care v-au achiziționat profilul când încă era public și de către Utilizatorii Recrutori la ale căror anunțuri ați aplicat sau veți aplica.</p>

                                <p>b. Puteți face public profilul pe Platforma lancehub.ro - în acest caz Utilizatorii Recrutori care au acces la baza de date a Platformei lancehub.ro vor avea acces la profilul dvs. Dacă vor dori, aceștia ne pot solicita deblocarea datelor dvs. de contact și veți putea fi contactați de aceștia în eventualitatea unui interviu sau unei oferte de muncă, sau de colaborare. Pentru a putea face profilul public, puteți folosi opțiunea ' profil public pentru angajatori', disponibilă în secțiunea profilul meu - Vizibilitate Profil'. Puteți oricând să vă modificați opțiunea ulterior și să vă faceți profilul confidențial, folosind optiunea profil ascuns pentru angajatori'.</p>

                                <p>Încercăm să limităm accesul la baza noastră de date doar angajatorilor, firmelor de resurse umane, managerilor de personal, specialiștilor în HR, dar nu garantăm că alți terți nu pot avea acces la baza noastră de date. lancehub.ro nu își asumă responsabilitatea asupra utilizării profilul de către terțe părți care l-au accesat cât timp acestea sunt în baza noastră de date.</p>

                                <p>Puteți să alegeți să vă ascundeți profilul în baza noastră de date oricând sau, dacă doriți, să vă ștergeți chiar contul de Utilizator. În cazul în care ascundeți profilul din baza de date sau vă ștergeți contul de utilizator, Utilizatorii Recrutori nu vor mai avea acces la datele dumneavoastră.</p>

                                <p>Totuși, ca parte a Serviciilor furnizate de Platforma lancehub.ro, Utilizatorii Recrutori care au accesat facilitatea de acces la datele din profilul Dvs. pot descărca sau transmite informația disponibilă în Platforma lancehub.ro pe propriile medii de stocare. În acest caz, chiar dacă noi vom șterge datele dvs. din baza noastră de date, Utilizatorii Recrutori vor putea avea acces în continuare la datele dvs. în cazul în care le-au descărcat, copiat sau extras din baza de date a Platformei lancehub.ro. În calitatea lor de operatori, Utilizatorii Recrutori sunt în mod independent responsabili de modul în care utilizează datele dvs. pe care le accesează prin intermediul Platformei lancehub.ro.</p>

                                <p>Unele companii care au ales să își facă publice anunțurile de recrutare prin intermediul Platformei lancehub.ro pot înțelege ca este mai simplu să prezinte o realitate înșelătoare, incompletă, incorectă sau agresiv orientată spre alte categorii sociale. Prezentele reguli impun corectitudine din partea angajatorilor, însă nu toți Utilizatorii Recrutori aleg să respecte regulile. Ar trebui deci să discerneți înainte de a folosi informația cuprinsă în aceste anunțuri înainte a merge la un interviu, de a vă angaja, de a colabora, sau de a realiza o investiție sau orice altă acțiune care implică folosirea de fonduri sau resurse proprii sau ale unor terți. Vă rugăm să ne contactați în situația în care aflați de existența unor astfel de anunțuri la adresa de e-mail office@lancehub.ro.</p>

                                <h3 className="margin-bottom-10 margin-top-50">2.3. Utilizatori Recrutori</h3>
                                
                                <p>Lance Hub Services S.R.L. vă asigură accesul pe bază de parolă la contul de Utilizator creat pe Platforma lancehub.ro. Prin intermediul lancehub.ro, Utilizatorii Recrutori pot căuta și selecta Utilizatori Candidați în vederea angajării, sau colaborarii sub alte forme contractuale.</p>

                                <h4 className="margin-bottom-10 margin-top-50">2.3.1. Cum se pot publica anunțuri de proiecte disponibile pe Platforma lancehub.ro</h4>
                                
                                <p>Anunțurile de proiecte pot fi afișate pe Platforma lancehub.ro pentru o perioadă determinată. În funcție de anunțul achiziționat (sau primit gratuit în cadrul unei campanii), acesta poate avea o valabilitate de 7, 14 sau 30 de zile, cu posibilitatea de prelungire. Ulterior, anunțul dumneavoastră este trimis prin e-mail tuturor Utilizatorilor Candidați care corespund profilului jobului și care sunt abonați la astfel de notificări. Profilul proiectului din anunțul validat spre publicare (Utilizatorul Recrutor a dat click pe butonul de publicare anunț) nu mai poate fi modificat după ce anunțul a fost afișat pe Platforma lancehub.ro și/sau a fost trimis către Utilizatorii Candidați. Modificări ulterioare pot fi făcute la cerere, cu ajutorul consultantului lancehub.ro dedicat companiei dvs. </p>

                                <p>Pentru a publica un proiect în industria MLM (AVON, ORIFLAME, altele), sau proiecte de COLABORATOR BANCAR/ASIGURARI sau alte joburi de COLABORATOR, trebuie să faceți o comandă de cel puțin 1 anunț și să ne confirmați plata facturii proforma. După ce ne trimiteți confirmarea plății, veți putea publica imediat jobul dorit. Anunțurile de operatoare videochat sau altele asemănătoare nu sunt agreate pe site-ul nostru chiar dacă au fost postate de persoane juridice.</p>

                                <h4 className="margin-bottom-10 margin-top-50">2.3.2. Politica „anunțuri gratuite” și discount-uri speciale</h4>

                                <p>lancehub.ro poate oferi, la alegerea sa, credite gratuite pentru publicarea anunțurilor de angajare, sau colaborare pe Platforma lancehub.ro sau discount-uri speciale la produsele și serviciile sale, ofertele fiind disponibile doar Utilizatorilor Recrutori înregistrați pentru prima data pe Platforma lancehub.ro, care au completat în mod corect și complet datele de înregistrare, în condițiile în care nu există suspiciuni de fraudare a sistemului. De exemplu, dacă înregistrarea se realizează în momentul în care platforma lancehub.ro ofera servicii de testare gratuite, aceste servicii vor fi valabile doar la prima înregistrare a unei companii/grup de companii/persoana de contact, și nu și la înregistrări ulterioare ale conturilor conexe, lancehub.ro rezervându-și dreptul de a refuza acordarea unei astfel de gratuități, în cazul identificării unei identități cu o societate care a beneficiat deja de un astfel de anunț.</p>

                                <p>În urma analizei interne a Utilizatorilor Recrutori nou înregistrați pe Platforma lancehub.ro, ne rezervăm dreptul de a refuza acordarea creditelor gratuite, sau de discount-urilor speciale.</p>

                                <p>Anunțurile gratuite pot avea același regim de valabilitate în timp ca și anunțurile cu plată și pot fi publicate numai în numele Utilizatorului Recrutor înregistrat în Platforma lancehub.ro.</p>

                                <p>De asemenea, ne rezervăm dreptul să refuzăm spre publicare sau să cenzurăm în orice alt fel anunțurile care sunt contrare legii sau bunelor moravuri, sau care conțin un limbaj licențios. Spre exemplu, nu vom accepta anunțuri de operatoare videochat, masaj erotic sau altele asemănătoare.</p>

                                <p>În acest sens, acceptați și sunteți de acord cu faptul că veți respecta regula bunei credințe atunci când concepeți și publicați anunțul de recrutare. Astfel, vă obligați să nu publicați anunțuri incomplete, incorecte sau discriminatorii și să nu condiționați ulterior selecția Utilizatorilor Candidați decât de acele reguli prezentate în anunțul de recrutare publicat de respectarea legislației aplicabile incidente.</p>

                                <p>În situația în care nu veți respecta aceste reguli, înțelegeți și sunteți de acord cu faptul că lancehub.ro are dreptul de a vă restricționa accesul la Serviciile sale. În cazul restricționării accesului Utilizatorilor Recrutori care au accesat Servicii plătite, aceștia vor avea dreptul la solicitarea restituirii părții de tarif corespunzătoare perioadei restricționate.</p>

                                <p>Lance Hub Services S.R.L.  poate alege, de asemenea, să ofere „anunțuri gratuite” Utilizatorilor Recrutori deja înregistrați sau discount-uri speciale în cadrul anumitor campanii de marketing. Clauzele acordării unor astfel de gratuități sau reduceri vor fi semnalate punctual cu ocazia fiecărei campanii.</p>

                                <h4 className="margin-bottom-10 margin-top-50">2.3.3 Alegeți să căutați profilul în baza de profiluri publice de pe Platforma lancehub.ro (serviciul Cautare referinte profesionisti).</h4>
                                
                                <p>Alegeți unul dintre pachetele de prețuri care corespund numărului de profiluri pe care le puteți vizualiza în baza de date a Platformei lancehub.ro. Plătiți prețul Serviciului. Puteți căuta prin toate profilurile publice din baza noastră de date. Puteți vizualiza oricâte profiluri vă doriți, fără date de contact. În funcție de pachetul de cautare referinte profesionist ales, puteți vizualiza datele de contact ale unui anumit număr de profiluri.</p>

                                <p>În toate cazurile, înțelegeți și sunteți de acord cu faptul că nu veți folosi datele cuprinse în profilurile Utilizatorilor Candidați decât cu scopul unic de gasire specialisti pentru proiectele Dvs.</p>

                                <p>Unii candidați care au ales să își introducă profilul în baza de date a Platformei lancehub.ro pot înțelege că este mai simplu să prezinte o realitate înșelătoare, incompletă, incorectă sau agresiv orientată spre alte categorii sociale. Ar trebui deci să discerneți înainte de a folosi informația cuprinsă în aceste profiluri înainte de a face o angajare, a colabora sub orice forma, o investiție sau orice altă acțiune care implică folosirea de fonduri sau resurse proprii sau ale unor terți. Vă rugăm să ne contactați în situația în care aflați de existența unor astfel de profiluri.</p>

                                <h4 className="margin-bottom-10 margin-top-50">2.3.4 Abonamente, plata abonamentelor și alte tranzacții</h4>
                                
                                <p>Accesul la unele Servicii furnizate Utilizatorilor Recrutori poate să fie permis doar după plata unui abonament. Toate plățile, incluzând prin aceasta, taxele aplicabile acestor plăți, potfi realizate prin plata online cu cardul, pe bază de factură proforma sau, la solicitarea dvs., pe bază de factură fiscală în contul indicat de lancehub.ro la cursul de schimb BNR din data emiterii proformei. În acest moment, Utilizatorii Recrutori care reprezintă companii străine pot achiziționa servicii și produse lancehub.ro doar prin plata online cu cardul. Accesul la unele dintre Servicii nu va fi permis decât după momentul la care se confirmă transferul de bani în contul lancehub.ro.</p>

                                <p>Platforma lancehub.ro poate fi folosită, de unii dintre partenerii săi de afaceri, drept bază pentru vânzarea sau promovarea unor produse și servicii. În aceasta situație, contractul care va reglementa contractul dintre dvs. și furnizorul de produse și servicii respectiv se va încheia între dvs. și respectivul furnizor de produse și servicii și nu între dvs. și lancehub.ro.</p>

                                <p>În situația în care doriți încetarea furnizării serviciului către dumneavoastră, înțelegeți și sunteți de acord cu faptul că plățile deja efectuate nu sunt și nu vor fi returnate.</p>

                                <p>Serviciile furnizate de lancehub.ro au la bază un anumit număr de anunțuri de publicare de proiecte, sau profiluri publice cu date de contact. Lancehub.ro își poate schimba prețul stabilit pentru furnizarea serviciului, la fel cum poate modifica orice altă clauză a prezentului acord, la încetarea perioadei inițiale a contractului.</p>

                                <p>Acceptați și sunteți de acord ca serviciile achiziționate să fie folosite în termen de maximum 24 de luni de la data facturii fiscale. Serviciile nefolosite își pierd valabilitatea și vor fi șterse din contul utilizatorului Recrutor după 24 de luni de la data facturii fiscale.</p>

                                <p>Acceptați și sunteți de acord că orice întârziere în plata sumelor de bani către lancehub.ro va constitui o încălcare a prevederilor prezentului acord. În condițiile în care lancehub.ro își rezervă dreptul să adopte și alte atitudini permise de lege sau contract, sunteți de acord și înțelegeți să RAMBURSAȚI ÎN TOTALITATE CĂTRE LANCEHUB.RO TOATE COSTURILE ȘI CHELTUIELILE CARE POT REZULTA ÎN LEGĂTURĂ CU RECUPERAREA SAU ÎNCERCAREA DE RECUPERARE A ACESTOR SUME NEPLĂTITE.</p>

                                <p>Acceptați și sunteți de acord ca publicarea unui anunț pe site-ul lancehub.ro înaintea efectuării unei plăți către lancehub.ro echivalează cu intrarea într-o relație contractuală cu lancehub.ro, dând naștere obligației echivalente de plată a respectivului anunț, mai puțin în cazul în care anunțul respectiv a fost acordat sub formă de gratuitate în cadrul unei campanii de marketing.</p>

                                <p>După scurgerea termenului agreat de plată a unei facturi proforme, așa cum este acesta înscris în respectiva factură, lancehub.ro este îndreptățit să restricționeze accesul la platformă până la efectuarea plății. Neplata la termen poate da naștere la calculul de penalități de întârziere în cuantum de 0.1% per zi de întârziere, calculat până la plata sumei integrale.</p>

                                <p>Acest lucru nu are repercusiuni și asupra anunțurilor care vor rămâne în continuare active: candidații vor putea în continuare să aplice la anunțurile active ale companiei, urmând să aveți acces la profilurile lor după efectuarea plății.</p>

                                <h4 className="margin-bottom-10 margin-top-50">2.3.5 Accesul la contul de Utilizator Recrutor pentru alte persoane din cadrul aceleiași organizații</h4>

                                <p>Contul aferent Utilizatorului Recrutor permite setarea accesului și altor persoane (ex: angajați, colaboratori) la notificările transmise de Societate către contul de Utilizator Recrutor. Utilizatorul Recrutor este responsabil de colectarea datelor cu caracter personal ale persoanelor respective, în special de informarea și obținerea consimțământului (sau identificarea unui alt temei legitim) pentru transmiterea către Societate a datelor cu caracter personal utilizate pentru transmiterea unor astfel de comunicări.</p>

                                <h4 className="margin-bottom-10 margin-top-20">2.4 Publicarea profilurilor / anunțurilor de proiecte prin intermediul Platformei lancehub.ro</h4>
                                
                                <p>Platforma lancehub.ro este o platformă de comunicare între Utilizatori, aceștia fiind responsabili de conținutul publicat pe Platforma lancehub.ro (profiluri, anunțuri de proiecte de colaborare etc.), denumite în continuare "Conținut Publicat de Utilizator”. Ne rezervăm dreptul (care poate fi exercitat la momentul ales de noi și fără o notificare prealabilă), de a șterge, muta sau edita aceste mesaje sau de a restricționa accesul unora dintre Utilizatori la una dintre platformele sale de comunicare. Aceste măsuri pot fi luate ca urmare a nerespectării de către Utilizatori a prevederilor legale sau a termenilor și condițiilor de utilizare a Platformei lancehub.ro.</p>

                                <p>Utilizatorul este singurul responsabil pentru Conținutul Publicat de Utilizatorul respectiv. Pentru aceasta, trebuie să știți ca postarea sau transmiterea de mesaje prin intermediul Platformei lancehub.ro sunt acțiuni care sunt supuse următoarelor condiții și limitări:</p>

                                <p>a. Anunțurile publicate trebuie să fie complete, conținând toate informațiile sau condițiile aflate în legătură cu anunțul. Un anunț corespunde unei singure poziții, dar se poate referi la multiple posturi dacă recrutorul caută să angajeze mai multe persoane care să îndeplinească același rol în companie.</p>

                                <p>b. NU puteți publica, transmite sau face referire la orice tip de mesaj care poate conține o formă unanim recunoscută în societate ca fiind "publicitate" pentru diverse categorii de bunuri și servicii.</p>

                                <p>c. NU puteți publica, transmite sau recomanda Utilizatorilor niciun fel de mesaj comercial nesolicitat, indiferent dacă această trimitere se face sau nu prin intermediul sistemelor de comunicație puse la dispoziție de lancehub.ro sau prin intermediul altor mijloace de comunicații, dar utilizând datele obținute prin intermediul Platformei lancehub.ro.</p>

                                <p>d. NU puteți publica, transmite sau face referire în niciun fel la mesaje care conțin recomandări de a cumpăra sau de a nu cumpăra un anumit produs sau serviciu. De asemenea, nu puteți publica sau face referire în niciun fel la mesaje care conțin informații confidențiale, indiferent dacă acestea sunt sau nu prevăzute cu mențiunea "confidențial" sau cu orice altă mențiune de acest gen, sau la mesaje care au rolul de a afecta prețul, imaginea sau valoarea de piață a unui produs sau serviciu. Nu puteți publica anunțuri de angajare în cadrul rețelelor de tip AVON, ORIFLAME sau altele asemenea, precum și anunțurile de angajare pentru colaboratori bancari, decât prin achiziția în avans a cel puțin unui anunț.</p>

                                <p>e. NU puteți publica, transmite sau face referire în niciun fel la mesaje care conțin texte ilegale, amenințătoare, abuzive, indecente, discriminatorii sau care încalcă în orice fel drepturi ale terților sau prevederi legale.</p>

                                <p>f. NU puteți publica, transmite sau face referire în niciun fel la mesaje care conțin viruși sau orice alte secvențe de cod care se dovedesc a fi distructive sau care pot întrerupe, elimina sau limita funcționalitatea Platformei lancehub.ro sau a oricăror alte sisteme informatice (hardware sau software).</p>

                                <p>g. NU puteți colecta în niciun fel informații identificabile personal de la utilizatorii sau membrii lancehub.ro, decât cu scopul unic și direct de a accesa Serviciile puse la dispoziție de lancehub.ro (i.e. aplicarea la un anunț de proiect publicat de un Utilizator Recrutor sau contactarea Utilizatorilor Candidați).</p>

                                <p>h. NU puteți restricționa sau elimina accesul altor Utilizatori la platformele de comunicare puse la dispoziție de lancehub.ro.</p>

                                <p>i. NU puteți publica, transmite sau face referire în niciun fel la mesaje a căror sursă este ascunsă.</p>
                                
                                <p>j. NU puteți publica, transmite sau face referire la niciun fel de "joc piramidal" sau orice altă activitate menită a înșela încrederea unor persoane, precum și orice activități similare unor fapte în privința cărora s-a probat în trecut potențialul infracțional ori potențialul de a înșela încrederea unui Utilizator al Serviciilor lancehub.ro.</p>

                                <p>k. Nu puteți publica materiale care contravin oricăror altor prevederi ai acestui set de Termeni și Condiții, lancehub.ro putând în mod discreționar să le elimine din Platforma lancehub.ro, fără niciun fel de explicație în acest sens.</p>

                                <p>Prin publicarea Conținutului Publicat de Utilizator pe sau prin intermediul Platformei lancehub.ro sunteți de acord că dumneavoastră sunteți singurul responsabil și veți despăgubi lancehub.ro pentru orice pagubă, costuri sau limitări de profit care apar ca urmare a postării, transmiterii sau referinței de către dvs. a unor mesaje al căror conținut încalcă prevederile condițiilor și limitărilor descrise mai sus.</p>

                                <p>Unii Utilizatori pot înțelege ca este mai simplu să încalce regulile statuate în acest set de Termeni și Condiții, și, drept urmare, unele dintre mesajele publicate pot prezenta o realitate înșelătoare, incompletă, incorectă sau agresiv orientată spre alte categorii sociale. Ar trebui deci să discerneți înainte de a folosi informația cuprinsă în aceste mesaje pentru a face o investiție sau orice altă acțiune care implică folosirea de fonduri sau resurse proprii sau alte unor terți. Imaginea prezentată de mesajele publicate poate fi uneori diferită de realitate.</p>

                                <h3 className="margin-bottom-10 margin-top-50">3. Protecția Contului de Utilizator (Utilizator Candidat sau Utilizator Recrutor)</h3>
                                
                                <p>Accesul dvs. la contul de Utilizator al Platformei lancehub.ro este protejat de o parolă. Vă recomandăm să nu dezvăluiți nimănui această parolă. Platforma lancehub.ro nu va cere niciodată parola conturilor dvs. în mesaje sau telefoane nesolicitate. Mai mult, vă recomandăm să ieșiți din contul de Utilizator (i.e. să dați Ieșire cont / Sign out), la sfârșitul fiecărei sesiuni de utilizare. Vă sfătuim, de asemenea, să închideți fereastra browserului în care ați lucrat la sfârșitul navigării dvs. pe lancehub.ro. Dacă utilizați aplicația de mobil lancehub.ro vă recomandăm să luați măsuri de protecție suplimentare pentru a preveni accesul neautorizat la dispozitivele mobile pe care le utilizați (ex: parolarea dispozitivului mobil).</p>

                                <p>Aceste sfaturi sunt menite să vă ajute să evitați accesul persoanelor neautorizate la informațiile dvs. personale sau la corespondența dvs.</p>

                                <p>Sunteți răspunzători de păstrarea confidențialității asupra informațiilor și a parolei dumneavoastră. Veți fi răspunzători de utilizarea înregistrării dumneavoastră, indiferent dacă utilizarea se face cu sau fără voia dumneavoastră. Sunteți de acord să sesizați lancehub.ro în legătură cu orice accesare neautorizată a datelor dvs. de înregistrare în Platforma lancehub.ro. Societatea nu este răspunzătoare pentru nicio pagubă morală sau materială provocată prin nerespectarea de către dvs. a măsurilor de securitate a datelor utilizate pentru accesarea platformei lancehub.ro.</p>

                                <h3 className="margin-bottom-10 margin-top-50">4. Protecția proprietății intelectuale</h3>
                                
                                <p>Conținutul și designul platformei lancehub.ro (precum și orice alt material având legătura cu lancehub.ro) trimis către dvs. prin e-mail sau furnizat către dvs. în orice altă modalitate (ca, spre exemplu: articole, design, newslettere și orice alte materiale la care ne vom referi în continuare ca la "Conținut lancehub.ro"), aparțin lancehub.ro și colaboratorilor săi, indiferent dacă autorul este specificat sau nu, și sunt protejate de legislația privind proprietatea intelectuală. Articolele publicate pe Blog-ul sau pe pagina de Facebook a lancehub.ro au drepturi de proprietate intelectuala aparținând exclusiv Lance Hub Services S.R.L, sau colaboratorilor lancehub.ro care le-au realizat.</p>

                                <p>Nu puteți utiliza, reproduce sau permite nimănui să utilizeze sau să reproducă materialele fără a deține o permisiune scrisă din partea lancehub.ro sau colaboratorilor săi, care dețin un drept de proprietate intelectuală asupra secțiunii respective de conținut a Platformei lancehub.ro. De asemenea, este interzisă reproducerea, în orice fel, integrală sau parțială, a informațiilor cuprinse în profilurile Candidaților și în anunțurile de recrutare ale Companiilor, fără acordul scris și prealabil din partea lancehub.ro.</p>

                                <p>Puteți folosi conținutul Platformei lancehub.ro doar pentru uzul dumneavoastră și al companiei dumneavoastră, potrivit scopului pentru care v-ați înscris pe Platforma lancehub.ro. Cu excepția celor spuse mai sus, conținutul Platformei lancehub.ro nu poate fi reprodus, modificat și exploatat, indiferent de scopul comercial sau necomercial al acestei exploatări.</p>

                                <p>Acțiunile de tipul celor descrise mai jos nu sunt permise în lipsa obținerii în prealabil a unei permisiuni scrise din partea lancehub.ro:</p>

                                <p>a. reproducerea sau stocarea conținutului, precum și trimiterea acestui conținut către orice alt site, server sau orice alt mijloc de stocare a informației.</p>

                                <p>b. modificarea, publicarea, transmiterea, precum și participarea la transferul, vânzarea, distribuția unor materiale realizate prin reproducerea, modificarea sau afișarea conținutului, în lipsa obținerii în prealabil a unei permisiuni scrise din partea noastră.</p>

                                <p>c. îndepărtarea însemnelor care semnifică dreptul de autor al lancehub.ro asupra conținutului.</p>

                                <p>Orice utilizare a conținutului platformei lancehub.ro în alte scopuri decât cele permise expres de prezentul document este interzisă. Cererile de utilizare a conținutului în alte scopuri decât cele permise expres de prezentul document pot fi trimise la adresa de e-mail: office@lancehub.ro.</p>

                                <p>De asemenea, este interzisă utilizarea de mijloace automate, inclusiv script-uri, aplicații de tip robot, bot, spider, crawler și/sau sau orice aplicații/programe de calculator ce pot înșela sau simula anumite activități sau statusuri în platforma lancehub.ro sau, în orice fel, exploata anumite funcții sau vulnerabilități ale platformei lancehub.ro în vederea obținerii unor avantaje, pentru sine sau pentru alți Utilizatori sau terți, în ceea ce privește utilizarea platformei lancehub.ro sau accesarea și utilizarea oricăror Servicii.</p>

                                <h3 className="margin-bottom-10 margin-top-50">5. Protecția datelor cu caracter personal</h3>
                                
                                <p>Societatea tratează cu seriozitate confidențialitatea datelor cu caracter personal ale Utilizatorilor. Politica de confidențialitate și aspectele privind modul în care Societatea prelucrează datele cu caracter personal ale Utilizatorilor (inclusiv ale reprezentanților Utilizatorilor Recrutori) sunt descrise în secțiunea Politica de Confidențialitate.</p>

                                <p>De asemenea, trebuie menționat că Utilizatorii Recrutori acționează ca operatori de date cu caracter personal, conform legislației aplicabile, și că, în această calitate, acestora le revin o serie de drepturi și obligații corelative cu privire la datele cu caracter personal ale Utilizatorilor Candidați accesate prin intermediul Platformei lancehub.ro.</p>

                                <p>ATUNCI CÂND UTILIZEAZĂ SERVICIILE OFERITE PRIN INTERMEDIUL PLATFORMEI LANCEHUB.RO, UTILIZATORII RECRUTORI ACCEPTĂ ȘI SUNT DE ACORD SĂ RESPECTE TERMENII ȘI CONDIȚIILE PREVĂZUTE ÎN ACORDUL DE PRELUCRARE A DATELOR CU CARACTER PERSONAL, CARE FACE PARTE INTEGRANTĂ DIN ACEST SET DE TERMENI ȘI CONDIȚII. ATUNCI CÂND VĂ CREAȚI UN CONT DE UTILIZATOR RECRUTOR, TERMENII ȘI CONDIȚIILE VOR INCLUDE CA ANEXĂ ȘI ACORDUL DE PRELUCRARE A DATELOR CU CARACTER PERSONAL, ACESTA FIIND APOI DISPONIBIL ÎN CONTUL DVS. DE UTILIZATOR.</p>

                                <p>De asemenea, în cazul Utilizatorilor Recrutori situați în state terțe cărora nu li s-a recunoscut un nivel de protecție adecvat, aceștia vor putea avea acces la datele cu caracter personal ale Utilizatorilor Candidați doar în baza unui set de clauze contractuale standard încheiate privind introducerea unui set alternativ de clauze contractuale standard pentru transferul de date cu caracter personal către țări terțe aprobat de Comisia UE. Acordul de Prelucrare a Datelor cu Caracter Personal va include și un set de clauze contractuale standard care trebuie acceptate de un astfel de Utilizator Recrutor înainte să aibă acces la datele Utilizatorilor Candidați.</p>

                                <p>În lipsa clauzelor contractuale standard semnate cu Utilizatorul Recrutor, transferul se va putea face doar în baza unor alte mecanisme care asigură garanții adecvate conform Art. 46 din GDPR sau dacă transferul este necesar pentru executarea unui contract cu persoana vizată (respectiv atunci când Utilizatorul Candidat aplică în mod direct la un anunț de angajare publicat de un Utilizator Recrutor situat într-un stat terț).</p>

                                <h3 className="margin-bottom-10 margin-top-50">6. Modificări și Suspendarea accesului</h3>

                                <p>Lance Hub Services S.R.L. își rezervă dreptul de a suspenda, modifica, adăuga sau șterge în orice moment porțiuni ale conținutului platformei lancehub.ro. De asemenea, lancehub.ro își rezervă dreptul de a restricționa accesul Utilizatorilor la o parte sau la întregul său conținut.</p>

                                <p>Lance Hub Services S.R.L. poate, fără orice altă notificare sau formalitate și fără ca acest lucru să necesite explicarea atitudinii sale, să suspende sau să înceteze accesul dvs. la conținutul platformei lancehub.ro sau la o parte a acestui conținut. În acest sens, lancehub.ro poate bloca accesul, utilizarea serviciilor sau folosirea oricăror altor servicii ale sale de care beneficiați, sau să scoată și să șteargă orice material din cadrul Serviciilor, pentru orice motiv. Lance Hub Services S.R.L. poate de asemenea, oricând dorește și fără a da socoteală, întrerupe furnizarea Serviciilor, sau a unei părți a lor, cu sau fără nicio notificare prealabilă.</p>

                                <p>În cazul acesta, se vor aplica, acolo unde este cazul, prevederile Secțiunii 2.3.4 din Termeni și Condiții. În cazul restricționării accesului Utilizatorilor de servicii plătite, aceștia înțeleg și sunt de acord că singurele pretenții pe care le vor putea formula împotriva lancehub.ro se limitează la solicitarea restituirii părții de tarif corespunzătoare perioadei restricționate.</p>

                                <h3 className="margin-bottom-10 margin-top-50">7. Lipsa garanției și declinării de răspundere</h3>
                                
                                <p>Lance Hub Services S.R.L. nu poate garanta că informațiile prezentate de Utilizatorii Candidați în profilurile lor sunt corecte, complete și actuale, la fel cum nu poate garanta acest lucru nici în privința informațiilor prezentate de Utilizatorii Recrutori în anunțurile de angajare publicate pe platforma lancehub.ro. În măsura în care legea aplicabilă permite, Lance Hub Services S.R.L. nu va fi ținut răspunzător, în nicio situație, pentru nicio daună, pricinuită direct sau indirect, pentru nicio lipsă de profit directă sau indirectă (incluzând, dar fără a se limita la această enumerare: daune pentru pierderea profitului, întreruperea unor afaceri, sau alte daune pecuniare), suferite ca urmare a utilizării sau a întreruperii utilizării ori a lipsei regularității informațiilor și Serviciilor furnizate de lancehub.ro.</p>

                                <p>Lance Hub Services S.R.L. nu garantează acuratețea, corectitudinea sau caracterul actual al informațiilor ori Serviciilor furnizate de lancehub.ro. De asemenea, Lance Hub Services S.R.L. nu garantează faptul că sistemele informatice sau programele folosite de lancehub.ro pentru afișarea sau transmiterea informației pe Platforma lancehub.ro sau în orice altă formă nu conțin viruși ori alte secvențe distructive de cod sau alte proprietăți distructive. Ce putem garanta însă e că echipa noastră va căuta întotdeauna să ofere informații cât mai actuale și să asigure un nivel cât mai ridicat de siguranță pentru toți utilizatorii Platformei lancehub.ro.</p>

                                <p>În măsura în care legea aplicabilă permite, sunteți de acord să exonerați de răspundere Lance Hub Services S.R.L. pentru orice acțiuni judiciare sau extrajudiciare și să acoperiți cheltuielile de judecată și orice alte cheltuieli care ar putea să apară ca urmare a încălcării de către dvs. a clauzelor acestui set de Termeni și Condiții.</p>

                                <h3 className="margin-bottom-10 margin-top-50">8. Forță Majoră</h3>

                                <p>Lance Hub Services S.R.L., afiliații sau, în general, furnizorii de informație către lancehub.ro nu pot fi făcuți responsabili pentru nicio întârziere sau eroare în conținutul furnizat de publicațiile noastre, rezultând direct sau indirect din cauze care nu depind de voința lancehub.ro. Această exonerare include, dar nu se limitează la: erorile de funcționare ale echipamentului tehnic Lance Hub Services S.R.L., lipsa funcționării conexiunii la internet, lipsa funcționării conexiunilor de telefon, virușii informatici, accesul neautorizat în sistemele lancehub.ro, erorile de operare, greva etc.</p>

                                <h3 className="margin-bottom-10 margin-top-50">9. Diverse</h3>

                                <h4 className="margin-bottom-10 margin-top-20">9.1. Schimbarea termenilor prezentului acord</h4>
                                
                                <p>SOCIETATEA ÎȘI REZERVĂ DREPTUL DE A ACTUALIZA PERIODIC ACEST SET DE Termeni și Condiții. UTILIZATORII VOR FI NOTIFICAȚI CU PRIVIRE LA OPERAREA UNOR ACTUALIZĂRI / MODIFICĂRI ÎN setul de Termeni și Condiții ȘI LI SE VA SOLICITA ACORDUL EXPRES CU PRIVIRE LA setul de Termeni și Condiții ACTUALIZAT. REFUZUL DE A ACCEPTA setul de Termeni și Condiții ACTUALIZAT VA ATRAGE ÎNCETAREA CONTRACTULUI CU SOCIETATEA, IAR UTILIZATORII NU VOR MAI PUTEA UTILIZA PLATFORMA LANCEHUB.RO ȘI/SAU SERVICIILE SOCIETĂȚII.</p>

                                <p>De asemenea, unele servicii oferite de lancehub.ro pot face obiectul unor termeni diferiți, care vor fi afișați în cazul fiecărui astfel de serviciu, la loc vizibil, putând fi accesați înainte de folosirea serviciului.</p>

                                <h4 className="margin-bottom-10 margin-top-20">9.2. Publicitatea și conținutul furnizat de alte părți</h4>

                                <p>Părți ale conținutului inclus în lancehub.ro pot fi furnizate de terțe persoane cu care lancehub.ro are încheiate contracte de furnizare de conținut. De asemenea, în interiorul conținutului lancehub.ro pot fi incluse secțiuni publicitare în care se vor afișa mesaje publicitare ale unor terțe persoane. lancehub.ro nu este responsabil în nicio măsură pentru conținutul furnizat de terțe persoane, indiferent dacă acesta are scop publicitar sau nu. De asemenea, lancehub.ro nu este responsabil pentru conținutul paginilor la care se face trimitere din interiorul său.</p>

                                <h4 className="margin-bottom-10 margin-top-20">9.3 Notificări</h4>
                                
                                <p>Orice notificare către Lance Hub Services S.R.L. trebuie trimisă în scris, prin e-mail la adresa office@lancehub.ro, sau prin intermediul poștei.</p>

                                <p>Cu excepția cazului în care Utilizatorul comunică orice modificare, răspunsurile la notificări vor fi transmise la datele de contact furnizate de Utilizator, iar respectivele notificări vor fi considerate primite de către Utilizator.</p>

                                <h4 className="margin-bottom-10 margin-top-20">9.4. Legea aplicabilă și jurisdicție</h4>
                                
                                <p>Drepturile și obligațiile părților, impuse de prezentul acord, precum și toate efectele juridice pe care le produce prezentul acord vor fi interpretate și guvernate de legea română în vigoare. Orice litigiu care are ca obiect acest acord va fi adus spre soluționare în fața instanțelor competente în localitatea în care se află sediul Lance Hub Services S.R.L.</p>

                            </div>
                        </div>
                    
                </div>
            </div>

            {/* Spacer */}
            <div className="padding-top-40"></div>
            {/* Spacer */}
        </Fragment>
    );
}

export default TermsAndConditions;

