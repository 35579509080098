import React,{useState, useEffect, Fragment} from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { addNotification } from '../../actions';
import { host } from '../../constants/constants';
import BounceLoader from "react-spinners/BounceLoader";
import { css } from "@emotion/react";

const Blog = () => {

    const [blogPosts, setBlogPosts] = useState([]);
    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#2A41E8");

    useEffect(() => fetchBlogPosts(), []);

    const fetchBlogPosts = () => {
        axios({
			method: 'GET',
			url: `${host}/blog`,
			withCredentials: true
		}).then(res => {
            setLoading(false)
			setBlogPosts(res.data);
		}).catch(err => {
			addNotification('error', 'Nu s-au putut incarca postarile. Incercati in cateva minute');
		});
    }
    
    const getShortenedPostBody = (post) => {
        return post.substring(0, 300 ).concat("...");
    }

    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
        `;

    const contentList =  <div class="col-xl-16">
                            {/* Blog Post */}
                            {blogPosts && blogPosts.map(blogPost => 
                                    <Link to={`/blog/${blogPost.blogPostId}`}>
                                        <a href="pages-blog-post.html" class="blog-post">    
                                            {/* Blog Post Thumbnail */}
                                            <div className="blog-post-thumbnail">
                                                <div class="blog-post-thumbnail-inner">
                                                    <span class="blog-item-tag">{blogPost.postTag}</span>
                                                    <img src={`data:image/png;base64,${blogPost.postImage}`} alt=""/>
                                                </div>
                                            </div>
                                            
                                            <div class="blog-post-content">
                                                <span class="blog-post-date">{
                                                new Date(blogPost.createdAt).toLocaleDateString("ro-RO", 
                                                {
                                                    day: 'numeric', // numeric, 2-digit
                                                    year: 'numeric', // numeric, 2-digit
                                                    month: 'long', // numeric, 2-digit, long, short, narrow
                                                })
                                                }</span>
                                                <h3>{blogPost.title}</h3>
                                                <p> {getShortenedPostBody(blogPost.postBody)} </p>
                                            </div>


                                            {/* Icon */}
                                            <div class="entry-icon"></div>
                                        </a>
                                    </Link>
                                )}
                        </div>;


    return (
        <React.Fragment>
            {/* Content */}
            <div id="titlebar" class="white margin-bottom-30">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h2>Blog</h2>

                            {/* Breadcrumbs */}
                            <nav id="breadcrumbs" class="dark">
                                <ul>
                                    <li><Link to="/"><a href="#">Pagină principală</a></Link></li>
                                    <li>Blog</li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            {/* Section */}
            <div class="section gray">
                <div class="container">
                    <div class="row">
                        {/* Section Headline */}
                        <div class="section-headline margin-top-60 margin-bottom-35 magring-right-60">
                                    <h4>Postări recente</h4>
                        </div>

                        {loading==false ? 
                            contentList:
                            <div class="margin-top-100 margin-bottom-35 ">
                                <BounceLoader color={color} loading={loading} css={override} size={150} />
                            </div>
                        }
                </div>
            </div>
            <div class="padding-top-40"></div> 
                    
            </div>
           
        </React.Fragment>
    );
}
export default Blog;