import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { mobileMenu } from '../../../runJQuery';
import { signOut } from '../../../actions';

function FreelancerSidebar(props) {
	const checkActiveLink = (match) => {
		if (props.location.pathname === match) return 'active';
	}

	useEffect(mobileMenu, []);

	return (
		<div className="dashboard-sidebar">
			<div className="dashboard-sidebar-inner" data-simplebar>
				<div className="dashboard-nav-container">

					{/*~~ Responsive Navigation Trigger ~~*/}
					<a href="#" className="dashboard-responsive-nav-trigger">
						<span className="hamburger hamburger--collapse" >
							<span className="hamburger-box">
								<span className="hamburger-inner"></span>
							</span>
						</span>
						<span className="trigger-title">Dashboard</span>
					</a>

					{/*~~ Navigation ~~*/}
					<div className="dashboard-nav">
						<div className="dashboard-nav-inner">

							<ul data-submenu-title="Dashboard">
								<li className={checkActiveLink('/dashboard')}>
									<Link to="/dashboard"><i className="icon-material-outline-dashboard"></i> Aplicații</Link>
								</li>
								<li className={checkActiveLink('/dashboard/promotion')}>
									<Link to="/dashboard/promotion"><i className="icon-material-outline-star-border"></i> Promovare</Link>
								</li>
							</ul>

							<ul data-submenu-title="Settings">
								<li className={checkActiveLink('/profile')}>
									<Link to="/dashboard/profile"><i className="icon-material-outline-settings"></i> Profil</Link>
								</li>
								<li><a href="index-logged-out.html" onClick={signOut} ><i className="icon-material-outline-power-settings-new"></i> Logout</a></li>
							</ul>

						</div>
					</div>
					{/*~~ Navigation / End ~~*/}

				</div>
			</div>
		</div>
	);
}

export default withRouter(FreelancerSidebar);