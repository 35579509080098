import React from 'react';
import { signIn } from '../../actions';

class SignIn extends React.Component {
	state = {
		email: '',
		password: '',
		error: null
	};

	/* Form control */
	controlEmail = (e) => {
		this.setState({ email: e.target.value.trim() });
	}

	controlPassword = (e) => {
		this.setState({ password: e.target.value });
	}

	submitForm = (e) => {
		e.preventDefault();

		if (!this.state.email) return this.setState({ error: 'Please provide an email.' });
		if (!this.state.password) return this.setState({ error: 'Please provide a password.' });

		signIn(this.state.email, this.state.password, (err) => {
			if (err) return this.setState({ error: err });
			
			// on successful signin
			this.setState({ error: null }); // remove error
			window.$.magnificPopup.close(); // close auth modal
		});
	}

	/* Render */
	render() {
		return (
			<div className="popup-tab-content" id="login">
				{/*-- Notification error --*/}
				{this.state.error && (
					<div className="notification error auth-notification">
						<p> {this.state.error} </p>
					</div>
				)}

				{/*<!-- Welcome Text -->*/}
				<div className="welcome-text">
					<h3>Ne bucurăm sa te revedem!</h3>
					<span>Nu ai un cont? <a className="register-tab">Înregistrează un cont!</a></span>
				</div>

				{/*<!-- Form -->*/}
				<form id="login-form" onSubmit={this.submitForm}>
					<div className="input-with-icon-left">
						<i className="icon-material-baseline-mail-outline"></i>
						<input
							type="email"
							className="input-text with-border"
							name="email"
							placeholder="Email Address"
							value={this.state.email}
							onChange={this.controlEmail} />
					</div>

					<div className="input-with-icon-left">
						<i className="icon-material-outline-lock"></i>
						<input type="password"
							className="input-text with-border"
							name="password"
							placeholder="Password"
							value={this.state.password}
							onChange={this.controlPassword} />
					</div>
					<a className="forgot-password">Ți-ai uitat parola?</a>
				</form>

				{/*<!-- Button -->*/}
				<button className="button full-width button-sliding-icon ripple-effect" type="submit" form="login-form">Log In <i className="icon-material-outline-arrow-right-alt"></i></button>

			</div>
		);
	}
}


export default SignIn;