import { addNotification } from '../../../actions';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { host } from '../../../constants/constants';
import { rippleEffect, DashboardResize } from '../../../runJQuery';
import { withRouter } from 'react-router';

const Transactions = () => {

	const [transactions, setTransactions] = useState([]);

	useEffect(() => {
		//rippleEffect();
        fetchTransactions();
        //DashboardResize();
	}, []);

	const fetchTransactions = () => {
		axios({
			method: 'GET',
			url: `${host}/payment-history`,
			withCredentials: true
		}).then(res => {
			setTransactions(res.data);
		}).catch(err => {
			addNotification('error', 'Could not load transactions. Please try again in few minutes');
		});
	};
	
	return (
	    <div class="dashboard-content-container " data-simplebar>
			<div className="dashboard-content-inner " >

				{/*~~ Dashboard Headline ~~*/}
				<div className="dashboard-headline">
					<h3>Tranzacții</h3>
				</div>

				{/*~~ Row ~~*/}
				{/*Dashboard Box */}
				<div class="col-xl-8 ">
					<div class="dashboard-box">
						<div class="headline">
							<h3><i class="icon-material-outline-assignment"></i> Tranzacții Credite</h3>
						</div>
						<div class="content">
							<ul class="dashboard-box-list margin-botton-95">
                                {transactions != undefined && 
                                    transactions.map(transaction => 
                                        <li>
                                            <div class="invoice-list-item">
                                            <strong>{transaction.buyerName} </strong>
                                                <ul>
                                                    <li>Număr comandă: {transaction.orderId}</li>
                                                    <li>Data plății: {new Date(transaction.paymentDate).toLocaleDateString("ro-RO", 
                                                        {
                                                            day: 'numeric', // numeric, 2-digit
                                                            year: 'numeric', // numeric, 2-digit
                                                            month: 'long', // numeric, 2-digit, long, short, narrow
                                                        })}
                                                    </li>
                                                    <li>IBAN: {transaction.iban}</li>
                                                </ul>
                                                <ul className="margin-top-10">
                                                    
                                                    <li>Credite: {transaction.creditAmount}</li>
													<li>Sold credite: {transaction.sold}</li>
                                                    <li>Total achitat: {transaction.total} RON</li>
                                                    <li>Tip plată: {(transaction.paymentType && transaction.paymentType==='BANK_TRANSFER') ? "Transfer bancar" : "Card online" }</li>
                                                </ul>
												<ul className="margin-top-10">
                                                    
                                                    <li>Postare proiecte: {transaction.projectsAcquired}</li>
                                                    <li>Saptămâni de promovare: {transaction.promotionWeeksAcquired}</li>
                                                    <li>Referinte: {transaction.referencesAcquired}</li>
													<li>Licitații: {transaction.bidsAcquired}</li>
                                                </ul>
                                                <ul className="margin-top-10">
                                                    <li>Detalii plată: {transaction.message}</li>
                                                </ul>
                                                <ul className="margin-top-10">
                                                    <li>Stare: {transaction.status === "PAID" ? <span class="paid">Plătită</span> : <span class="unpaid">Neplătită</span> } </li>
                                                        
                                                </ul>
                                            </div>
                                            {/*Buttons */}
                                            {/* <div class="buttons-to-right">
                                                <a href="pages-invoice-template.html" class="button gray">View Invoice</a>
                                            </div> */}
                                        </li>
                                        
                                    )}
							</ul>
						</div>
					</div>
				</div>

			</div>
		</div>
		
	);
}

export default withRouter(Transactions);