import React, { useEffect } from 'react';
import UserWidget from './UserWidget';
import { Link } from 'react-router-dom';
import {mobileMenu} from '../../runJQuery';
import { connect } from 'react-redux';

function Header(props) {

	useEffect(() => {
		mobileMenu();
	},[]);

	return (
		<header id="header-container" className="fullwidth">
			<link rel="icon" href="images/justLogo.png"/>
			<div id="header">
				<div className="container">
					<div className="left-side">

						{/*--- Logo ---*/}
						<div id="logo" >
							<img src="/images/logo-black.svg" alt=""  />
						</div>

						{/*--- Navigation ---*/}
						<nav id="navigation">
							<ul id="responsive">
							

								<li><a href="#" ><Link to="/"> Pagină principală </Link></a></li>

								<li><a href="#"><Link to="/searchProject">Caută proiecte</Link></a></li>

								<li><a href="#"><Link to="/searchFreelancer">Caută profesionist</Link></a></li>
								
								<li><a href="#"><Link to="/packages">Pachete promoționale</Link></a></li>

								{(props.authenticated == true && props.userType !== undefined && props.userType != 'ADMIN') &&
									<li><a href="#"><Link to="/dashboard">Dashboard</Link></a></li>
								}

								{(props.authenticated == true && props.userType !== undefined && props.userType === 'ADMIN') &&
									<li><a href="#"><Link to="/admin-dashboard">Admin Dashboard</Link></a></li>
								}

								<li><a href="#"><Link to="/blog">Blog</Link></a></li>
							</ul>
						</nav>
						<div className="clearfix"></div>
					</div>
					<div className="right-side">

						{/*~~ User widget ~~*/}
						<UserWidget />

						<span className="mmenu-trigger">
							<button className="hamburger hamburger--collapse" type="button">
								<span className="hamburger-box">
									<span className="hamburger-inner"></span>
								</span>
							</button>
						</span>

					</div>

				</div>
			</div>
		</header>
	);
}

const mapStateToProps = (state) => ({
	authenticated: state.user.authenticated,
	userType: state.user.profile.userType
});

export default connect(mapStateToProps)(Header);