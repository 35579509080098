import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import ProfileUpdate from './ProfileUpdate';
import Projects from '../employer/Projects';
import FreelancerSidebar from './FreelancerSidebar';
import { interactiveEffects, keywordsEffect, tippyEffect, wrapperHeight, DashboardResize, fullPageScrollbar, mobileMenu } from '../../../runJQuery';
import AppliedProjects from './AppliedProjects';
import Promotion from './Promotion';

function FreelancerDashboard(props) {

    useEffect(() => {
        interactiveEffects();
        wrapperHeight();
        tippyEffect();
        keywordsEffect();
        fullPageScrollbar();
        DashboardResize();
        mobileMenu();
    }, []);

	return (
		<div className="dashboard-container">
			<FreelancerSidebar />

			<div className="dashboard-content-container">
				<Route path="/dashboard/" exact component={AppliedProjects} />
				<Route path="/dashboard/profile" exact component={ProfileUpdate} />
                <Route path="/dashboard/promotion" exact component={Promotion} />
			</div>
		</div>
	);
};

export default FreelancerDashboard;