import React, { useEffect, useRef, useState, Fragment } from "react"
import { useParams } from "react-router-dom"
import axios from 'axios'
import { host } from '../../constants/constants';
import { addNotification, addProjectApplication } from '../../actions';
import { inlineBG, modalTrigger, slidingButtonEffect } from "../../runJQuery";
import categories from "../../constants/categories.json"
import { useSelector } from "react-redux";

const FreelanceDetails = () => {
    const [worker, setWorker] = useState({
        portfolio: ""
    });

    const detailsSplit = worker.portfolio.split(/\n/);

    const userAuthenticated = useSelector(state => state.user.authenticated);
    const userType = useSelector(state => state.user.profile.userType);

    const { workerId } = useParams();

    const getCurrencySymbol = () => {
        switch(worker.hourlyTariffCurrency) {
            case "USD" : return "$";
            case "EUR" : return "EUR";
            default : return "RON";
        }
    }

    const getWorkerBudget = () => {
        return `${worker.hourlyTariff} ${getCurrencySymbol()}`;
    }
    
    const getJobType = () => {
        if (worker.workLocation === "ON_SITE" || worker.workLocation === "HQ") 
            return "La sediu";
        if (worker.workLocation === "REMOTE")
            return "Remote";
        if (worker.workLocation === "UNSPECIFIED")
            return "Neprecizat";
    }

    useEffect(() => {
        fetchFreelancer();
        inlineBG();
    }, []);

    const fetchFreelancer = () => {
		axios({
			method: 'GET',
			url: `${host}/worker/${workerId}`,
			withCredentials: true
		}).then(res => {
			setWorker(res.data);
		}).catch(err => {
			addNotification('error', 'Nu am putut găsi proiecte. Încearcă în câteva minute.');
		});
	};

    const getCategoryNameById = (catId) => {
		if(catId && catId.id != "-1")
			return categories.find(category => category.id === parseInt(catId.id)).name;
		else
			return "nicio categorie";
	}

    const unlockWorker = () => {
        axios({
			method: 'POST',
			url: `${host}/users/unlock/${workerId}`,
			withCredentials: true
		}).then(res => {
            addNotification('succes', 'Profesionist deblocat.');
		}).catch(err => {
			addNotification('error', 'Nu am putut debloca profesionistul. Încearcă în câteva minute.');
		});
    }

    if (worker.categories)
        worker.categories.sort();

    return (
        <Fragment>
            <div className="single-page-header freelancer-header" data-background-image="/images/single-freelancer.jpg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="single-page-header-inner">
                                <div className="left-side">
                                    <div className="header-image freelancer-avatar"><img src="/images/user-avatar-placeholder.png" alt=""/></div>
                                    <div className="header-details">
                                        <h3>{worker.name} <span>{worker.description}</span></h3>
                                        <ul>
                                            <li><img className="flag" src="/images/flags/ro.svg" alt=""/> România</li>
                                            <li>Freelancer</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">

                    {/* Content */}
                    <div className="col-xl-8 col-lg-8 content-right-offset">

                        {/* Page Content */}
                        <div className="single-page-section">
                            <h3 className="margin-bottom-25">Despre mine</h3>
                            {   detailsSplit.map(paragraph => 
                                        <p>{paragraph}</p>
                                    )
                            } 
                        </div>
                    </div>

                    {/* Sidebar */}
                    <div className="col-xl-4 col-lg-4">
                        <div className="sidebar-container">

                            {/* Profile Overview */}
                            <div className="profile-overview">
                                <div className="overview-item"><strong>{getWorkerBudget()}</strong><span>Rată orară</span></div>
                                <div className="overview-item"><strong>{getJobType()}</strong><span>Mod de lucru</span></div>
                                <div className="overview-item"><strong>{worker.workerCity}</strong><span>Locație</span></div>
                            </div>

                            {/* Button */}
                            {userAuthenticated === true && userType === "CLIENT" ? 
                                <a href="#small-dialog" className="apply-now-button popup-with-zoom-anim margin-bottom-50" onClick={unlockWorker}><i className="icon-feather-unlock margin-right-10"></i>Deblochează </a>
                                : <div className="sidebar-widget border-top section-headline" />}

                            

                            {/* Widget */}
                            <div className="sidebar-widget">
                                <h3>Skills</h3>
                                <div className="task-tags">
                                    {worker.skills != null &&
                                        worker.skills.map(skill => 
                                            <span>{skill}</span>)
                                    }
                                </div>
                            </div>


                            {(worker.categories != null && worker.categories && worker.categories[0]) &&
                                <div className="sidebar-widget">
                                    <h3>Categorie principală</h3>
                                    <div className="freelancer-socials margin-top-25">
                                        <ul>
                                            <li><a  title="Dribbble" data-tippy-placement="top" style={{fontSize: 18}}><i className="icon-material-outline-local-offer margin-right-10"></i>  {getCategoryNameById(worker.categories[0])} </a></li>
                                        </ul>
                                    </div>
                                </div>
                            }
                            {(worker.categories != null && worker.categories && worker.categories[1]) &&
                                <div className="sidebar-widget">
                                <h3>Categorie principală</h3>
                                <div className="freelancer-socials margin-top-25">
                                    <ul>
                                        <li><a  title="Dribbble" data-tippy-placement="top" style={{fontSize: 18}}><i className="icon-material-outline-local-offer margin-right-10"></i>  {getCategoryNameById(worker.categories[1])} </a></li>
                                    </ul>
                                </div>
                            </div>
                            }
                        </div>
                    </div>

                </div>
            </div>


            {/* Spacer */}
            <div className="margin-top-15"></div>
        </Fragment>
    );
};

export default FreelanceDetails;