import React from 'react';
import { useEffect } from 'react';
import { modalTrigger } from '../../../runJQuery';

function AuthButton() {
	let ref = React.createRef();

	useEffect(() => {
		modalTrigger(ref);
	}, [ref]);

	return (
		<div className="header-widget">
			<a href="#sign-in-dialog" className="popup-with-zoom-anim log-in-button" ref={ref}>
				<i className="icon-feather-log-in"></i>
				<span>Log In / Register</span>
			</a>
		</div>
	);
}

export default AuthButton;