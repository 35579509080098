import React from 'react';
import useClickToOpen from '../../../hooks/useClickToOpen';

function UserNotifications() {
	let ref = React.createRef();
	let [active, toggleState] = useClickToOpen(ref);

	const classFromState = () => {
		if (active) return 'header-notifications active';
		return 'header-notifications';
	}

	return (
		<div className="header-widget hide-on-mobile">

			{/*~~ Notifications ~~*/}
			<div className={classFromState()} ref={ref}>

				{/*~~ Trigger ~~*/}
				<div className="header-notifications-trigger" onClick={toggleState}>
					<button><i className="icon-feather-bell"></i><span>4</span></button>
				</div>

				{/*~~ Dropdown ~~*/}
				<div className="header-notifications-dropdown">

					<div className="header-notifications-headline">
						<h4>Notifications</h4>
						<button className="mark-as-read ripple-effect-dark" title="Mark all as read" data-tippy-placement="left">
							<i className="icon-feather-check-square"></i>
						</button>
					</div>

					<div className="header-notifications-content">
						<div className="header-notifications-scroll" data-simplebar>
							<ul>
								{/*~~ Notification ~~*/}
								<li className="notifications-not-read">
									<a href="dashboard-manage-candidates.html">
										<span className="notification-icon"><i className="icon-material-outline-group"></i></span>
										<span className="notification-text">
											<strong>Michael Shannah</strong> applied for a job <span className="color">Full Stack Software Engineer</span>
										</span>
									</a>
								</li>

								{/*~~ Notification ~~*/}
								<li>
									<a href="dashboard-manage-bidders.html">
										<span className="notification-icon"><i className=" icon-material-outline-gavel"></i></span>
										<span className="notification-text">
											<strong>Gilbert Allanis</strong> placed a bid on your <span className="color">iOS App Development</span> project
												</span>
									</a>
								</li>

								{/*~~ Notification ~~*/}
								<li>
									<a href="dashboard-manage-jobs.html">
										<span className="notification-icon"><i className="icon-material-outline-autorenew"></i></span>
										<span className="notification-text">
											Your job listing <span className="color">Full Stack PHP Developer</span> is expiring.
												</span>
									</a>
								</li>

								{/*~~ Notification ~~*/}
								<li>
									<a href="dashboard-manage-candidates.html">
										<span className="notification-icon"><i className="icon-material-outline-group"></i></span>
										<span className="notification-text">
											<strong>Sindy Forrest</strong> applied for a job <span className="color">Full Stack Software Engineer</span>
										</span>
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default UserNotifications;