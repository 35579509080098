import React, { useEffect, useState } from 'react'
import { interactiveEffects, keywordsEffect, tippyEffect, wrapperHeight, DashboardResize, fullPageScrollbar } from '../../../runJQuery';
import DropdownWithSearch from '../../util/DropdownWithSearch';
import Dropdown from '../../util/Dropdown';
import { Link } from 'react-router-dom';
import regionalData from '../../../regionalData.json';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { addNotification } from '../../../actions';
import { host } from '../../../constants/constants';

const ClientProfileUpdate = (props) => {

    const [clientProfile, setClientProfile] = useState({
        address : {
            city : "Bucuresti",
            county: "Bucuresti"
        }
    });

    const [formErrors, setFormErrors] = useState({
        errors: {
            clientName: null,
            hourlyTariff: null,
            portfolio: null,
            description: null,
            currentPassword: null,
            newPassword: null,
            newPasswordConfirmation: null,
            phone: null
        }
    });

	const history = useHistory();

    const getUserProfile  = async () => {
        const options = {
            method: 'GET',
            url: `${host}/profile`,
            withCredentials: true
        };
    
        axios(options)
        .then(res => {
            let newProfile = res.data;
            if (newProfile.clientType === 'PERSONA' && newProfile.personaData == null) {
                newProfile ={
                    ...newProfile,
                    personaData : {
                        firstName : "",
                        lastName : "",
                        cnp : "",
                    },
                    currentPassword: "",
                    newPassword: "",
                    newPasswordConfirmation: ""
                };
            }
            if (newProfile.clientType === 'JURIDIC' && newProfile.juridicData == null) {
                newProfile ={
                    ...newProfile,
                    juridicData : {
                        noRegCom : "",
                        company : "",
                        iban : "",
                        cui: "",
                        postalCode: ""
                    },
                    currentPassword: "",
                    newPassword: "",
                    newPasswordConfirmation: ""
                };
            }
            if (newProfile.address.county === null || newProfile.address.city === null) {
                newProfile = {
                    ...newProfile,
                    address : {
                        city : "Bucuresti",
                        county: "Bucuresti"
                    }
                }
            }
            console.log(newProfile);
            setClientProfile(newProfile);
        })
        .catch(err => addNotification('error', "Nu s-au putut incarca datele! Încearcă în câteva minute."));
    }

    useEffect(() => {
		getUserProfile();
        interactiveEffects();
        wrapperHeight();
        tippyEffect();
        keywordsEffect();
        fullPageScrollbar();
    }, []);

      
    //events
    
	const controlClientName = (e) => {
        const clientName = e.target.value;
        setClientProfile(prev => ({...prev, clientName}));
    }

	const controlWorkLocation = (workLocation) => {
		setClientProfile(prev => ({...prev, workLocation }));
	}

	const controlCurrentPassword = (e) => {
		const currentPassword = e.target.value;
        setClientProfile(prev => ({...prev, currentPassword}));
	}

	const controlNewPassword = (e) => {
		const newPassword = e.target.value;
        setClientProfile(prev => ({...prev, newPassword}));
	}

	const controlNewPasswordConfirmation = (e) => {
		const newPasswordConfirmation = e.target.value;
        setClientProfile(prev => ({...prev, newPasswordConfirmation}));
	}

	const controlCounty = (county) => {
		setClientProfile(prevState => ({
			...prevState,
            address: { ...prevState.address,
                county,
                city: regionalData[county][0]
            }}));
	}

    const controlClientType = (clientType) => {
        setClientProfile(prevState => ({...prevState, clientType}));
        if (clientType === 'PERSONA' && clientProfile.personaData == null) {
            setClientProfile(prevState => ({...prevState, personaData : {
                firstName : "",
                lastName : "",
                cnp : "",
            }}));
        }
        if (clientType === 'JURIDIC' && clientProfile.juridicData == null) {
            setClientProfile(prevState => ({...prevState, juridicData : {
                noRegCom : "",
                company : "",
                iban : "",
                cui: "",
                postalCode: ""
            }}));
        }
    };

	const controlCity = (city) => {
		setClientProfile(prevState => ({...prevState, 
            address: { ...prevState.address, city}}));
	};

    const controlPostalCode = (e) => {
        const postalCode = e.target.value;
        setClientProfile(prevState => ({...prevState, 
            address: { ...prevState.address,postalCode}}));
    }

    const controlStreet = (e) => {
        const street = e.target.value;
        setClientProfile(prevState => ({...prevState, 
            address: { ...prevState.address, street}}));
    };

    const controlApartment = (e) => {
        const apartment = e.target.value;
        setClientProfile(prevState => ({...prevState, 
            address: { ...prevState.address, apartment}}));
    };

    const controlBuilding = (e) => {
        const building = e.target.value;
        setClientProfile(prevState => ({...prevState, 
            address: { ...prevState.address, building}}));
    };

    const controlNumber = (e) => {
        const number = e.target.value;
        setClientProfile(prevState => ({...prevState, 
            address: { ...prevState.address, number}}));
    };

	const controlPhone = (e) => {
		const phone = e.target.value;
		setClientProfile(prevState => ({...prevState, phone}));
	};

    const controlDescription = (e) => {
        const description = e.target.value;
        setClientProfile(prevState => ({...prevState, description :  description}));
    };

    const controlCompany = (e) => {
        const company = e.target.value;
        setClientProfile(prevState => ({...prevState, 
            juridicData: { ...prevState.juridicData, company}}));
    };

    const controlNoRegCom = (e) => {
        const noRegCom = e.target.value;
        setClientProfile(prevState => ({...prevState, 
            juridicData: { ...prevState.juridicData, noRegCom}}));
    };

    const controlCui = (e) => {
        const cui = e.target.value;
        setClientProfile(prevState => ({...prevState, 
            juridicData: { ...prevState.juridicData, cui}}));
    };

    const controlIban = (e) => {
        const iban = e.target.value;
        setClientProfile(prevState => ({...prevState, 
            juridicData: { ...prevState.juridicData, iban}}));
    };

    const controlContactPerson = (e) => {
        const contactPerson = e.target.value;
        setClientProfile(prevState => ({...prevState, 
            juridicData: { ...prevState.juridicData, contactPerson}}));
    };

    const controlFirstName = (e) => {
        const firstName = e.target.value;
        setClientProfile(prevState => ({...prevState, 
            personaData: { ...prevState.personaData, firstName}}));
    };

    const controlLastName = (e) => {
        const lastName = e.target.value;
        setClientProfile(prevState => ({...prevState, 
            personaData: { ...prevState.personaData, lastName}}));
    };

    const controlCnp = (e) => {
        const cnp = e.target.value;
        setClientProfile(prevState => ({...prevState, 
            personaData: { ...prevState.personaData, cnp}}));
    };

    /* Methods */
	const validateData = () => {
		const errors = {};

		//clientName
		const clientName = clientProfile.clientName;
		if (clientName == null || !clientName.trim()) errors.clientName = 'Introdu nume';
		if (clientName != null && clientName.trim().length < 3) errors.clientName = 'Introdu nume mai mare de 3 caractere';

		// details
		const description = clientProfile.description;
		if (description == null || !description.trim()) errors.description = 'Introdu o scurta descriere despre compania ta';
		if (description != null && description.trim().length < 20) errors.description = 'Introdu o scurta descriere despre compania ta mai mare de 3 caractere';

		//Passwords
		if ((clientProfile.newPassword != null && clientProfile.newPassword.trim() != "")
			|| (clientProfile.newPasswordConfirmation != null && clientProfile.newPasswordConfirmation.trim() != "")
			|| (clientProfile.currentPassword != null && clientProfile.currentPassword.trim() != "")) {

			if ((clientProfile.newPassword != null || !clientProfile.newPassword.trim())
				&& (clientProfile.newPasswordConfirmation != null || !clientProfile.newPasswordConfirmation.trim())
				&& (clientProfile.currentPassword == null || clientProfile.currentPassword.trim() === "")) errors.currentPassword = 'Introdu parola curenta';

			if ((clientProfile.newPassword != null || !clientProfile.newPassword.trim()) && clientProfile.newPassword.length < 6) errors.newPassword = 'Noua parola nu poate fi mai mică de 6 caractere';

			if ((clientProfile.newPassword != null || !clientProfile.newPassword.trim()) 
				&& (clientProfile.newPasswordConfirmation != null || !clientProfile.newPasswordConfirmation.trim())
				&& clientProfile.newPassword != clientProfile.newPasswordConfirmation) errors.newPassword = 'Cele doua parole nu se potrivesc'
		}


		setFormErrors(prevState => ({...prevState,  errors }));

		if (Object.keys(errors).length > 0) return false;
		return true;
	}


    const submitForm = (e) => {
		if (!validateData()) return;

		let data = {
			clientName: clientProfile.clientName.trim(),
			description: clientProfile.description,
			workLocation: clientProfile.workLocation,
			county: clientProfile.address.county,
			city: clientProfile.address.city,
            street: clientProfile.address.street,
            number: clientProfile.address.number,
            building: clientProfile.address.building,
            apartment: clientProfile.address.apartment,
            postalCode: clientProfile.address.postalCode,
            phone: clientProfile.phone,
            clientType: clientProfile.clientType,
		};

		if ((clientProfile.newPassword != null && clientProfile.newPassword.trim() != "")
			&& (clientProfile.newPasswordConfirmation != null && clientProfile.newPasswordConfirmation.trim() != "")
			&& (clientProfile.currentPassword != null && clientProfile.currentPassword.trim() != "")) {
			data = {
				...data,
				currentPassword: clientProfile.currentPassword,
				newPassword: clientProfile.newPassword,
				newPasswordConfirmation: clientProfile.newPasswordConfirmation
			}
		}

        if (clientProfile.clientType === "PERSONA") {
            data = {
				...data,
                personaData: {
                    firstName: clientProfile.personaData.firstName,
                    lastName: clientProfile.personaData.lastName,
                    cnp: clientProfile.personaData.cnp
                }
			}
        }

        if (clientProfile.clientType === "JURIDIC") {
            data = {
				...data,
                juridicData : {
                    noRegCom: clientProfile.juridicData.noRegCom,
                    company: clientProfile.juridicData.company,
                    contactPerson: clientProfile.juridicData.contactPerson,
                    cui: clientProfile.juridicData.cui,
                    iban: clientProfile.juridicData.iban
                }
			}
        }

		console.log(data);

		axios({
			method: 'put',
			url: `${host}/profile/client`,
			withCredentials: true,
			data
		}).then(res => {
			addNotification('success', 'Modificările au fost salvate cu succes!');
			history.push('/dashboard');
		}).catch(err => {
			console.log(err.response);
			addNotification('error', 'A aparut o eroare! Încearcă în câteva minute.');
		});
	}

    return (
     
		<div className="dashboard-content-inner" >
			
			{/* Dashboard Headline */}
			<div className="dashboard-headline">
				<h3>Setări</h3>

				{/* Breadcrumbs */}
				<nav id="breadcrumbs" className="dark">
					<ul>
						<li><Link to="/">Pagină principală</Link></li>
						<li><Link to="/dashboard">Dashboard</Link></li>
						<li>Setări</li>
					</ul>
				</nav>
			</div>
            
            {clientProfile && (
			
			<div className="row">

				{/* Dashboard Box */}
				<div className="col-xl-12">
					<div className="dashboard-box margin-top-0">

						{/* Headline */}
						<div className="headline">
							<h3><i className="icon-material-outline-account-circle"></i> Contul meu</h3>
						</div>

						<div className="content with-padding padding-bottom-0">

							<div className="row">

								<div className="col-auto">
									<div className="avatar-wrapper" data-tippy-placement="bottom" title="Change Avatar">
										<img className="profile-pic" src="/images/user-avatar-placeholder.png" alt="" />
										<div className="upload-button"></div>
										<input className="file-upload" type="file" accept="image/*"/>
									</div>
								</div>

								<div className="col">
									<div className="row">

										{/* <div className="col-xl-6">
											<div className="submit-field">
												<h5>Prenume {formState.formErrors.firstName && (
													<small className="form-input-error">{formState.formErrors.firstName}</small>
												)}</h5>
												<input type="text"  className={`with-border ${formState.formErrors.firstName ? 'invalid' : ''}`} placeholder="nume" value={formState.firstName} onChange={controlFirstName}/>
											</div>
										</div>

										<div className="col-xl-6">
											<div className="submit-field">
												<h5>Nume {formState.formErrors.lastName && (
													<small className="form-input-error">{formState.formErrors.lastName}</small>
												)}</h5>
												<input type="text"  className={`with-border ${formState.formErrors.lastName ? 'invalid' : ''}`} placeholder="prenume" value={formState.lastName} onChange={controlLastName}/>
											</div>
										</div> */}

                                        <div className="col-xl-3">
											<div className="submit-field">
												<h5>Nume Client {formErrors.errors.clientName && (
													<small className="form-input-error">{formErrors.errors.clientName}</small>
												)}</h5>
												<input type="text"  className={`with-border ${formErrors.errors.clientName ? 'invalid' : ''}`} placeholder="prenume" value={clientProfile.clientName} onChange={controlClientName}/>
											</div>
										</div>


										<div className="col-xl-3">
											<div className="submit-field">
												<h5>Email</h5>
												<input type="text" className="with-border" placeholder="ex. nume@domeniu.ro" value={clientProfile.email}/>
											</div>
										</div>
										<div className="col-xl-3">
											{/* Account Type */}
											<div className="submit-field">
												<h5>Telefon {formErrors.errors.phone && (
													<small className="form-input-error">{formErrors.errors.phone}</small>
												)}</h5>
												<input type="text" className={`with-border ${formErrors.errors.phone ? 'invalid' : ''}`} placeholder="telefon" value={clientProfile.phone} onChange={controlPhone}/>
											</div>
										</div>

                                        <div class="col-xl-3">
                                            <div class="submit-field">
                                                <h5>Tip client </h5>
                                                <Dropdown
                                                    className="with-border"
                                                    onChange={controlClientType}
                                                    options={[
                                                        {text: "Persoană Fizică", value: "PERSONA"},
                                                        {text: "Persoană Juridică", value: "JURIDIC"},
                                                    ]}
                                                />
                                            </div>
									    </div>

									</div>
								</div>
							</div>

						</div>
					</div>
				</div>

				{/* Dashboard Box */}
				<div className="col-xl-12">
					<div className="dashboard-box">

						{/* Headline */}
						<div className="headline">
							<h3><i className="icon-material-outline-face"></i> Profilul meu</h3>
						</div>

						<div className="content">
							<ul className="fields-ul">
							<li>
								<div className="row">

									<div className="col-xl-2">
										<div className="submit-field">
										<h5>Tipul de muncă</h5>
											<Dropdown
												className="with-border"
												onChange={controlWorkLocation}
												options={[
													{ text: 'Neprecizat', value: 'UNSPECIFIED' },
													{ text: 'Remote', value: 'REMOTE' },
													{ text: 'La sediul clientului', value: 'ON_SITE' }
												]} />
											</div>
									</div>
                                    {/* City & County */}
									<div className="col-xl-2">
										<div className="submit-field">
											<h5>Județ</h5>
											<DropdownWithSearch
												options={Object.keys(regionalData)}
												selected={clientProfile.address.county}
												onChange={controlCounty}
											/>
										</div>
									</div>

									<div className="col-xl-2">
										<div className="submit-field">
											<h5>Oraș</h5>
											<DropdownWithSearch
												options={regionalData[clientProfile.address.county]}
												selected={clientProfile.address.city}
												onChange={controlCity}
												hideOptions
											/>
										</div>
									</div>
									
								</div>
							</li>
							<li>
								<div className="row">
									<div className="col-xl-12">
										<div className="submit-field">
											<h5>Scurtă descriere despre compania ta 
                                                {formErrors.errors.description && (
													<small className="form-input-error">{formErrors.errors.description}</small>
												)}</h5>
                                                <textarea
												cols="30"
												rows="5"
												className={`with-border ${formErrors.errors.description ? 'invalid' : ''}`}
												value = {clientProfile.description}
												onChange = {controlDescription}
											></textarea>
										</div>
									</div>

								</div>
							</li>
						</ul>
						</div>
					</div>
				</div>

                {/* Dashboard Box */}
				<div className="col-xl-12">
					<div id="test1" className="dashboard-box">

						{/* Headline */}
						<div className="headline">
							<h3><i className="icon-line-awesome-building"></i> Adresă</h3>

                            {/* "postalCode": "011271",
                                "street": "Alune",
                                "number": "431",
                                "building": "12C",
                                "apartment": "22" */}
                         </div>   
						<div className="content with-padding">
							<div className="row">
								<div className="col-xl-4">
									<div className="submit-field">
                                        <h5>Stradă</h5>
										<input type="text" className="with-border" value={clientProfile.address.street} placeholder="nume stradă" onChange={controlStreet}/>
									</div>
								</div>

                                <div className="col-xl-4">
									<div className="submit-field">
                                        <h5>Număr</h5>
										<input type="text" className="with-border" value={clientProfile.address.number} placeholder="număr stradă" onChange={controlNumber}/>
									</div>
								</div>

                                <div className="col-xl-4">
									<div className="submit-field">
										<h5>Bloc/Clădire</h5>
										<input type="text" className="with-border" value={clientProfile.address.building} placeholder="detalii bloc/clădire" onChange={controlBuilding}/>
									</div>
								</div>

                                <div className="col-xl-4">
									<div className="submit-field">
                                        <h5>Apartament</h5>
										<input type="text" className="with-border" value={clientProfile.address.apartment} placeholder="detalii apartament" onChange={controlApartment}/>
									</div>
								</div>

                                <div className="col-xl-4">
									<div className="submit-field">
                                        <h5>Cod poștal</h5>
										<input type="text" className="with-border" value={clientProfile.address.postalCode} placeholder="cod postal" onChange={controlPostalCode}/>
									</div>
								</div>
                            </div>    
						</div>
                    </div>
                </div>

                 {/* Dashboard Box */}
				{clientProfile.clientType != "" && clientProfile.clientType === "JURIDIC"  && <div className="col-xl-12">
					<div id="test1" className="dashboard-box">

						{/* Headline */}
						<div className="headline">
							<h3><i className="icon-material-outline-library-books"></i> Date Juridice</h3>
                         </div>   
						<div className="content with-padding">
							<div className="row">
								<div className="col-xl-4">
									<div className="submit-field">
                                        <h5>Nume companie</h5>
										<input type="text" className="with-border" value={clientProfile.juridicData.company} onChange={controlCompany}/>
									</div>
								</div>

                                <div className="col-xl-4">
									<div className="submit-field">
                                        <h5>Număr în registrul comerțului</h5>
										<input type="text" className="with-border" value={clientProfile.juridicData.noRegCom} onChange={controlNoRegCom}/>
									</div>
								</div>

                                <div className="col-xl-4">
									<div className="submit-field">
										<h5>CUI</h5>
										<input type="text" className="with-border" value={clientProfile.juridicData.cui} onChange={controlCui}/>
									</div>
								</div>

                                <div className="col-xl-4">
									<div className="submit-field">
                                        <h5>IBAN </h5>
										<input type="text" className="with-border" value={clientProfile.juridicData.iban} onChange={controlIban}/>
									</div>
								</div>

                                <div className="col-xl-4">
									<div className="submit-field">
                                        <h5>Persoană de contact</h5>
										<input type="text" className="with-border" value={clientProfile.juridicData.contactPerson} onChange={controlContactPerson}/>
									</div>
								</div>
                            </div>    
						</div>
                    </div>
                </div>
                }

                {(clientProfile.clientType != "" && clientProfile.clientType === "PERSONA") && <div className="col-xl-12">
					<div id="test1" className="dashboard-box">

						{/* Headline */}
						<div className="headline">
							<h3><i className="icon-material-outline-library-books"></i> Date Juridice</h3>
                         </div>   
						<div className="content with-padding">
							<div className="row">
								<div className="col-xl-4">
									<div className="submit-field">
                                        <h5>Prenume</h5>
										<input type="text" className="with-border" value={clientProfile.personaData.firstName} placeholder="prenume" onChange={controlFirstName}/>
									</div>
								</div>

                                <div className="col-xl-4">
									<div className="submit-field">
                                        <h5>Nume</h5>
										<input type="text" className="with-border" value={clientProfile.personaData.lastName} placeholder="nume" onChange={controlLastName}/>
									</div>
								</div>

                                <div className="col-xl-4">
									<div className="submit-field">
										<h5>CNP</h5>
										<input type="text" className="with-border" value={clientProfile.personaData.cnp} placeholder="cod numeric personal" onChange={controlCnp}/>
									</div>
								</div>
                            </div>    
						</div>
                        </div>
                    </div>
                }

				{/* Dashboard Box */}
				<div className="col-xl-12">
					<div id="test1" className="dashboard-box">

						{/* Headline */}
						<div className="headline">
							<h3><i className="icon-material-outline-lock"></i> Parolă</h3>
						</div>

						<div className="content with-padding">
							<div className="row">
								<div className="col-xl-4">
									<div className="submit-field">
										<h5>Parolă curentă <small className="form-input-error">{formErrors.errors.currentPassword}</small></h5>
										<input type="password" className={`with-border ${formErrors.errors.currentPassword ? 'invalid' : ''}`} value={clientProfile.currentPassword} onChange={controlCurrentPassword}/>
									</div>
								</div>

								<div className="col-xl-4">
									<div className="submit-field">
										<h5>Parolă nouă <small className="form-input-error">{formErrors.errors.newPassword}</small></h5>
										<input type="password" className={`with-border ${formErrors.errors.newPassword ? 'invalid' : ''}`} value={clientProfile.newPassword} onChange={controlNewPassword}/>
									</div>
								</div>

								<div className="col-xl-4">
									<div className="submit-field">
										<h5>Confirmare parolă nouă <small className="form-input-error">{formErrors.errors.newPasswordConfirmation}</small></h5>
										<input type="password" className={`with-border ${formErrors.errors.newPasswordConfirmation ? 'invalid' : ''}`} value={clientProfile.newPasswordConfirmation} onChange={controlNewPasswordConfirmation} />
									</div>
								</div>
							</div>
						</div>
					</div>
                    {/* Button */}
                    <div className="col-xl-12">
                        <a href="#" className="button ripple-effect big margin-top-30" onClick={submitForm}>Salvează</a>
                    </div>

				</div>
				
				
                {/* Footer */}
                

		    </div>

            )}
            <div className="dashboard-footer-spacer"></div>
            <div className="small-footer margin-top-15">
                <div className="small-footer-copyrights">
                    © 2021 <strong>Lance Hub</strong>. Toate drepturile rezervate.
                </div>
                <ul className="footer-social-links">
                    <li>
                        <a href="https://www.facebook.com/LanceHubServices" title="Facebook" data-tippy-placement="top">
                            <i className="icon-brand-facebook-f"></i>
                        </a>
                    </li>

                </ul>
                <div className="clearfix"></div>
            </div>
        </div>
    );

};

export default ClientProfileUpdate;