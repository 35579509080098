import React from 'react';
import { Route } from 'react-router-dom';
import EmployerDashboard from './employer/Dashboard';
import FreelancerDashboard from './freelancer/FreelancerDashboard';
import {mobileMenu} from '../../runJQuery';
import { connect } from 'react-redux';

const RootDashboard = (props) => {
	if (props.authenticated === true && props.userType === "CLIENT") {
	    return <EmployerDashboard />;
	} else {
		if (props.authenticated === true && props.userType === "WORKER") {
			return <FreelancerDashboard />;
		}
	}
};

const mapStateToProps = (state) => ({
	authenticated: state.user.authenticated,
	userType: state.user.profile.userType
});
export default connect(mapStateToProps)(RootDashboard);