import React from 'react';
import Dropdown from '../../util/Dropdown';
import axios from 'axios';
import { interactiveEffects, keywordsEffect, tippyEffect, wrapperHeight } from '../../../runJQuery';
import DropdownWithSearch from '../../util/DropdownWithSearch';
import regionalData from '../../../regionalData.json';
import { addNotification } from '../../../actions';
import { host } from '../../../constants/constants';
import categories from "../../../constants/categories.json"

class CreateProject extends React.Component {
	state = {
		projectName: '',
		projectType: 'RECRUIT',
		xpLevel: '',
		workLocation: '',
		budget: '',
		paymentType: 'PROJECT',
		months: '',
		days: '',
		county: 'Bucuresti',
		city: '',
		description: '',
		urgent: false,
		currency: "RON",
		formErrors: {
			projectName: null,
			budget: null,
			duration: null,
			description: null
		},
		skills: [],
		categories: [],
		selectedMainCategory: "-1",
		selectedSubCategory: "-1"
	};

	skillRef = React.createRef();

	componentDidMount() {
		interactiveEffects();
		wrapperHeight();
		tippyEffect();
		keywordsEffect();
	}

	/* Methods */
	validateData() {
		const errors = {};

		// project name
		const projectName = this.state.projectName.trim();
		if (!projectName) errors.projectName = 'Please specify a project name';

		// budget
		const budget = this.state.budget.trim();
		if (!budget) errors.budget = 'Please input a budget';
		else if (isNaN(parseFloat(budget))) errors.budget = 'Please input a valid number';

		// duration
		const months = this.state.months;
		const days = this.state.days;
		if (!months && !days) errors.duration = 'Please provide a duration';
		else {
			if (isNaN(parseInt(months)) || isNaN(parseInt(days))) errors.duration = 'Please input a valid number';
		}

		// description
		const details = this.state.description.trim();
		if (!details) errors.description = 'Please provide some project details';

		this.setState({ formErrors: errors });

		if (Object.keys(errors).length > 0) return false;
		return true;
	}

	mainCategories = categories
		.filter(cat => cat.parent === "-1");

	getSubCategoriesForMain = (categId) => {
		const mainCateg = categories.find(cat => cat.id === categId);
		if (mainCateg !== undefined) {
			return categories
				.filter(cat => cat.parent === mainCateg.id.toString())
				.map(cat => {
					return {text: cat.name, value: cat.id}
				});
		}
		return [{text: "Toate subcategoriile", value: "-1"}];
	}

	getCategoryNameById = (catId) => {
		if(catId && catId.id != "-1")
			return categories.find(category => category.id === parseInt(catId.id)).name;
		else
			return "nicio categorie";
	}
		
	//events
	controlMainCategory = (selectedMainCategory) => {
		this.setState({ selectedMainCategory });
	};

	controlSubCategory = (selectedSubCategory) => {
		this.setState({ selectedSubCategory});
	};

	/* Events */
	controlProjectName = (e) => {
		this.setState({ projectName: e.target.value });
	}

	controlXpLevel = (xpLevel) => {
		this.setState({ xpLevel });
	}

	controlWorkLocation = (workLocation) => {
		this.setState({ workLocation });
	}

	controlBudget = (e) => {
		this.setState({ budget: e.target.value });
	}

	controlPaymentType = (e) => {
		this.setState({ paymentType: e.target.value });
	}

	setMonthsDuration = (e) => {
		this.setState({
			months: e.target.value,
			days: 0
		});
	}

	setDaysDuration = (e) => {
		this.setState({
			days: e.target.value,
			months: 0
		});
	}

	controlCounty = (county) => {
		this.setState({
			county,
			city: regionalData[county][0]
		});
	}

	controlCity = (city) => {
		this.setState({ city });
	}

	controlCurrency = (currency) => {
		this.setState({ currency });
	}

	controlProjectType = (projectType) => {
		this.setState({ projectType });
	}

	controlDescription = (e) => {
		this.setState({ description: e.target.value });
	}

	removeSkillHandler = (event) => {
        const skill = event.target.skill;
		this.setState((prevState) => ({	
			skills: prevState.skills.splice(prevState.skills.find(curSkill => skill === curSkill))
		}));
		keywordsEffect();
    }

	addSkillHandler = () => {
        const skill = this.skillRef.current.value.toLowerCase();
        if (skill.trim() != "" && !this.state.skills.includes(skill.toLowerCase())) {
            this.setState((prevState) => ({	
				skills: [...prevState.skills, skill.toLowerCase()]
			}));
        }
        this.skillRef.current.value="";
		keywordsEffect();
    };

	toggleUrgent = () => {
		this.setState((prevState) => ({
			urgent: !prevState.urgent
		}));
	}

	submitForm = (e) => {
		// validate form data
		if (!this.validateData()) return;

		const data = {
			title: this.state.projectName.trim(),
			details: this.state.description.trim(),
			experienceLevel: this.state.xpLevel,
			workLocation: this.state.workLocation,
			urgent: this.state.urgent,
			duration: 0,
			durationType: '',
			budget: parseFloat(this.state.budget.trim()),
			currency: this.state.currency,
			paymentType: this.state.paymentType,
			county: this.state.county,
			city: this.state.city,
			skills: this.state.skills,
			projectType: this.state.projectType ? this.state.projectType : 'RECRUIT'
		};

		// add duration
		const months = parseInt(this.state.months);
		const days = parseInt(this.state.days);

		if (months > 0) {
			data.duration = months;
			data.durationType = 'MONTHS';
		} else if (days > 0) {
			data.duration = days;
			data.durationType = 'DAYS';
		}

		if (this.state.selectedMainCategory != "-1") {
			data.categories = [this.state.selectedMainCategory]
			if(this.state.selectedSubCategory != "-1") {
				data.categories = [this.state.selectedMainCategory, this.state.selectedSubCategory]
			}
		}

		console.log(data)

		axios({
			method: 'post',
			url: `${host}/project`,
			withCredentials: true,
			data
		}).then(res => {
			addNotification('success', 'New project created successfully!');
			this.props.history.push('/dashboard');
		}).catch(err => {
			console.log(err.response);
			addNotification('error', 'Unknown error! Maybe try a different project name.');
		});
	}

	/* Render */
	render() {
		return (
			<div className="dashboard-content-inner" >
				{/*~~ Dashboard Headline ~~*/}
				<div className="dashboard-headline">
					<h3>Postează un proiect</h3>
				</div>

				{/*~~ Row ~~*/}
				<div className="row">

					{/*~~ Dashboard Box ~~*/}
					<div className="col-xl-12">
						<div className="dashboard-box margin-top-0">

							{/*~~ Headline ~~*/}
							<div className="headline">
								<h3><i className="icon-feather-folder-plus"></i> Înregistrare proiect</h3>
							</div>

							<div className="content with-padding padding-bottom-10">
								<div className="row">

									{/* Project name */}
									<div className="col-xl-4">
										<div className="submit-field">
											<h5>
												Nume proiect
												{this.state.formErrors.projectName && (
													<small className="form-input-error">{this.state.formErrors.projectName}</small>
												)}
											</h5>

											<input
												type="text"
												className={`with-border ${this.state.formErrors.projectName ? 'invalid' : ''}`}
												placeholder="e.g. construieste un website"
												value={this.state.projectName}
												onChange={this.controlProjectName}
											/>
										</div>
									</div>

									{/* XP Level */}
									<div className="col-xl-4">
										<div className="submit-field">
											<h5>Nivel de experiență</h5>
											<Dropdown
												className="with-border"
												onChange={this.controlXpLevel}
												options={[
													{ text: 'Junior', value: 'JUNIOR' },
													{ text: 'Middle', value: 'MIDDLE' },
													{ text: 'Senior', value: 'SENIOR' }
												]}
											/>
										</div>
									</div>

									{/* Work Location */}
									<div className="col-xl-2">
										<div className="submit-field">
											<h5>Tipul de lucru  <i className="help-icon" data-tippy-placement="right" title="Forma de lucru preferată"></i></h5>
											<Dropdown className="with-border" onChange={this.controlWorkLocation} options={[
												{ text: 'Neprecizat', value: 'UNSPECIFIED' },
												{ text: 'La sediu', value: 'ON_SITE' },
												{ text: 'Remote', value: 'REMOTE' }
											]} />
										</div>
									</div>

									{/* Work Location */}
									<div className="col-xl-2">
										<div className="submit-field">
											<h5>Tip proiect</h5>
											<Dropdown className="with-border" onChange={this.controlProjectType} options={[
												{ text: 'Proiect Recrutare', value: 'RECRUIT' },
												{ text: 'Licitație', value: 'BID' }
											]} />
										</div>
									</div>

									{/* Budget */}
									<div className="col-xl-3">
										<div className="submit-field">
											<h5>
												Care este bugetul estimat?
												{this.state.formErrors.budget && (
													<small className="form-input-error">{this.state.formErrors.budget}</small>
												)}
											</h5>
											<div className="input-with-icon">
												<input
													className={`with-border ${this.state.formErrors.budget ? 'invalid' : ''}`}
													type="text"
													placeholder="Amount"
													value={this.state.budget}
													onChange={this.controlBudget}
												/>
												<i className="currency">{this.state.currency}</i>
											</div>
											{/* Payment type */}
											<div className="feedback-yes-no margin-top-0">
												<div className="radio">
													<input
														id="radio-1"
														type="radio"
														value="PROJECT"
														checked={this.state.paymentType === 'PROJECT'}
														onChange={this.controlPaymentType}
													/>
													<label htmlFor="radio-1"><span className="radio-label"></span> Plată fixă</label>
												</div>

												<div className="radio">
													<input
														id="radio-2"
														type="radio"
														value="HOUR"
														checked={this.state.paymentType === 'HOUR'}
														onChange={this.controlPaymentType}
													/>
													<label htmlFor="radio-2"><span className="radio-label"></span> Plată pe oră</label>
												</div>

												<div className="radio">
													<input
														id="radio-3"
														type="radio"
														value="MONTH"
														checked={this.state.paymentType === 'MONTH'}
														onChange={this.controlPaymentType}
													/>
													<label htmlFor="radio-3"><span className="radio-label"></span> Plată pe lună</label>
												</div>
											</div>
										</div>
									</div> 

									<div class="col-xl-1">
										<div class="submit-field">
											<h5>Monedă </h5>
                                            <Dropdown
												className="with-border"
												onChange={this.controlCurrency}
												options={[
													{text: "RON", value: "RON"},
													{text: "EUR", value: "EUR"},
													{text: "USD", value: "USD"}
												]}
											/>
										</div>
									</div>

									{/* Duration */}
									<div className="col-xl-4">
										<div className="submit-field">
											<h5>
												Durată proiect
												{this.state.formErrors.duration && (
													<small className="form-input-error">{this.state.formErrors.duration}</small>
												)}
											</h5>
											<div className="row">
												<div className="col-xl-6">
													<div className="input-with-icon">
														<input
															className={`with-border ${this.state.formErrors.duration ? 'invalid' : ''}`}
															type="text"
															placeholder="?"
															value={this.state.months}
															onChange={this.setMonthsDuration}
														/>
														<i className="currency">Luni</i>
													</div>
												</div>
												<div className="col-xl-6">
													<div className="input-with-icon">
														<input
															className={`with-border ${this.state.formErrors.duration ? 'invalid' : ''}`}
															type="text"
															placeholder="?"
															value={this.state.days}
															onChange={this.setDaysDuration}
														/>
														<i className="currency">Zile</i>
													</div>
												</div>
											</div>
										</div>
									</div>

									{/* City & County */}
									<div className="col-xl-2">
										<div className="submit-field">
											<h5>Județ</h5>
											<DropdownWithSearch
												options={Object.keys(regionalData)}
												selected={this.state.county}
												onChange={this.controlCounty}
											/>
										</div>
									</div>

									<div className="col-xl-2">
										<div className="submit-field">
											<h5>Oraș</h5>
											<DropdownWithSearch
												options={regionalData[this.state.county]}
												selected={this.state.city}
												onChange={this.controlCity}
												hideOptions
											/>
										</div>
									</div>
									<div class="col-xl-4">
										<div class="submit-field">
											<h5>Ce skill-uri sunt importante pentru proiectul tău? <i class="help-icon" data-tippy-placement="right" title="Adauga maxim 5 skill-uri care te pot ajuta in promovarea proiectului"></i></h5>
											<div class="keywords-container">
												<div class="keyword-input-container">
													<input type="text" class="keyword-input with-border" placeholder="Add Skills" ref={this.skillRef}/>
													<button class="keyword-input-button ripple-effect" onClick={this.addSkillHandler}><i class="icon-material-outline-add" ></i></button>
													<div class="keywords-list">
														{this.state.skills.map(skill => 
																<span key={skill} class='keyword'><span class='keyword-remove' skill={skill} onClick={this.removeSkillHandler}></span><span class='keyword-text'>{skill}</span></span>
															)
														}
													</div>
												</div>
												
												<div class="clearfix"></div>
											</div>

										</div>
									</div>

									<div class="col-xl-4">
										<div class="submit-field">
											<h5>Categorie principală </h5>
                                            <Dropdown
												className="with-border"
												onChange={this.controlMainCategory}
												options={[{text: "Toate categoriile", value: "-1"},
													...this.mainCategories.map(categ => {
														return {text: categ.name, value: categ.id}
                                                })]}
											/>
										</div>
									</div>
									

									<div class="col-xl-4">
										<div class="submit-field">
											<h5>Sub Categorie</h5>
											<Dropdown
												className="with-border"
												onChange={this.controlSubCategory}
												options={this.getSubCategoriesForMain(this.state.selectedMainCategory)}
											/>
										</div>
									</div>

								

									{/* Details */}
									<div className="col-xl-12 margin-top-20">
										<div className="submit-field">
											<h5>
												Descriere proiect
												{this.state.formErrors.description && (
													<small className="form-input-error">{this.state.formErrors.description}</small>
												)}
											</h5>
											<textarea
												cols="30"
												rows="5"
												className={`with-border ${this.state.formErrors.description ? 'invalid' : ''}`}
												value={this.state.description}
												onChange={this.controlDescription}
											></textarea>
											{/* <div className="uploadButton margin-top-30">
												<input className="uploadButton-input" type="file" accept="image/*, application/pdf" id="upload" multiple />
												<label className="uploadButton-button ripple-effect" htmlFor="upload">Upload Files</label>
												<span className="uploadButton-file-name">Images or documents that might be helpful in describing your project</span>
											</div> */}
										</div>
									</div>

									{/* Urgent */}
									<div className="col-xl-12">
										<div className="switch-container">
											<label className="switch">
												<input type="checkbox" checked={this.state.urgent} onChange={this.toggleUrgent} />
												<span className="switch-button"></span>
												Urgent
											</label>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>

					<div className="col-xl-12">
						<button className="button ripple-effect big margin-top-30" onClick={this.submitForm}>
							<i className="icon-feather-plus"></i> Creare proiect
						</button>
					</div>

				</div>
				{/*~~ Row / End ~~*/}
			</div>
		);
	}
}

export default CreateProject;