import { SET_USER_PROFILE, SIGN_OUT, LOADING_USER, ADD_APPLICATION_PROJECT, REMOVE_APPLICATION_PROJECT } from './types';
import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from './types';
import axios from 'axios';
import qs from 'qs';
import store from '../reducers/store';
import {host} from '../constants/constants.js'

// Best dispatch ever
function dispatch(type, payload) {
	store.dispatch({ type, payload });
}

/*----------------------------------------------------*/
/*  Sign In
/*----------------------------------------------------*/
export async function signIn(email, password, callback) {
	const options = {
		method: 'POST',
		url: `${host}/login`,
		headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
		withCredentials: true,
		data: qs.stringify({
			'lancehub.username': email,
			'lancehub.password': password
		})
	};

	try {
		dispatch(LOADING_USER, true);
		const response = await axios(options);

		//dispatch(SET_USER_PROFILE, response.data);
		getUserProfile(() => {});
		addNotification('success', `Welcome back, ${response.data.firstName}!`);
		console.log(response);
		callback(null);
	}
	catch (err) {
		callback('Wrong username or password!');
	}
	finally {
		dispatch(LOADING_USER, false);
	}
}

/*----------------------------------------------------*/
/*  Register
/*----------------------------------------------------*/
export async function signUp(data, callback) {
	const options = {
		method: 'POST',
		url: `${host}/{TBD}`,
		headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
		withCredentials: true,
		data: {
			'password': data.password,
			'passwordConfirmation': data.repeatPassword
		}
	};

	if (data.freelancer) {
		options.url = `${host}/registerWorker`;
		options.data = qs.stringify({
			...options.data,
			'worker.firstName': data.firstName,
			'worker.lastName': data.lastName,
			'worker.user.email': data.email
		});
	} else {
		options.url = `${host}/registerClient`;
		options.data = qs.stringify({
			...options.data,
			'client.clientName': data.clientName,
			'client.user.email': data.email
		});
	}

	try {
		dispatch(LOADING_USER, true);
		const response = await axios(options);

		if (response.data.errorMessage) throw response.data.errorMessage;

		getUserProfile(() => {});
		callback(null);
	}
	catch (err) {
		console.error(err);
		callback(err);
	}
	finally {
		dispatch(LOADING_USER, false);
	}
}

/*----------------------------------------------------*/
/*  Get User Profile
/*----------------------------------------------------*/
export async function getUserProfile(done) {
	const options = {
		method: 'GET',
		url: `${host}/profile`,
		withCredentials: true
	};

	try {
		dispatch(LOADING_USER, true);
		const response = await axios(options);

		dispatch(SET_USER_PROFILE, response.data);
		addNotification('success', `Welcome back, ${response.data.fullName}! `);
	}
	catch (err) { }
	finally {
		dispatch(LOADING_USER, false);
		done();
	}
}

/*----------------------------------------------------*/
/*  Sign Out
/*----------------------------------------------------*/
export function signOut() {
	const options = {
		method: 'POST',
		url: `${host}/logout`,
		withCredentials: true
	};

	axios(options).then(() => {
		dispatch(SIGN_OUT);
		addNotification('success', 'See you later!');
	});
}

/*----------------------------------------------------*/
/*  Notifications
/*----------------------------------------------------*/
export function addNotification(type, message) {
	dispatch(ADD_NOTIFICATION, {
		id: Math.random().toString(36).substring(7),
		type,
		message
	});
}

export function removeNotification(id) {
	dispatch(REMOVE_NOTIFICATION, id);
}

/*----------------------------------------------------*/
/*  Project Applicaion
/*----------------------------------------------------*/
export function addProjectApplication(project) {
	dispatch(ADD_APPLICATION_PROJECT, project);
}

export function removeProjectApplication() {
	dispatch(REMOVE_APPLICATION_PROJECT);
}