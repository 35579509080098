
import { useParams } from "react-router";
import React,{useState, useEffect, Fragment} from "react";
import axios from 'axios';
import { addNotification } from '../../actions';
import { host } from '../../constants/constants';
import { Link } from "react-router-dom";
import BounceLoader from "react-spinners/BounceLoader";
import { css } from "@emotion/react";
import YoutubeFrame from "./YouTubeFrame";


const BlogPage = () => {

    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#2A41E8");

    const [blogPost, setBlogPost] = useState({
        postBody: ""
    });
    const { postId } = useParams();

    const fetchBlogPosts = () => {
        axios({
			method: 'GET',
			url: `${host}/blog/${postId}`,
			withCredentials: false
		}).then(res => {
			setBlogPost(res.data);
            setLoading(false)
		}).catch(err => {
			addNotification('error', 'Nu s-au putut Încarca aceasta postare. Încercati în câteva minute');
		});
    }

    const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    `;

    useEffect(() => fetchBlogPosts(), []);

    const detailsSplit = blogPost.postBody.split(/\n/);

    return (
        <Fragment>
            <div id="titlebar" className="gradient">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2>Blog</h2>

                        {/* Breadcrumbs */}
                        <nav id="breadcrumbs" className="dark">
                            <ul>
                                <li><a href="#">Pagină principală</a></li>
                                <li><Link to="/blog"><a href="#">Blog</a></Link></li>
                                <li>Postare</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>

        {/* Post Content */}
        <div className="container">
            <div className="row">
            {blogPost  && (
               
                <div className="col-xl-10 col-lg-18">
                    {/* Blog Post */}
                    <div className="blog-post single-post">
                        {loading==false ?
                            <div>
                                <div className="blog-post-thumbnail">
                                    <div className="blog-post-thumbnail-inner">
                                        <span className="blog-item-tag">{blogPost.postTag}</span>
                                        <img src={`data:image/png;base64,${blogPost.postImage}`} alt=""/>
                                    </div>
                                </div>
                                <div className="blog-post-content">
                                    <h3 className="margin-bottom-10">{blogPost.title}</h3>

                                    <div className="blog-post-info-list margin-bottom-20">
                                        <a className="blog-post-info">{
                                                    new Date(blogPost.createdAt).toLocaleDateString("ro-RO", 
                                                    {
                                                        day: 'numeric', // numeric, 2-digit
                                                        year: 'numeric', // numeric, 2-digit
                                                        month: 'long', // numeric, 2-digit, long, short, narrow
                                                    })}</a>
                                    </div>

                                    { 
                                        detailsSplit.map(paragraph => <p>{paragraph}</p>)
                                    } 

                                    {blogPost.youtubeId != null &&
                                        <div style={{textAlign: "center"}}>
                                            <YoutubeFrame
                                                youtubeId={blogPost.youtubeId}
                                            />
                                            </div>
                                    }
                                    
                                
                                </div>
                            </div>:
                            <div class="margin-top-100 margin-bottom-35 ">
                                 <BounceLoader color={color} loading={loading} css={override} size={150} />
                            </div>
                        }     
                    </div>
                </div>)}
            </div>
        </div>

       
    </Fragment>);
}

export default BlogPage;