// User
export const SET_USER_PROFILE = 'user_profile';
export const LOADING_USER = 'loading_user';
export const SIGN_OUT = 'sign_out';

// Util
export const ADD_NOTIFICATION = 'add_notification';
export const REMOVE_NOTIFICATION = 'remove_notification';

//Project
export const ADD_APPLICATION_PROJECT = 'add_application_project';
export const REMOVE_APPLICATION_PROJECT = 'remove_application_project';