import axios from "axios";
import React, { useEffect, useState, useRef } from "react"
import { keywordsEffect, rangeSlider, rippleEffect, slidingButtonEffect } from '../../runJQuery';
import ProjectItem from "./ProjectItem";
import { host } from '../../constants/constants';
import { addNotification } from '../../actions';
import Pagination from "../generic/pagination";
import { useLocation } from "react-router";
import SearchBarMini from '../util/SearchBarMini';
import Dropdown from "../util/Dropdown";
import DropdownWithSearch from '../util/DropdownWithSearch';
import categories from "../../constants/categories.json"
import regionalData from '../../regionalData.json';

const SearchProject = (props) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [pageChanged, setPageChanged] = useState(true);
	const [projects, setProjects] = useState([]);
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [searchBarContent, setSearchBarContent] = useState("");
    const [selectedMainCategory, setSelectedMainCategory] = useState("-1");
    const [selectedSubCategory, setSelectedSubCategory] = useState("-1");
    const [county, setCounty] = useState("Toate județele");
    const [city, setCity] = useState("Toate orașele");
    const [workLocation, setWorkLocation] = useState("-1");
    const [currency, setCurrency] = useState("-1");
    const [infLimit, setInfLimit] = useState();
    const [supLimit, setSupLimit] = useState();
    const [advancedSearchEnabled, setAdvancedSearchEnabled] = useState(false);
    const skillRef = useRef();

    const search = useLocation().search;
    const projectTerm = new URLSearchParams(search).get('projectTerm');
    const categoryParam = new URLSearchParams(search).get('categoryParam');

    const itemsOnPage = 10;

    const simpleSearch = (term) => {
        axios({
			method: 'GET',
			url: `${host}/search?pageNumber=${currentPage}&itemsOnPage=${itemsOnPage}&type=project&projectTerm=${term ? term : ""}`,
			withCredentials: true
		}).then(res => {
            setProjects(res.data.searchResults.map(searchResult => searchResult.project));
            setNumberOfPages(res.data.numberOfPages);
		}).catch(err => {
			addNotification('error', 'Nu s-au putut încărca proiectele. Încercați în cateva minute.');
		});
    };

    const advancedSearch = (searchCategory) => {
        const filterParams = []

        if (selectedSkills)
            filterParams.push(`skills=${selectedSkills.join(",")}`)
        if (searchBarContent)
            filterParams.push(`terms=${searchBarContent}`)
        if (selectedSubCategory != "-1" && selectedMainCategory !="-1") 
            filterParams.push(`categories=${selectedSubCategory}`)
        if (selectedSubCategory === "-1" && selectedMainCategory !="-1") 
            filterParams.push(`categories=${selectedMainCategory}`)    

        if (city != 'Toate orașele')
            filterParams.push(`cityName=${city}`)
        if (county != 'Toate județele')
            filterParams.push(`countyName=${county}`)

        if (currency != "-1")
            filterParams.push(`currency=${currency}`)
    
        // if (searchCategory != null || searchCategory !== undefined){
        //     filterParams.push(`categories=${searchCategory}`)
        // }
            
        if (workLocation != "-1") {
            filterParams.push(`locations=${workLocation}`)
        }

        if (currency != "-1" && infLimit != undefined ) {
            if(infLimit != undefined && infLimit != null) {
                switch(currency) {
                    case 'EUR' : {filterParams.push(`budgetEurInf=${infLimit}`); break;}
                    case 'RON' : {filterParams.push(`budgetRonInf=${infLimit}`); break;}
                    case 'USD' : {filterParams.push(`budgetUsdInf=${infLimit}`); break;}
                }
            }
            if(supLimit != undefined && supLimit != null) {
                switch(currency) {
                    case 'EUR' : {filterParams.push(`budgetEurSup=${supLimit}`); break;}
                    case 'RON' : {filterParams.push(`budgetRonSup=${supLimit}`); break;}
                    case 'USD' : {filterParams.push(`budgetUsdSup=${supLimit}`); break;}
                }
            }
        }

        axios({
			method: 'GET',
			url: `${host}/search/advanced/project?pageNumber=${currentPage}&itemsOnPage=${itemsOnPage}&type=project&${filterParams.join("&")}`,
			withCredentials: true
		}).then(res => {
            setProjects(res.data.searchResults.map(searchResult => searchResult.project));
            setNumberOfPages(res.data.numberOfPages);
            //setPageChanged(true)
		}).catch(err => {
			addNotification('error', 'Nu s-au putut încărca proiectele. Încercați în cateva minute.');
		});
    }

    const addSkillHandler = () => {
        const skill = skillRef.current.value;
        if (skill.trim() != "" && !selectedSkills.includes(skill)) {
            setSelectedSkills(prev =>  [...prev, skill]);
        }
        skillRef.current.value="";
        keywordsEffect();
    };

    const removeSkillHandler = (skill) => {
        setSelectedSkills(prev => prev.filter(curSkill => skill != curSkill));
    }

    useEffect(() => {
        if(pageChanged === true) {
            rangeSlider();
            rippleEffect();
            keywordsEffect();
            slidingButtonEffect();
            if(advancedSearchEnabled === false) {
                if(projectTerm === "" || projectTerm == undefined) {
                    simpleSearch("");
                }
        
                if(projectTerm != undefined && categoryParam == undefined){
                    simpleSearch(projectTerm);
                } else {
                    if(categoryParam != undefined || categoryParam != null) {
                        advancedSearch(categoryParam);
                    }
                }
            }
            if(advancedSearchEnabled === true) {
                advancedSearch(categoryParam);
            }
            setPageChanged(false);
        }
    }, [pageChanged]);

    const handleChange = (e) => {
		setSearchBarContent(e.target.value);
	};

	const handleSearch = () => {
		//simpleSearch(searchBarContent);
        setAdvancedSearchEnabled(true);
        setCurrentPage(0);
        advancedSearch();
	}

    const controlWorkLocation = (workLocation) => {
        setWorkLocation(workLocation);
    }

    // categories
    const mainCategories = categories
        .filter(cat => cat.parent === "-1");

    const getSubCategoriesForMain = (categId) => {
        const mainCateg = categories.find(cat => cat.id === categId);
        if (mainCateg !== undefined) {
            return categories
                .filter(cat => cat.parent === mainCateg.id.toString())
                .map(cat => {
                    return {text: cat.name, value: cat.id}
                });
        }
        return [{text: "Toate subcategoriile", value: "-1"}];
    }

    const getCategoryNameById = (catId) => {
        if(catId && catId.id != "-1")
            return categories.find(category => category.id === parseInt(catId.id)).name;
        else
            return "nicio categorie";
    }
            
    //events
    const controlMainCategory = (categ) => {
        setSelectedMainCategory(categ);
    };

    const controlSubCategory = (categ) => {
        setSelectedSubCategory(categ);
    };

    const controlCounty = (county) => {
        setCounty(county);
    };

    const controlCity = (city) => {
        setCity(city);
    };

    const controlCurrency = (currency) => {
        setCurrency(currency)
    }

    const controlInfLimit = (e) => {
        const infLimit = e.target.value;
        setInfLimit(infLimit);
    }

    const controlSupLimit = (e) => {
        const supLimit = e.target.value;
        setSupLimit(supLimit);
    }

    return (
            <React.Fragment>
                <div className="clearfix"></div>
                {/* Header Container / End */}

                {/* Spacer */}
                <div className="margin-top-90"></div>
                {/* Spacer / End*/}

                {/* Page Content */}
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4">
                            <div className="sidebar-container">

                                {/* Category */}
                                <div className="sidebar-widget">
                                    <h3>Categorie</h3>

                                    <Dropdown
                                        className="with-border"
                                        onChange={controlMainCategory}
                                        options={[{ text: "Toate categoriile", value: "-1" },
                                        ...mainCategories.map(categ => {
                                            return { text: categ.name, value: categ.id }
                                        })]}
                                    />
                                </div>

                                <div className="sidebar-widget">
                                    <h3>Subcategorie</h3>
                                    
                                    <Dropdown
                                        className="with-border"
                                        onChange={controlSubCategory}
                                        options={getSubCategoriesForMain(selectedMainCategory)}
                                    />
                                </div>

                                <div className="sidebar-widget">
                                    <h3>Mod de lucru</h3>
                                    <Dropdown
                                        className="with-border"
                                        onChange={controlWorkLocation}
                                        options={[
                                            { text: "Toate", value: "-1" },
                                            { text: "La sediu", value: "ON_SITE" },
                                            { text: "Remote", value: "REMOTE" },
                                        ]} />
                                </div>

                                 {/* Județ */}
                                 <div className="sidebar-widget">
                                    
                                    <h3>Județ</h3>
                                    <DropdownWithSearch
                                        options={Object.keys(regionalData)}
                                        selected={county}
                                        onChange={controlCounty}
                                    />
                                </div>

                                {/* Oraș */}
                                <div className="sidebar-widget">
                                    <h3>Oraș</h3>
                                    <DropdownWithSearch
                                        options={regionalData[county]}
                                        selected={city}
                                        onChange={controlCity}
                                        hideOptions
                                    />
                                </div>

                                <div className="sidebar-widget">
                                    <h3>Monedă</h3>
                                    <Dropdown
                                        className="with-border"
                                        onChange={controlCurrency}
                                        options={[
                                            { text: "Toate valutele", value: "-1" },
                                            { text: 'RON', value: 'RON' },
                                            { text: 'EUR', value: 'EUR' },
                                            { text: 'USD', value: 'USD' }
                                        ]} />
                                </div>
                                <div className="sidebar-widget">
                                    <h3>Preț</h3>
									<input type="text"  className={`with-border`} placeholder="Limită inferioară" value={infLimit} onChange={controlInfLimit}/>
                                    <br/>
                                    <input className="margin-top-20" type="text"  className={`with-border`} placeholder="Limită superioară" value={supLimit} onChange={controlSupLimit}/>
                                </div>

                                {/* Tags */}
                                <div className="sidebar-widget">
                                    <h3>Skills</h3>

                                    {/* More Skills */}
                                    <div className="keywords-container margin-top-20">
                                        <div className="keyword-input-container">
                                            <input type="text" className="keyword-input" pattern="[a-z]" placeholder="adauga mai multe skill-uri" ref={skillRef}/>
                                            <button className="keyword-input-button ripple-effect" onClick={addSkillHandler}><i className="icon-material-outline-add"></i></button>
                                        </div>
                                        <div className="keywords-list">{/* keywords go here */}
                                            {
                                                selectedSkills.map(skill => 
                                                        <span key={skill} className='keyword'><span className='keyword-remove' onClick={() => removeSkillHandler(skill)}></span><span className='keyword-text'>{skill}</span></span>
                                                    )
                                            }
                                            
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                                <div className="clearfix"></div>

                            </div>
                        </div>
                        <div class="col-xl-9 col-lg-8 content-left-offset">
                            
                            {/* SearchBar mini */}

                            <h3 class="page-title">Căutare proiect</h3>
                            <SearchBarMini 
                                title="Caută profesionist"
                                placeholder="Titlu proiect sau cuvinte cheie"
                                handleSearch={handleSearch}
                                handleChange={handleChange}
                                searchBarContent={searchBarContent}
                                searchButton="Caută proiect"
                            />

                            {/* <h3 class="page-title">Proiecte promovate</h3>

                            <div class="tasks-list-container margin-top-35">
                                    {
                                        projects.map(project => 
                                            <ProjectItem 
                                                id={project.id}
                                                title={project.title}
                                                description={project.details}
                                                budget={project.budget}
                                                currency={project.currency}
                                                workLocation={project.workLocation}
                                                workCity={project.city.name}
                                                skills={project.skills}
                                                cratedDate={project.createdAt}
                                                projectPaymentType={project.paymentType}
                                                promoted={true}
                                            ></ProjectItem>	
                                        )
                                    }
                            </div> */}

                            <h3 class="page-title">Rezultate căutare</h3>

                            { projects.length === 0 &&
                                <div class="notification warning margin-top-30" style={{textAlign: "center"}}>
                                    <p><strong>Nu s-au găsit rezultate</strong></p>
                                </div>
                            }
                        
                            {/* Tasks Container */}
                            <div class="tasks-list-container margin-top-35">
                                    {
                                        projects.map(project => 
                                            <ProjectItem 
                                                id={project.id}
                                                title={project.title}
                                                description={project.details}
                                                budget={project.budget}
                                                currency={project.currency}
                                                workLocation={project.workLocation}
                                                workCity={project.city.name}
                                                skills={project.skills}
                                                cratedDate={project.createdAt}
                                                projectPaymentType={project.paymentType}
                                                promoted={project.promoted}
                                                datePosted={project.createdAt}
                                                categories={project.categories}
                                            ></ProjectItem>	
                                        )
                                    }
                            </div>

                            {/* Pagination */}
                            { numberOfPages > 0 &&
                                <Pagination
                                    onPageChange = {(x) => {
                                            setCurrentPage(x-1);
                                            setPageChanged(true);
                                        }}
                                    totalCount = {numberOfPages * itemsOnPage}
                                    currentPage={currentPage + 1 }
                                    pageSize={itemsOnPage}
                                />
                            }
                        </div>
                    </div>
                </div>
                </React.Fragment>
            )};

export default SearchProject;
