import React from 'react';
import { connect } from 'react-redux';
import AuthButton from './AuthButton';
import UserNotifications from './UserNotifications';
import UserMenu from './UserMenu';

function UserWidget(props) {
	// if loading
	if (props.loadingUser) return '...';

	// if not authenticated
	if (!props.authenticated) return (
		<AuthButton />
	);

	// if authenticated
	return (
		<>
			{/* <UserNotifications /> */}
			<UserMenu />
		</>
	);
}

const mapStateToProps = (state) => ({
	authenticated: state.user.authenticated,
	loadingUser: state.user.loading
});

export default connect(mapStateToProps)(UserWidget);