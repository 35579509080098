const $ = window.$;

export function wrapperHeight() {
	var headerHeight = $("#header-container").outerHeight();
	var windowHeight = $(window).outerHeight() - headerHeight;
	$('.full-page-content-container, .dashboard-content-container, .dashboard-sidebar-inner, .dashboard-container, .full-page-container').css({ height: windowHeight });
	$('.dashboard-content-inner').css({ 'min-height': windowHeight });
}

export function interactiveEffects() {
	$(".switch, .radio").each(function () {
		var intElem = $(this);
		intElem.on('click', function () {
			intElem.addClass('interactive-effect');
			setTimeout(function () {
				intElem.removeClass('interactive-effect');
			}, 400);
		});
	});
}

export function mobileMenu() {
	// Dashboard Nav Submenus
	$('.dashboard-nav ul li a').on('click', function (e) {
		if ($(this).closest("li").children("ul").length) {
			if ($(this).closest("li").is(".active-submenu")) {
				$('.dashboard-nav ul li').removeClass('active-submenu');
			} else {
				$('.dashboard-nav ul li').removeClass('active-submenu');
				$(this).parent('li').addClass('active-submenu');
			}
			e.preventDefault();
		}
	});


	// Responsive Dashbaord Nav Trigger
	$('.dashboard-responsive-nav-trigger').on('click', function (e) {
		e.preventDefault();
		$(this).toggleClass('active');

		var dashboardNavContainer = $('body').find(".dashboard-nav");

		if ($(this).hasClass('active')) {
			$(dashboardNavContainer).addClass('active');
		} else {
			$(dashboardNavContainer).removeClass('active');
		}

		$('.dashboard-responsive-nav-trigger .hamburger').toggleClass('is-active');

	});
}

export function slickCarousel() {
	$('.default-slick-carousel').slick({
		infinite: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		dots: false,
		arrows: true,
		adaptiveHeight: true,
		responsive: [
			{
				breakpoint: 1292,
				settings: {
					dots: true,
					arrows: false
				}
			},
			{
				breakpoint: 993,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					dots: true,
					arrows: false
				}
			},
			{
				breakpoint: 769,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: true,
					arrows: false
				}
			}
		]
	});
}

export function rippleEffect() {
	$('.ripple-effect, .ripple-effect-dark').on('click', function (e) {
		var rippleDiv = $('<span class="ripple-overlay">'),
			rippleOffset = $(this).offset(),
			rippleY = e.pageY - rippleOffset.top,
			rippleX = e.pageX - rippleOffset.left;

		rippleDiv.css({
			top: rippleY - (rippleDiv.height() / 2),
			left: rippleX - (rippleDiv.width() / 2),
		}).appendTo($(this));

		window.setTimeout(function () {
			rippleDiv.remove();
		}, 800);
	});
}

export function modalTrigger(ref) {
	console.log(ref.current)
	$(ref.current).magnificPopup({
		type: 'inline',

		fixedContentPos: false,
		fixedBgPos: true,

		overflowY: 'auto',

		closeBtnInside: true,
		preloader: false,

		midClick: true,
		removalDelay: 300,
		mainClass: 'my-mfp-zoom-in'
	});

	/*----------------------------------------------------*/
	/*  Tabs */
	/*----------------------------------------------------*/
	var $tabsNav = $('.popup-tabs-nav'),
		$tabsNavLis = $tabsNav.children('li');

	$tabsNav.each(function () {
		var $this = $(this);

		$this.next().children('.popup-tab-content').stop(true, true).hide().first().show();
		$this.children('li').first().addClass('active').stop(true, true).show();
	});

	$tabsNavLis.on('click', function (e) {
		var $this = $(this);

		$this.siblings().removeClass('active').end().addClass('active');

		$this.parent().next().children('.popup-tab-content').stop(true, true).hide()
			.siblings($this.find('a').attr('href')).fadeIn();

		e.preventDefault();
	});

	var hash = window.location.hash;
	var anchor = $('.tabs-nav a[href="' + hash + '"]');
	if (anchor.length === 0) {
		$(".popup-tabs-nav li:first").addClass("active").show(); //Activate first tab
		$(".popup-tab-content:first").show(); //Show first tab content
	} else {
		anchor.parent('li').click();
	}

	// Link to Register Tab
	$('.register-tab').on('click', function (event) {
		event.preventDefault();
		$(".popup-tab-content").hide();
		$("#register.popup-tab-content").show();
		$("body").find('.popup-tabs-nav a[href="#register"]').parent("li").click();
	});

	// Disable tabs if there's only one tab
	$('.popup-tabs-nav').each(function () {
		var listCount = $(this).find("li").length;
		if (listCount < 2) {
			$(this).css({
				'pointer-events': 'none'
			});
		}
	});
}

export function rangeSlider() {

	function ThousandSeparator(nStr) {
	    nStr += '';
	    var x = nStr.split('.');
	    var x1 = x[0];
	    var x2 = x.length > 1 ? '.' + x[1] : '';
	    var rgx = /(\d+)(\d{3})/;
	    while (rgx.test(x1)) {
	        x1 = x1.replace(rgx, '$1' + ',' + '$2');
	    }
	    return x1 + x2;
	}

		// Bidding Slider Average Value
		var avgValue = (parseInt($('.bidding-slider').attr("data-slider-min")) + parseInt($('.bidding-slider').attr("data-slider-max")))/2;
		if ($('.bidding-slider').data("slider-value") === 'auto') {
			$('.bidding-slider').attr({'data-slider-value': avgValue});
		}
	
		// Bidding Slider Init
		$('.bidding-slider').slider();
	
		$(".bidding-slider").on("slide", function(slideEvt) {
			$("#biddingVal").text(ThousandSeparator(parseInt(slideEvt.value)));
		});
		$("#biddingVal").text(ThousandSeparator(parseInt($('.bidding-slider').val())));
	
	

	// Default Bootstrap Range Slider
	var currencyAttr = $(".range-slider").attr('data-slider-currency');
	
	$(".range-slider").slider({
		formatter: function(value) {
			return currencyAttr + ThousandSeparator(parseInt(value[0])) + " - " + currencyAttr + ThousandSeparator(parseInt(value[1]));
		}
	});
	
	$(".range-slider-single").slider();
} 

export function keywordsEffect() {
	/*--------------------------------------------------*/
	/*  Keywords
	/*--------------------------------------------------*/
	$(".keywords-container").each(function() {

		var keywordInput = $(this).find(".keyword-input");
		var keywordsList = $(this).find(".keywords-list");

		// // adding keyword
		// function addKeyword() {
		// 	var $newKeyword = $("<span class='keyword'><span class='keyword-remove'></span><span class='keyword-text'>"+ keywordInput.val() +"</span></span>");
		// 	keywordsList.append($newKeyword).trigger('resizeContainer');
		// 	keywordInput.val("");
		// }

		// // add via enter key
		// keywordInput.on('keyup', function(e){
		// 	if((e.keyCode == 13) && (keywordInput.val()!=="")){
		// 		addKeyword();
		// 	}
		// });

		// // add via button
		// $('.keyword-input-button').on('click', function(){ 
		// 	if((keywordInput.val()!=="")){
		// 		addKeyword();
		// 	}
		// });

		// removing keyword
		$(document).on("click",".keyword-remove", function(){
			$(this).parent().addClass('keyword-removed');

			function removeFromMarkup(){
			  $(".keyword-removed").remove();
			}
			setTimeout(removeFromMarkup, 500);
			keywordsList.css({'height':'auto'}).height();
		});


		//animating container height
		// keywordsList.on('resizeContainer', function(){
		//     var heightnow = $(this).height();
		//     var heightfull = $(this).css({'max-height':'auto', 'height':'auto'}).height();

		// 	$(this).css({ 'height' : heightnow }).animate({ 'height': heightfull }, 200);
		// });

		$(window).on('resize', function() {
			keywordsList.css({'height':'auto'}).height();
		});

		// Auto Height for keywords that are pre-added
		$(window).on('load', function() {

				keywordsList.css({'height':'auto'}).height();
			
		});

	});
};

export function inlineBG() {

	// Common Inline CSS
	$(".single-page-header, .intro-banner").each(function() {
		var attrImageBG = $(this).attr('data-background-image');

		if(attrImageBG !== undefined) {
			$(this).append('<div class="background-image-container"></div>');
			$('.background-image-container').css('background-image', 'url('+attrImageBG+')');
		}
	});

		// Fix for intro banner with label
	$(".intro-search-field").each(function() {
		var bannerLabel = $(this).children("label").length;
		if ( bannerLabel > 0 ){
			$(this).addClass("with-label");
		}
	});

	// Photo Boxes
	$(".photo-box, .photo-section, .video-container").each(function() {
		var photoBox = $(this);
		var photoBoxBG = $(this).attr('data-background-image');

		if(photoBox !== undefined) {
			$(this).css('background-image', 'url('+photoBoxBG+')');
		}
	});
};



// export function tippyEffect() {
// 	tippy('[data-tippy-placement]', {
// 		delay: 100,
// 		arrow: true,
// 		arrowType: 'sharp',
// 		size: 'regular',
// 		duration: 200,

// 		// 'shift-toward', 'fade', 'scale', 'perspective'
// 		animation: 'shift-away',

// 		animateFill: true,
// 		theme: 'dark',

// 		// How far the tooltip is from its reference element in pixels 
// 		distance: 10,

// 	});
// };

export function slidingButtonEffect() {
	/*--------------------------------------------------*/
	/*  Sliding Button Icon
	/*--------------------------------------------------*/
	$(window).on('load', function() {
		$(".button.button-sliding-icon").not(".task-listing .button.button-sliding-icon").each(function() {
			var buttonWidth = $(this).outerWidth()+30;
			$(this).css('width',buttonWidth);
		});
	});


	/*--------------------------------------------------*/
	/*  Sliding Button Icon
	/*--------------------------------------------------*/
    $('.bookmark-icon').on('click', function(e){
    	e.preventDefault();
		$(this).toggleClass('bookmarked');
	});

    $('.bookmark-button').on('click', function(e){
    	e.preventDefault();
		$(this).toggleClass('bookmarked');
	});
};

export function tippyEffect() {

	/*--------------------------------------------------*/
	/*  Tippy JS 
	/*--------------------------------------------------*/
    /* global tippy */
	tippy('[data-tippy-placement]', {
		delay: 100,
		arrow: true,
		arrowType: 'sharp',
		size: 'regular',
		duration: 200,

		// 'shift-toward', 'fade', 'scale', 'perspective'
		animation: 'shift-away',

		animateFill: true,
		theme: 'dark',

		// How far the tooltip is from its reference element in pixels 
		distance: 10,

	});
};

export function paymentTabEffect() {
	var radios = document.querySelectorAll('.payment-tab-trigger > input');
 
    for (var i = 0; i < radios.length; i++) {
        radios[i].addEventListener('change', expandAccordion);
    }
 
    function expandAccordion (event) {
      /* jshint validthis: true */
      var tabber = this.closest('.payment');
      var allTabs = tabber.querySelectorAll('.payment-tab');
      for (var i = 0; i < allTabs.length; i++) {
        allTabs[i].classList.remove('payment-tab-active');
      }
      event.target.parentNode.parentNode.classList.add('payment-tab-active');
    }

	$('.billing-cycle-radios').on("click", function() {
		if($('.billed-yearly-radio input').is(':checked')) { $('.pricing-plans-container').addClass('billed-yearly'); }
		if($('.billed-monthly-radio input').is(':checked')) { $('.pricing-plans-container').removeClass('billed-yearly'); }
	});
};


export function qtyEffect() {
		/*--------------------------------------------------*/
	/*  Quantity Buttons
	/*--------------------------------------------------*/
	function qtySum(){
	    var arr = document.getElementsByName('qtyInput');
	    var tot=0;
	    for(var i=0;i<arr.length;i++){
	        if(parseInt(arr[i].value))
	            tot += parseInt(arr[i].value);
	    }
	} 
	qtySum();

   $(".qtyDec, .qtyInc").on("click", function() {

      var $button = $(this);
      var oldValue = $button.parent().find("input").val();

      if ($button.hasClass('qtyInc')) {
          $button.parent().find("input").val(parseFloat(oldValue) + 1);
      } else {
         if (oldValue > 1) {
            $button.parent().find("input").val(parseFloat(oldValue) - 1);
         } else {
            $button.parent().find("input").val(1);
         }
      }

      qtySum();
      $(".qtyTotal").addClass("rotate-x");

   });
};

export function DashboardResize() {
		
	/*----------------------------------------------------*/
	/* Dashboard Scripts
	/*----------------------------------------------------*/

	// Dashboard Nav Submenus
    $('.dashboard-nav ul li a').on('click', function(e){
		if($(this).closest("li").children("ul").length) {
			if ( $(this).closest("li").is(".active-submenu") ) {
	           $('.dashboard-nav ul li').removeClass('active-submenu');
	        } else {
	            $('.dashboard-nav ul li').removeClass('active-submenu');
	            $(this).parent('li').addClass('active-submenu');
	        }
	        e.preventDefault();
		}
	});


	// Responsive Dashbaord Nav Trigger
    $('.dashboard-responsive-nav-trigger').on('click', function(e){
    	e.preventDefault();
		$(this).toggleClass('active');

		var dashboardNavContainer = $('body').find(".dashboard-nav");

		if( $(this).hasClass('active') ){
			$(dashboardNavContainer).addClass('active');
		} else {
			$(dashboardNavContainer).removeClass('active');
		}

		$('.dashboard-responsive-nav-trigger .hamburger').toggleClass('is-active');

	});

	// Fun Facts
	function funFacts() {
		/*jslint bitwise: true */
		function hexToRgbA(hex){
		    var c;
		    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
		        c= hex.substring(1).split('');
		        if(c.length== 3){
		            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
		        }
		        c= '0x'+c.join('');
		        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',0.07)';
		    }
		}

		$(".fun-fact").each(function() {
			var factColor = $(this).attr('data-fun-fact-color');

	        if(factColor !== undefined) {
	        	$(this).find(".fun-fact-icon").css('background-color', hexToRgbA(factColor));
	            $(this).find("i").css('color', factColor);
	        }
		});

	} funFacts();


	// Notes & Messages Scrollbar
	$(window).on('load resize', function() {
		var winwidth = $(window).width();
		if ( winwidth > 1199) {

			// Notes
			$('.row').each(function() {
				var mbh = $(this).find('.main-box-in-row').outerHeight();
				var cbh = $(this).find('.child-box-in-row').outerHeight();
				if ( mbh < cbh ) {
					var headerBoxHeight = $(this).find('.child-box-in-row .headline').outerHeight();
					var mainBoxHeight = $(this).find('.main-box-in-row').outerHeight() - headerBoxHeight + 39;

					$(this).find('.child-box-in-row .content')
							.wrap('<div class="dashboard-box-scrollbar" style="max-height: '+mainBoxHeight+'px" data-simplebar></div>');
				}
			});

			// Messages Sidebar
			// var messagesList = $(".messages-inbox").outerHeight();
			// var messageWrap = $(".message-content").outerHeight();
			// if ( messagesList > messagesWrap) {
			// 	$(messagesList).css({
			// 		'max-height': messageWrap,
			// 	});
			// }
		}
	});

	// Mobile Adjustment for Single Button Icon in Dashboard Box
	$('.buttons-to-right').each(function() {
		var btr = $(this).width();
		if (btr < 36) {
			$(this).addClass('single-right-button');
		}
	});

	// Small Footer Adjustment
	$(window).on('load resize', function() {
		var smallFooterHeight = $('.small-footer').outerHeight();
		$('.dashboard-footer-spacer').css({
			'padding-top': smallFooterHeight + 45
		});
	});


	// Auto Resizing Message Input Field
    /* global jQuery */
	jQuery.each(jQuery('textarea[data-autoresize]'), function() {
		var offset = this.offsetHeight - this.clientHeight;

		var resizeTextarea = function(el) {
		    jQuery(el).css('height', 'auto').css('height', el.scrollHeight + offset);
		};
		jQuery(this).on('keyup input', function() { resizeTextarea(this); }).removeAttr('data-autoresize');
	});

};


export function fullPageScrollbar() {
		/*--------------------------------------------------*/
	/*  Full Screen Page Scripts
	/*--------------------------------------------------*/

	// Wrapper Height (window height - header height)
	function wrapperHeight() {
		var headerHeight = $("#header-container").outerHeight();
		var windowHeight = $(window).outerHeight() - headerHeight;
		$('.full-page-content-container, .dashboard-content-container, .dashboard-sidebar-inner, .dashboard-container, .full-page-container').css({ height: windowHeight });
		$('.dashboard-content-inner').css({ 'min-height': windowHeight });
	}

	// Enabling Scrollbar
	function fullPageScrollbar() {
		$(".full-page-sidebar-inner, .dashboard-sidebar-inner").each(function() {

			var headerHeight = $("#header-container").outerHeight();
			var windowHeight = $(window).outerHeight() - headerHeight;
			var sidebarContainerHeight = $(this).find(".sidebar-container, .dashboard-nav-container").outerHeight();

			// Enables scrollbar if sidebar is higher than wrapper
			if (sidebarContainerHeight > windowHeight) {
				$(this).css({ height: windowHeight });
		
			} else {
				$(this).find('.simplebar-track').hide();
			}
		});
	}

	// Init
	$(window).on('load resize', function() {
		wrapperHeight();
		fullPageScrollbar();
	});
	wrapperHeight();
	fullPageScrollbar();

	// Sliding Sidebar 
	$('.enable-filters-button').on('click', function(){
		$('.full-page-sidebar').toggleClass("enabled-sidebar");
		$(this).toggleClass("active");
		$('.filter-button-tooltip').removeClass('tooltip-visible');
	});

	/*  Enable Filters Button Tooltip */
	$(window).on('load', function() {
		$('.filter-button-tooltip').css({
			left: $('.enable-filters-button').outerWidth() + 48
		})
		.addClass('tooltip-visible');
	});

	// Avatar Switcher
	function avatarSwitcher() {
	    var readURL = function(input) {
	        if (input.files && input.files[0]) {
	            var reader = new FileReader();

	            reader.onload = function (e) {
	                $('.profile-pic').attr('src', e.target.result);
	            };
	    
	            reader.readAsDataURL(input.files[0]);
	        }
	    };
	   
	    $(".file-upload").on('change', function(){
	        readURL(this);
	    });
	    
	    $(".upload-button").on('click', function() {
	       $(".file-upload").click();
	    });
	} avatarSwitcher();
}
