import React, { useEffect, useState } from 'react'
import { interactiveEffects, keywordsEffect, tippyEffect, wrapperHeight, DashboardResize, fullPageScrollbar } from '../../../runJQuery';
import categories from "../../../constants/categories.json"
import DropdownWithSearch from '../../util/DropdownWithSearch';
import Dropdown from '../../util/Dropdown';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import regionalData from '../../../regionalData.json';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { addNotification, getUserProfile } from '../../../actions';
import { host } from '../../../constants/constants';

const ProfileUpdate = (props) => {

    const [selectedMainCategory, setSelectedMainCategory] = useState("-1");
    const [selectedSubCategory, setSelectedSubCategory] = useState("-1");
	const [unregisteredSkill, setUnregisteredSkill] = useState([]);

	const history = useHistory();

    const [formState, setFormState] = useState({
        lastName: "nume",
        firstName: "prenume",
        country: "RO",
        hourlyTariffCurrency: "RON",
        county: "Bucuresti",
        description: "",
        workLocation: "UNSPECIFIED",
        experienceLevel: "",
		status: "OPEN",
		phone: "",
		currentPassword: "",
		newPassword: "",
		newPasswordConfirmation: "",
		skills: [],
        formErrors: {
			firstName: null,
			lastName: null,
            hourlyTariff: null,
            portfolio: null,
            description: null,
			currentPassword: null,
			newPassword: null,
			newPasswordConfirmation: null,
			phone: null
		},
    });

    useEffect(() => {
		getUserProfile(() => {});
		setFormState(prev => {return merge(prev, props.profile);});
        interactiveEffects();
        wrapperHeight();
        tippyEffect();
        keywordsEffect();
        fullPageScrollbar();
    }, []);

    // categories
    const mainCategories = categories
            .filter(cat => cat.parent === "-1");

    const getSubCategoriesForMain = (categId) => {
        const mainCateg = categories.find(cat => cat.id === categId);
		if (mainCateg !== undefined) {
			return categories
				.filter(cat => cat.parent === mainCateg.id.toString())
				.map(cat => {
					return {text: cat.name, value: cat.id}
				});
		}
		return [{text: "Toate subcategoriile", value: "-1"}];
    }

	const getCategoryNameById = (catId) => {
		if(catId && catId.id != "-1")
			return categories.find(category => category.id === parseInt(catId.id)).name;
		else
			return "nicio categorie";
	}
            
    //events
    const controlMainCategory = (categ) => {
        setSelectedMainCategory(categ);
    };

    const controlSubCategory = (categ) => {
        setSelectedSubCategory(categ);
    };

    const controlPortfolio = (e) => {
        const portfolio = e.target.value;
        setFormState(prev => ({...prev, portfolio}));
    }

	const controlFirstName = (e) => {
        const firstName = e.target.value;
        setFormState(prev => ({...prev, firstName}));
    }

	const controlLastName = (e) => {
        const lastName = e.target.value;
        setFormState(prev => ({...prev, lastName}));
    }

	const controlDescription = (e) => {
        const description = e.target.value;
        setFormState(prev => ({...prev, description}));
    }

	const controlCurrency = (hourlyTariffCurrency) => {
        setFormState(prev => ({...prev, hourlyTariffCurrency}));
    }

	const controlHourlyTariff = (e) => {
		const hourlyTariff = e.target.value;
        setFormState(prev => ({...prev, hourlyTariff}));
	}

	const controlXpLevel = (xpLevel) => {
		setFormState(prev => ({...prev, xpLevel }));
	}

	const controlWorkLocation = (workLocation) => {
		setFormState(prev => ({...prev, workLocation }));
	}

	const skillChangeHandler = (e) => {
		const skill = e.target.value;
		setUnregisteredSkill(skill);
	}

	const controlCurrentPassword = (e) => {
		const currentPassword = e.target.value;
        setFormState(prev => ({...prev, currentPassword}));
	}

	const controlNewPassword = (e) => {
		const newPassword = e.target.value;
        setFormState(prev => ({...prev, newPassword}));
	}

	const controlNewPasswordConfirmation = (e) => {
		const newPasswordConfirmation = e.target.value;
        setFormState(prev => ({...prev, newPasswordConfirmation}));
	}

	const controlCounty = (county) => {
		setFormState(prevState => ({
			...prevState,
			county,
			city: regionalData[county][0]
		}));
	}

	const controlCity = (city) => {
		setFormState(prevState => ({...prevState, city}));
	}

	const controlPhone = (e) => {
		const phone = e.target.value;
		setFormState(prevState => ({...prevState, phone}));
	}

	const addSkillHandler = () => {
        const skill = unregisteredSkill;
        if (skill.trim() != "" 
			&& formState.skills != null 
			&& !formState.skills.includes(skill) 
			&& formState.skills.length < 5) {
            setFormState((prevState) => ({
				...prevState,
				skills: [...prevState.skills, skill]
			}));
        }
		if (skill.trim() != "" && formState.skills == null) {
			setFormState((prevState) => ({	
				...prevState,
				skills: [skill]
			}));
		}
		setUnregisteredSkill("");
    };

	const removeSkillHandler = (skill) => {
		setFormState((prevState) => ({	
			...prevState,
			skills: prevState.skills.filter(curSkill => skill != curSkill)
		}));
    };

	const merge = (obj1, obj2) => {
		const answer = {}
		for(const key in obj2) {
		  if(answer[key] === undefined || answer[key] === null)
			answer[key] = obj2[key];
		}

		for(const key in obj1) {
			if(answer[key] === undefined || answer[key] === null)
			  answer[key] = obj1[key];
		}
		return answer
	}

    /* Methods */
	const validateData = () => {
		const errors = {};

		// hourlyTariff
		const hourlyTariff = formState.hourlyTariff;
		if (!hourlyTariff && !isNaN(hourlyTariff) ) errors.hourlyTariff = 'Introdu o rată orară';
		else{
			if (isNaN(parseFloat(hourlyTariff)) ) 
			 	errors.hourlyTariff = 'Introdu un numar valid';
			if (parseFloat(hourlyTariff) <= 0)  
				errors.hourlyTariff = 'Introdu un numar valid mai mare ca 0';
		}

		//firstname
		const firstName = formState.firstName;
		if (firstName == null || !firstName.trim()) errors.firstName = 'Introdu prenume';
		if (firstName != null && firstName.trim().length < 3) errors.firstName = 'Introdu prenume mai mare de 3 caractere';

		//lastName
		const lastName = formState.lastName;
		if (lastName == null || !lastName.trim()) errors.lastName = 'Introdu nume';
		if (lastName != null && lastName.trim().length < 3) errors.lastName = 'Introdu nume mai mare de 3 caractere';

		// details
		const description = formState.description;
		if (description == null || !description.trim()) errors.description = 'Introdu un titlu pentru profesia ta';
		if (description != null && description.trim().length < 3) errors.description = 'Introdu un titlu pentru profesia ta mai mare de 3 caractere';

		// portfolio
		const portfolio = formState.portfolio;
		if (portfolio == null || !portfolio.trim()) errors.portfolio = 'Oferă cateva detalii despre experiența ta profesionă';
		if (portfolio != null && portfolio.trim().length > 3000) errors.portfolio = 'Descrierea trebuie să contină maxim 3000 de caractere';

		//Passwords
		if ((formState.newPassword.trim() != "")
			|| (formState.newPasswordConfirmation.trim() != "")
			|| (formState.currentPassword.trim() != "")) {

			if ((formState.newPassword != null || !formState.newPassword.trim())
				&& (formState.newPasswordConfirmation != null || !formState.newPasswordConfirmation.trim())
				&& (formState.currentPassword == null || formState.currentPassword.trim() === "")) errors.currentPassword = 'Introdu parola curenta';

			if ((formState.newPassword != null || !formState.newPassword.trim()) && formState.newPassword.length < 6) errors.newPassword = 'Noua parola nu poate fi mai mică de 6 caractere';

			if ((formState.newPassword != null || !formState.newPassword.trim()) 
				&& (formState.newPasswordConfirmation != null || !formState.newPasswordConfirmation.trim())
				&& formState.newPassword != formState.newPasswordConfirmation) errors.newPassword = 'Cele doua parole nu se potrivesc'
		}


		setFormState(prevState => ({...prevState,  formErrors: errors }));

		if (Object.keys(errors).length > 0) return false;
		return true;
	}


    const submitForm = (e) => {
		if (!validateData()) return;

		let data = {
			firstName: formState.firstName.trim(),
			lastName: formState.lastName.trim(),
			description: formState.description,
			experienceLevel: formState.experienceLevel,
			workLocation: formState.workLocation,
			hourlyTariff: parseFloat(formState.hourlyTariff),
			hourlyTariffCurrency: formState.hourlyTariffCurrency,
			portfolio: formState.portfolio,
			status: formState.status,
			county: formState.county,
			country: formState.country,
			city: formState.city,
			skills: formState.skills,
		};

		if ((formState.newPassword != null || formState.newPassword.trim() != "")
			&& (formState.newPasswordConfirmation != null || formState.newPasswordConfirmation.trim() != "")
			&& (formState.currentPassword != null ||  formState.currentPassword.trim() != "")) {
			data = {
				...data,
				currentPassword: formState.currentPassword,
				newPassword: formState.newPassword,
				newPasswordConfirmation: formState.newPasswordConfirmation
			}
		}

		if (selectedSubCategory != "-1") {
			data = {
				...data,
				categories: [selectedMainCategory.toString(), selectedSubCategory.toString()]
			}
		} else {
			data = {
				...data,
				categories: [selectedMainCategory.toString()]
			}
		}

		console.log(data);

		axios({
			method: 'put',
			url: `${host}/profile/worker`,
			withCredentials: true,
			data
		}).then(res => {
			addNotification('success', 'Modificările au fost salvate cu succes!');
			history.push('/dashboard');
		}).catch(err => {
			console.log(err.response);
			addNotification('error', 'Unknown error! Maybe try a different project name.');
		});
	}

    return (
     
		<div class="dashboard-content-inner" >
			
			{/* Dashboard Headline */}
			<div class="dashboard-headline">
				<h3>Setări</h3>

				{/* Breadcrumbs */}
				<nav id="breadcrumbs" class="dark">
					<ul>
						<li><Link to="/">Pagină principală</Link></li>
						<li><Link to="/dashboard">Dashboard</Link></li>
						<li>Setări</li>
					</ul>
				</nav>
			</div>
	
			{/* Row */}
			<div class="row">

				{/* Dashboard Box */}
				<div class="col-xl-12">
					<div class="dashboard-box margin-top-0">

						{/* Headline */}
						<div class="headline">
							<h3><i class="icon-material-outline-account-circle"></i> Contul meu</h3>
						</div>

						<div class="content with-padding padding-bottom-0">

							<div class="row">

								<div class="col-auto">
									<div class="avatar-wrapper" data-tippy-placement="bottom" title="Change Avatar">
										<img class="profile-pic" src="/images/user-avatar-placeholder.png" alt="" />
										<div class="upload-button"></div>
										<input class="file-upload" type="file" accept="image/*"/>
									</div>
								</div>

								<div class="col">
									<div class="row">

										<div class="col-xl-6">
											<div class="submit-field">
												<h5>Prenume {formState.formErrors.firstName && (
													<small className="form-input-error">{formState.formErrors.firstName}</small>
												)}</h5>
												<input type="text"  className={`with-border ${formState.formErrors.firstName ? 'invalid' : ''}`} placeholder="nume" value={formState.firstName} onChange={controlFirstName}/>
											</div>
										</div>

										<div class="col-xl-6">
											<div class="submit-field">
												<h5>Nume {formState.formErrors.lastName && (
													<small className="form-input-error">{formState.formErrors.lastName}</small>
												)}</h5>
												<input type="text"  className={`with-border ${formState.formErrors.lastName ? 'invalid' : ''}`} placeholder="prenume" value={formState.lastName} onChange={controlLastName}/>
											</div>
										</div>

										<div class="col-xl-6">
											{/* Account Type */}
											<div class="submit-field">
												<h5>Titlu profesie {formState.formErrors.description && (
													<small className="form-input-error">{formState.formErrors.description}</small>
												)}</h5>
												<input type="text" className={`with-border ${formState.formErrors.description ? 'invalid' : ''}`} placeholder="ex. Expert" value={formState.description} onChange={controlDescription}/>
											</div>
										</div>

										<div class="col-xl-3">
											<div class="submit-field">
												<h5>Email</h5>
												<input type="text" class="with-border" placeholder="ex. nume@domeniu.ro" value={formState.email}/>
											</div>
										</div>
										<div class="col-xl-3">
											{/* Account Type */}
											<div class="submit-field">
												<h5>Telefon {formState.formErrors.description && (
													<small className="form-input-error">{formState.formErrors.phone}</small>
												)}</h5>
												<input type="text" className={`with-border ${formState.formErrors.phone ? 'invalid' : ''}`} placeholder="telefon" value={formState.phone} onChange={controlPhone}/>
											</div>
										</div>

									</div>
								</div>
							</div>

						</div>
					</div>
				</div>

				{/* Dashboard Box */}
				<div class="col-xl-12">
					<div class="dashboard-box">

						{/* Headline */}
						<div class="headline">
							<h3><i class="icon-material-outline-face"></i> Profilul meu</h3>
						</div>

						<div class="content">
							<ul class="fields-ul">
							<li>
								<div class="row">
									<div class="col-xl-2">
										<div class="submit-field">
											<h5>Rată orară <small className="form-input-error">{formState.formErrors.hourlyTariff}</small></h5>
												<input type="text" className={`with-border ${formState.formErrors.hourlyTariff ? 'invalid' : ''}`} placeholder="ex. 50"  value={formState.hourlyTariff} onChange={controlHourlyTariff}/>
										</div>
									</div>
									<div class="col-xl-2">
										<div class="submit-field">
											<h5>Monedă</h5>
											<Dropdown
												className="with-border"
												onChange={controlCurrency}
												options={[
													{ text: 'RON', value: 'RON' },
													{ text: 'EUR', value: 'EUR' },
													{ text: 'USD', value: 'USD' }
												]} />
										</div>
									</div>
									<div class="col-xl-2">
										<div class="submit-field">
										<h5>Nivel de experiență</h5>
											<Dropdown
												className="with-border"
												onChange={controlXpLevel}
												options={[
													{ text: 'Junior', value: 'JUNIOR' },
													{ text: 'Mediu', value: 'MEDIU' },
													{ text: 'Senior', value: 'SENIOR' }
												]} />
											</div>
									</div>

									<div class="col-xl-2">
										<div class="submit-field">
										<h5>Tipul de muncă</h5>
											<Dropdown
												className="with-border"
												onChange={controlWorkLocation}
												options={[
													{ text: 'Neprecizat', value: 'UNSPECIFIED' },
													{ text: 'Remote', value: 'REMOTE' },
													{ text: 'La sediul clientului', value: 'ON_SITE' }
												]} />
											</div>
									</div>

									<div class="col-xl-4">
										<div class="submit-field">
											<h5>Skills <i class="help-icon" data-tippy-placement="right" title="Add up to 10 skills"></i></h5>

											{/* Skills List */}
											<div class="keywords-container">
												<div class="keyword-input-container">
													<input type="text" class="keyword-input with-border" placeholder="e.g. Angular, Laravel" value={unregisteredSkill} onChange={skillChangeHandler}/>
													<button class="keyword-input-button ripple-effect" onClick={addSkillHandler}><i class="icon-material-outline-add"></i></button>
												</div>
												<div class="keywords-list">
														{(formState.skills && formState.skills.length > 0) && formState.skills.map(skill => 
																<span key={skill} class='keyword'><span class='keyword-remove' onClick={() => removeSkillHandler(skill)}></span><span class='keyword-text'>{skill}</span></span>
															)
														}
												</div>
												<div class="clearfix"></div>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li>
								<div class="row">
									
									{/* City & County */}
									<div className="col-xl-2">
										<div className="submit-field">
											<h5>Județ</h5>
											<DropdownWithSearch
												options={Object.keys(regionalData)}
												selected={formState.county}
												onChange={controlCounty}
											/>
										</div>
									</div>

									<div className="col-xl-2">
										<div className="submit-field">
											<h5>Oraș</h5>
											<DropdownWithSearch
												options={regionalData[formState.county]}
												selected={formState.city}
												onChange={controlCity}
												hideOptions
											/>
										</div>
									</div>
									<div class="col-xl-4">
										<div class="submit-field">
											<h5>Categorie principală  | {(formState.categories && formState.categories.length > 0) && <small>categorie curentă: {getCategoryNameById(formState.categories[0])} </small>}</h5>
											
                                            <Dropdown
												className="with-border"
												onChange={controlMainCategory}
												options={[{text: "Toate categoriile", value: "-1"},
													...mainCategories.map(categ => {
														return {text: categ.name, value: categ.id}
                                                })]}
											/>
										</div>
									</div>
									

									<div class="col-xl-4">
										<div class="submit-field">
											<h5>Sub Categorie  | {(formState.categories && formState.categories.length > 1) && <small>categorie curentă: {getCategoryNameById(formState.categories[1])} </small>}</h5>
											<Dropdown
												className="with-border"
												onChange={controlSubCategory}
												options={getSubCategoriesForMain(selectedMainCategory)}
											/>
										</div>
									</div>

									<div class="col-xl-12">
										<div class="submit-field">
											<h5>Scurtă descriere despre experiența ta 
                                                {formState.formErrors.portfolio && (
													<small className="form-input-error">{formState.formErrors.portfolio}</small>
												)}</h5>
                                                <textarea
												cols="30"
												rows="5"
												className={`with-border ${formState.formErrors.portfolio ? 'invalid' : ''}`}
												value={formState.portfolio}
												onChange={controlPortfolio}
											></textarea>
										</div>
									</div>

								</div>
							</li>
						</ul>
						</div>
					</div>
				</div>

				{/* Dashboard Box */}
				<div class="col-xl-12">
					<div id="test1" class="dashboard-box">

						{/* Headline */}
						<div class="headline">
							<h3><i class="icon-material-outline-lock"></i> Parolă</h3>
						</div>

						<div class="content with-padding">
							<div class="row">
								<div class="col-xl-4">
									<div class="submit-field">
										<h5>Parolă curentă <small className="form-input-error">{formState.formErrors.currentPassword}</small></h5>
										<input type="password" className={`with-border ${formState.formErrors.currentPassword ? 'invalid' : ''}`} value={formState.currentPassword} onChange={controlCurrentPassword}/>
									</div>
								</div>

								<div class="col-xl-4">
									<div class="submit-field">
										<h5>Parolă nouă <small className="form-input-error">{formState.formErrors.newPassword}</small></h5>
										<input type="password" className={`with-border ${formState.formErrors.newPassword ? 'invalid' : ''}`} value={formState.newPassword} onChange={controlNewPassword}/>
									</div>
								</div>

								<div class="col-xl-4">
									<div class="submit-field">
										<h5>Confirmare parolă nouă <small className="form-input-error">{formState.formErrors.newPasswordConfirmation}</small></h5>
										<input type="password" className={`with-border ${formState.formErrors.newPasswordConfirmation ? 'invalid' : ''}`} value={formState.newPasswordConfirmation} onChange={controlNewPasswordConfirmation} />
									</div>
								</div>

								{/* <div class="col-xl-12">
									<div class="checkbox">
										<input type="checkbox" id="two-step" checked/>
										<label for="two-step"><span class="checkbox-icon"></span> Enable Two-Step Verification via Email</label>
									</div>
								</div> */}
							</div>
						</div>
					</div>
                    {/* Button */}
                    <div class="col-xl-12">
                        <a href="#" class="button ripple-effect big margin-top-30" onClick={submitForm}>Salvează</a>
                    </div>

				</div>
				
				
                {/* Footer */}
                

		</div>
        <div class="dashboard-footer-spacer"></div>
                    <div class="small-footer margin-top-15">
                        <div class="small-footer-copyrights">
                            © 2021 <strong>Lance Hub</strong>. Toate drepturile rezervate.
                        </div>
                        <ul class="footer-social-links">
                            <li>
                                <a href="https://www.facebook.com/LanceHubServices" title="Facebook" data-tippy-placement="top">
                                    <i class="icon-brand-facebook-f"></i>
                                </a>
                            </li>
                        
                        </ul>
                        <div class="clearfix"></div>
                    </div>
                </div>
    );

};

const mapStateToProps = (state) => ({
	profile: state.user.profile,
});

export default connect(mapStateToProps)(ProfileUpdate);