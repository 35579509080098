import React from 'react';
import YouTube from 'react-youtube';

const YoutubeFrame = (props) => {
    return (
        <YouTube
            videoId={props.youtubeId}                  // defaults -> null
        />
    );
}

export default YoutubeFrame;