import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const PrivateRoute = ({ component: Component, ...rest }) => {
	const render = (props) => {
		if (rest.authenticated) return <Component {...props} />
		return <Redirect to={{
			pathname: '/',
			state: { from: props.location }
		}} />
	}

	return <Route {...rest} render={render} />
}

const mapStateToProps = (state) => ({
	authenticated: state.user.authenticated
});

export default connect(mapStateToProps)(PrivateRoute);